import React, { Component } from "react";
import Layout from "../../layouts/contactuslayout";
import "./contactus.css";
import Section from "../../components/Section";
import ContactForm from "../../components/com/contactform/ContactForm";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
const Image = require("../../images/help.png");
export default class ContactUs extends Component {
  render() {
    return (
      <Layout>
        <Section className="bloqsec">
          <Row type="flex" justify="center">
            <Col span={12} xs={24}>
              <div className="contact-container">
                {/* <Link to="/contactus/#contactform">GOT A QUESTION?</Link> */}
                <h2>Contact Us</h2>
                <p>
                  We’re here to help and answer any question you might have.{" "}
                  <br /> We look forward to hearing from you
                </p>
              </div>
            </Col>
          </Row>
        </Section>
        <Section className="section section-gray diagonal">
          <Row type="flex" justify="center">
            <Col span={10} xs={24} md={24} lg={12} xl={10} className="lad1">
              <div id="contactform" className="contact-form">
                <ContactForm
                  className="form-contact"
                  showAvatar={false}
                  description={false}
                  buttonText="Send"
                  data={{}}
                />
              </div>
            </Col>
            <Col span={14} xs={24} md={24} lg={12} xl={14} className="lad2">
              <div
                className="image-container"
                style={{
                  marginTop: 50,
                  marginLeft: 40
                }}
              >
                <h2
                  style={{
                    marginBottom: 40
                  }}
                >
                  How Can We Help?
                </h2>
                {/*<img
                  src={Image}
                  width="100%"
                  style={{
                    height: 400
                  }}
                />*/}
                {/*  <p>
                  Please select a topic below related to your inquiry. If you
                  don’t find what you need, fill out our contact form.
                </p> */}
                <p>
                  <h4>Are selling your home?</h4>
                  {/* <p>Request demo from one of our converstation</p> */}
                  <hr />
                </p>
                <p>
                  <h4>Are you buying a new property?</h4>
                  {/* <p>
                    Discover the many ways in which our customers use Sleeknote.
                  </p> */}
                  <hr />
                </p>
                <p>
                  <h4>Do you want to work with us?</h4>
                  {/* <p>
                    Join our Partner Program and earn 25% recurring commissions.
                  </p> */}
                  <hr />
                </p>
              </div>
            </Col>
          </Row>
        </Section>
      </Layout>
    );
  }
}
