/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import "./layoutlistings.css";

import { Layout } from "antd";
import Menu from "../components/menuListings";
import Footer from "../components/com/footer/Footer";
const { Header, Sider, Content } = Layout;

const ConteSubdivision = ({ children }) => (
  <div className="listings-container">
    <Layout>
      <header>
        <Menu />
      </header>
      {/*<Header className="submenu-listings">
        <Search />
      </Header>*/}
      <Content>{children}</Content>
      <Footer span={12} className="footer-page footer-listing">
          Footer
      </Footer>
    </Layout>
  </div>
);

ConteSubdivision.propTypes = {
  children: PropTypes.node.isRequired
};

export default ConteSubdivision;
