import React, { Component } from "react";
import { withRouter } from "react-router";
import { Menu, Dropdown, Icon } from "antd";
import "./menu.css";
import Logo from "../images/logo.svg";
import { isMobile } from "react-device-detect";
import ProfileMenu from "./ProfileMenu";
import { Link } from "react-router-dom";

class MyMenu extends Component {
  state = {
    visible: false,
    current: "home"
  };
  handleClick = e => {
    let value = e.key;
    let { history } = this.props;

    switch (value) {
      case "login":
        return history.push("/login");
        break;
      case "signup":
        return history.push("/signup");
        break;
      case "about":
        return history.push("/about");
        break;
      case "contactus":
        return history.push("/contactus");
        break;
      default:
        this.setState({
          visible: !this.state.visible
        });
        break;
    }
    if (value !== "menu")
      this.setState({
        current: value
      });
  };
  showDrawer = () => {
    this.setState({
      visible: !this.state.visible
    });
    /*console.log('Clic: -- ', this.state.visible);*/
  };

  handleMenuClick = value => {
    if (this.props.handleMenuClick) this.props.handleMenuClick(value);
  };
  render() {
    return (
      <div
        className={this.props.className}
        style={{
          background: "transparent",
          padding: "0px",
          margin: "0px"
        }}
      >
        <div className="logo">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </div>

        <Menu
          onClick={this.handleClick}
          className="header-menu menu-right"
          mode="horizontal"
          defaultSelectedKeys={["signup"]}
        >
          {!localStorage.userId && (
            <Menu.Item key="login" className="item-menu-desktop">
              Sign In
            </Menu.Item>
          )}
          {!localStorage.userId && (
            <Menu.Item key="signup" className="item-menu-desktop">
              Sign Up
            </Menu.Item>
          )}
          {
            <Menu.Item
              /* onClick={(e) => this.showDrawer(e)} */
              key="menu"
              className="item-menu-transparent topitem"
            >
              <Icon type="menu" style={{ padding: 10 }} theme="outlined" />
            </Menu.Item>
          }
        </Menu>
        <ProfileMenu
          title={""}
          onVisible={this.state.visible}
          onClose={this.showDrawer}
        />
      </div>
    );
  }
}

export default withRouter(MyMenu);
