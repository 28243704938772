import { Constants, ApiGO, Api, Api2, ApiFeathers, Helper } from "../utils";

/* Feathers Services */
import {
  search,
  property,
  new_listings,
  new_listings_under_300,
  new_listings_apartament_rent,
  property_detail,
  property_history,
  walkscores,
  transit_scores,
  nearby_schools,
  listings_by_subdivisionname,
  findLatLngService,
  userSaveService,
  propertyFavoriteService,
  listingClosedService,
  propertyHistoryService,
  listingByAddressMls,
  EmailService,
  recoveryPassword,
  userService,
  getService,
  changePassword
} from "./services";
/* API Gooneworld */
/*
 * @api_autocomplete: {search_param}
 */
const api_autocomplete = params => {
  params["limit"] = 30;
  return ApiGO.get(Constants.URL_AUTOCOMPLETE + "?cache=120", { params });
};
/*
 * @recent_listings: {city}
 */
const recent_listings = params => {
  return ApiGO.get(Constants.URL_RECENT_LISTING + "?cache=120", { params });
};

/* API */
/*
 * @api_search: {params}
 */
const api_search = params => {
  params["limit"] = 30;
  return Api.get(Constants.URL_API_SEARCH + "?cache=120", { params });
};

/** Subdivision **/
const api_search_subdivision = params => {
  return Api.get(Constants.URL_SEARCH_SUBDIVISION + "?cache=120", { params });
  /* return Api.get(Constants.URL_API_SEARCH + "?cache=120", { params }); */
};

/** CondosList **/
const api_search_condoslist = params => {
  return Api2.get("/condos2.json", { params });
};

/* @find photos by slug  */
const get_photos_by_slug = params => {
  return Api.get(Constants.PATH_API_DETAILS, { params });
};
/*
 * @find_lat_lng: {listingId}
 */
/* const find_lat_lng = listingId => {
  return Api.get(Constants.URL_API_FIND_LAT_LNG + listingId, { cache: 120 });
};
 */
/*
 * @pothos: {_id, index:0}
 */
const pothos = (_id, index) => {
  index = index || 0;
  return ApiGO.get(Constants.URL_POTHOS + _id + "/" + index, { cache: 120 });
};
/*
 * @search_autocomplete: {params}
 */
/* const search_autocomplete = params => {
  params["limit"] = 30;
  return Api.get(Constants.URL_AUTOCOMPLETE_SEARCH, { params });
}; */
/*
 * @login: {params}
 */
const login = params => {
  return new Promise((resolve, reject) => {
    Api.post(Constants.URL_LOGIN, params).then(response => {
      let { data } = response;
      Api.defaults.headers.common["Authorization"] = data.id;
      resolve(response);
    }, reject);
  });
};
/*
 * @logout: {params}
 */
const logout = () => {
  Api.defaults.headers.common["Authorization"] = localStorage.access_token;
  return new Promise((resolve, reject) => {
    Api.post(
      `${Constants.URL_LOGOUT}?access_token=${localStorage.access_token}`
    ).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      }
    );
  });
};

/*
 * @save_search: {params}
 */
const save_search = params => {
  Api.defaults.headers.common["Authorization"] = localStorage.access_token;
  return new Promise((resolve, reject) => {
    Api.post(Constants.URL_SAVE_SEARCH, params).then(response => {
      resolve(response);
    }, reject);
  });
};

const send_message = params => {
  return ApiGO.post(Constants.URL_SEND_MESSAGE, { params });
};

const calcula_down_payment = params => {
  return ApiGO.get(Constants.URL_CALCULA_DOWN_PAYMENNT, { params });
};

const calcula_mortgage = params => {
  return ApiGO.get(Constants.URL_CALCULA_DOWN_PAYMENNT, { params });
};

/*
 * @add_favorite: {params}
 */
/* const add_favorite = params => {
  Api.defaults.headers.common["Authorization"] = localStorage.access_token;
  return new Promise((resolve, reject) => {
    Api.post(
      Constants.URL_ADD_FAVORITES.format(
        params.userId,
        params.ListingKeyNumeric
      )
    ).then(response => {
      resolve(response);
    }, reject);
  });
}; */
const get_user = userId => {
  Api.defaults.headers.common["Authorization"] = localStorage.access_token;
  return Api.get(`${Constants.URL_USERS}/${userId}`);
};
const update_user = params => {
  Api.defaults.headers.common["Authorization"] = localStorage.access_token;
  let userId = localStorage.userId;
  return Api.put(`${Constants.URL_USERS}/${userId}`, params);
};

/* New Listings */
/* const new_listings = params => {
  return Api.get(`${Constants.URL_API_SEARCH}`, { params });
}; */

const get_walk_score = ({ address, lat, lon }) => {
  const url =
    "http://ec2-54-84-6-129.compute-1.amazonaws.com/WalkScore.php?format=json&wsapikey=9a01b5374639d6da62556ea007cf5247";
  return Api.get(url, {
    params: {
      address,
      lat,
      lon
    }
  });
};
const get_transit_score = ({ address, lat, lon, city, state }) => {
  const url =
    "http://ec2-54-84-6-129.compute-1.amazonaws.com/TransitScore.php?format=json&wsapikey=9a01b5374639d6da62556ea007cf5247";
  return Api.get(url, {
    params: {
      address,
      lat,
      lon,
      city,
      state
    }
  });
};
const get_schools = ({ address, lat, lon, city, state }) => {
  const url = "http://ec2-54-84-6-129.compute-1.amazonaws.com/Schools.php";
  return Api.get(url, {
    params: {
      address,
      lat,
      lon,
      city,
      state
    }
  });
};

const search_autocomplete = params => search.find(params);
const property_search = params =>
  property.find({
    query: params
  });
const get_new_listings = () => new_listings.find();
const get_new_listings_under_300 = () => new_listings_under_300.find();
const get_new_listings_apartament_rent = () =>
  new_listings_apartament_rent.find();

const get_property_detail = params =>
  property_detail.find({
    query: params
  });
const get_property_history = params =>
  property_history.find({
    query: params
  });
const get_walkscores = params =>
  walkscores.find({
    query: params
  });
const get_transit_scores = params =>
  transit_scores.find({
    query: params
  });
const get_nearby_schools = params =>
  nearby_schools.find({
    query: params
  });
const get_listings_by_subdivisionname = params =>
  listings_by_subdivisionname.find({
    query: params
  });
const find_lat_lng = params =>
  findLatLngService.find({
    query: params
  });
const save_listing_search = params => userSaveService.create(params);
const add_favorite = params => propertyFavoriteService.create(params);
const get_listings_closed = params =>
  listingClosedService.find({
    query: params
  });
const get_property_closed = ListingKeyNumeric =>
  propertyHistoryService.find({
    query: {
      ListingKeyNumeric
    }
  });

const get_listings_by_address_mls = params => listingByAddressMls.find(params);
const send_email = params => EmailService.create(params);

/* Reset  Password */
const recovery_password = params => recoveryPassword.create(params);

/*
 * @signup: {params}
 */
const signup = params => userService.create(params);

const change_password = params => changePassword.create(params);
export {
  api_autocomplete,
  recent_listings,
  api_search,
  pothos,
  get_photos_by_slug,
  search_autocomplete,
  send_message,
  login,
  logout,
  signup,
  save_search,
  calcula_down_payment,
  calcula_mortgage,
  get_user,
  update_user,
  change_password,
  recovery_password,
  new_listings,
  api_search_subdivision,
  api_search_condoslist,
  get_walk_score,
  get_transit_score,
  get_schools,
  property_search,
  get_new_listings,
  get_new_listings_under_300,
  get_new_listings_apartament_rent,
  get_property_detail,
  get_property_history,
  get_walkscores,
  get_transit_scores,
  get_nearby_schools,
  get_listings_by_subdivisionname,
  find_lat_lng,
  save_listing_search,
  add_favorite,
  get_listings_closed,
  get_listings_by_address_mls,
  get_property_closed,
  send_email,
  getService
};
