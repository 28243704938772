import React, { Component } from "react";
import "./carousel.css";
import { isMobile } from "react-device-detect";
import ItemsCarousel from "react-items-carousel";
import range from "lodash/range";

import { Button } from "antd";

class MyCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfCards: 3
    };
  }

  componentWillMount() {
    this.setState({
      children: [],
      activeItemIndex: 0
    });

    setTimeout(() => {
      this.setState({
        children: this.createChildren(20)
      });
    }, 100);
  }
  createChildren = n =>
    range(n).map(i => (
      <div key={i} style={{ height: 100, backgroundColor: "#333" }}>
        {i}
      </div>
    ));
  changeActiveItem = activeItemIndex => this.setState({ activeItemIndex });

  getImages = () => {
    let dataSource = this.props.dataSource || [];

    console.log("dataSource: ", dataSource);
    return dataSource.map((url, index) => (
      <div className="item" key={index}>
        <div
          className="image"
          style={{
            backgroundImage: "url(" + url + ")",
            backgroundSize: "cover",
            height: 100,
            width: 100
          }}
        />
      </div>
    ));
  };
  render() {
    let dataSource = this.props.dataSource || [];
    let { activeItemIndex, numberOfCards } = this.state;

    let { children, total, className } = this.props;
    if (typeof className === "undefined") {
      className = "my-carousel ";
    }

    console.log(">>> TOTAL", total, numberOfCards);
    if (total < numberOfCards) {
      numberOfCards = total;
    }
    if (isMobile) {
      numberOfCards = 1;
    }
    if (numberOfCards === 1) {
      className += " simple-card";
    }

    return (
      <div>
        <ItemsCarousel
          className={className}
          // Placeholder configurations
          enablePlaceholder
          numberOfPlaceholderItems={dataSource.lenght > 0 ? 5 : 0}
          minimumPlaceholderTime={1000}
          placeholderItem={
            <div style={{ height: 100, background: "#900" }}>Placeholder</div>
          }
          width={100}
          height={100}
          // Carousel configurations
          numberOfCards={numberOfCards}
          gutter={12}
          showSlither={true}
          firstAndLastGutter={true}
          freeScrolling={false}
          // Active item configurations
          requestToChangeActive={this.changeActiveItem}
          activeItemIndex={activeItemIndex}
          activePosition={"center"}
          chevronWidth={24}
          rightChevron={<Button shape="circle" icon="right" size={"large"} />}
          leftChevron={<Button shape="circle" icon="left" size={"large"} />}
          outsideChevron={false}
        >
          {this.getImages()}
        </ItemsCarousel>
      </div>
    );
  }
}
export default MyCarousel;
