import React, { Component } from "react";
import { withRouter } from "react-router";
import ListingCard from "./ListingCard";
import { List, Pagination, Row } from "antd";
import "./listingscards.css";
import qs from "qs";
import Skeleton from "../com/skeleton/SkeletonTpl";
import { add_favorite } from "../../services";
import { Modal, Dropdown, Button, Col, Menu, message } from "antd";
import { Helper } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const { confirm } = Modal;

const MAX_SKELETON = 30;

const SortBy = props => {
  let { onClick } = props;
  const menu = (
    <Menu
      selectedKeys={[props.value]}
      onClick={onClick}
      style={{
        width: "100%"
      }}
    >
      <Menu.Item key="LOW-PRICE">Price Low-High</Menu.Item>
      <Menu.Item key="HIGH-PRICE">Price High-Low</Menu.Item>
      <Menu.Item key="OLD-LISTINGS">Date Old-New</Menu.Item>
      <Menu.Item key="NEW-LISTINGS">Date New-Old</Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} className={props.className} trigger={["click"]}>
      <Button size="large">
        <FontAwesomeIcon
          className="icon-fab"
          icon={["fas", "sort-amount-down-alt"]}
        />{" "}
        Sort By
      </Button>
    </Dropdown>
  );
};

class ListingsCards extends Component {
  state = {
    loading: false,
    preloading: true,
    hasMore: true,
    data: [],
    pageStart: 0,
    page: 1,
    order_by: undefined,
    loadMore: true
  };
  handlePagination = value => {
    //window.scrollTo(0, 0);
    this.setState({
      page: this.state.page !== value ? value : undefined
    });
    this.changeQuery("page", value);
    if (typeof this.props.onChange !== "undefined") {
      this.props.onChange(value);
    }
  };
  sortByChange = e => {
    let value = e.key || "";
    this.setState({
      order_by: this.state.order_by !== value ? value : undefined
    });
    if (this.props.sortByChange) this.props.sortByChange(value);
  };
  changeQuery = (name, value) => {
    let { location, history } = this.props;
    let { search } = location;
    let query = qs.parse(search.replace(/\?/, ""));
    if (name && value) {
      query[name] = query[name] !== value ? value : undefined;
      history.replace(`?${qs.stringify(query)}`);
    }
    if (query["page"]) query["page"] = Number(query["page"]);
    this.setState({
      ...query
    });
  };
  componentDidMount() {
    this.changeQuery();

    this.setState({
      data: this.defaultData()
    });
  }
  defaultData() {
    return Array.apply(null, {
      length: this.props.pageSize || MAX_SKELETON
    }).map((item, index) => {
      return index;
    });
  }
  addFavorite = ListingKeyNumeric => {
    const me = this;
    let { history, location } = this.props;
    //let { ListingKeyNumeric } = this.props;
    let paramas = {
      userId: localStorage.userId,
      ListingKeyNumeric
    };
    if (!localStorage.userId)
      return confirm({
        title: "Add to favorites?",
        content: "Do you Want to add to favorites?",
        onOk() {
          history.push("/login");
        }
      });
    me.add_to_favorite(paramas);
  };
  add_to_favorite = paramas => {
    add_favorite(paramas)
      .then(response => {
        let { data } = response;
        message.info(data.description);
      })
      .catch(error => message.error(error.message));
  };
  handleSaveSearch = e => {
    let { search, history, location } = this.props;

    if (!search) search = {};
    let data = search.data || {};
    const params = this.props.match.params || {};
    const { transaction_type } = params;

    /* Query String */
    let query = location.search || "";
    let values = qs.parse(query.replace("?", ""));

    console.log("Save Properties? ", values);
    return confirm({
      title: "Save Listing?",
      content: "Do you Want to save Listing?",
      onOk() {
        if (typeof localStorage.userId === "undefined") {
          return history.push("/login", {
            state: params
          });
        }
        params["userId"] = localStorage.userId;
        params["hostApp"] = "GOW";
        params["PropertyType"] = transaction_type;
        params["url"] = `${window.location.pathname}${window.location.search ||
          ""}`;

        for (var key in values) {
          params[key] = values[key];
        }
      }
    });
  };
  render() {
    let { loading, pagination = {}, pageSize = 30 } = this.props;
    let data = this.props.data || [];
    let { CurrentPage = 1, TotalRecords = data.length } = pagination;
    data = !loading ? data : this.state.data;

    let { match, location } = this.props;
    const { params } = match;
    let { City, StateOrProvince, transaction_type } = params;
    if (typeof transaction_type === "undefined")
      transaction_type = this.props.transaction_type;
    let transactionType = transaction_type == "Sale" ? "sales" : "rentals";

    let query = location.search || "";
    let values = qs.parse(query.replace("?", ""));
    let propertySubTypes =
      typeof values["PropertySubType"] !== "undefined"
        ? values["PropertySubType"]
        : "";

    let types_array = ["SingleFamilyResidence", "UnimprovedLand"];
    let types_array_correct = ["Home", "Land"];
    propertySubTypes = propertySubTypes
      .split(",")
      .map(item => {
        let index = types_array.indexOf(item);
        if (index !== -1) {
          return types_array_correct[index];
        }
        return item;
      })
      .join(",");
    let title =
      this.props.title ||
      `
    ${propertySubTypes} ${transactionType} ${City ? " in " + City + ", " : ""}
    ${StateOrProvince ? StateOrProvince : ""}`;

    return (
      <div
        className={
          !loading && TotalRecords === 0
            ? "listings listings-empty"
            : "listings"
        }
      >
        <div className="listings-cards">
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 3,
              lg: 2,
              xxl: 3
              /*
            xl: 6,
            xxl: 3  */
            }}
            header={
              <>
                {TotalRecords > 0 && (
                  <Row
                    justify="space-around"
                    align="middle"
                    className="listing-header"
                  >
                    <Col xs={24} sm={18} md={18} className="listign-title">
                      <h3>{title}</h3>
                      {!loading ? (
                        <span>{`${Helper.decimal(
                          TotalRecords
                        )} total ${transactionType} `}</span>
                      ) : (
                        <span>Searching...</span>
                      )}
                    </Col>
                    <Col xs={24} sm={6} md={6} className="tools-listings">
                      <div className="search-item"></div>
                      <SortBy
                        className="btn-action"
                        value={String(this.state.order_by)}
                        onClick={this.sortByChange}
                        style={{
                          width: "100%"
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </>
            }
            dataSource={data}
            renderItem={item => {
              /* if (typeof item === "object")
                axios
                  .get(
                    `http://54.84.6.129/syncPhotos.php?mui=${
                      item.ListingKeyNumeric
                    }`
                  )
                  .then(response => {
                    console.log("Done!", response);
                  })
                  .catch(err => console.log(err)); */
              return (
                <List.Item>
                  {!loading ? (
                    <ListingCard
                      displayActions={true}
                      handleAddFavorite={this.addFavorite}
                      {...item}
                    />
                  ) : (
                    <Skeleton />
                  )}
                </List.Item>
              );
            }}
          />

          {!loading && TotalRecords > 0 && pagination && (
            <Pagination
              className="pagination"
              showTotal={total => `Total ${total} items`}
              size="large"
              onChange={value => {
                window.scrollTo(0, 0);
                this.handlePagination(value);
              }}
              current={this.state.page}
              defaultCurrent={Number(CurrentPage)}
              total={TotalRecords}
              pageSize={pageSize}
            />
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(ListingsCards);
