import React, { Component } from "react";
import { Input, Icon, Button, Row, message, Col } from "antd";
import "./profileform.css";
import { AdvancedForm } from "../index";
import S3Button from "../form/S3Button";
import { getService } from "../../../services";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
const service = getService("users");
const Form = styled(AdvancedForm)`
  &.ant-form-vertical .ant-form-item {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }
  & input {
    /* border: 0px solid #d9d9d9 !important;
    border-bottom: 1px solid #cccccc54 !important;
    border-radius: 0px !important; */
  }
  & input[readonly] {
    /* border: 0px solid #d9d9d9 !important;
    border-bottom: 1px solid #cccccc54 !important; */
  }

  @media (max-width: 480px) {
    margin: 4px !important;
    /* box-shadow: 3px 3px 3px #ccc; */
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    padding-top: 64px;
  }
`;
const PictureContainer = styled.div`
  @media (max-width: 480px) {
    margin-top: 20px;
    z-index: 9999999999 !important;
    position: absolute;
    top: 30px;
    left: calc(50% - 50px);
  }
`;
class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMe: false,
      record: props.record || {},
      userId: localStorage.userId,
      username: null,
      laoding: false,
      email: null,
      first_name: null,
      last_name: null,
      phone: null,
      address: null,
      city: null,
      password: null,
      state: null
    };
  }
  handleOnChange = url => {
    let { userId } = this.state;
    this.setState({
      laoding: true
    });
    service
      .patch(userId, {
        picture: url
      })
      .then(record => {
        this.setState({
          record,
          laoding: false
        });
      });
  };
  onSubmit = (err, params) => {
    if (!err) {
      let { userId } = this.state;
      this.setState({
        laoding: true
      });
      service
        .patch(userId, params)
        .then(record => {
          this.setState({
            record,
            laoding: false
          });
          if (this.props.onSubmit) this.props.onSubmit(record);
          message.info("User updated!");
        })
        .catch(err => message.error(err.message));
    }
  };

  componentWillReceiveProps(props) {
    let { record, isMe } = props;

    if (record)
      return this.setState({
        ...record,
        isMe,
        laoding: false
      });
  }
  componentWillMount() {
    let { userId } = this.state;

    this.setState({
      laoding: true
    });
    service
      .get(userId)
      .then(response => {
        this.setState({
          ...response,
          laoding: false
        });
      })
      .catch(err => console.log(err));
  }
  render() {
    let {
      email,
      first_name,
      last_name,
      phone,
      address,
      about_me,
      city,
      password,
      state,
      userId,
      isMe,
      laoding
    } = this.state;

    return (
      <>
        {isMobile && (
          <PictureContainer
            className="picture-container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px"
            }}
          >
            {
              <S3Button
                disabled={!isMe}
                record={this.props}
                value={this.props.record.picture}
                idComponent="btn-send"
                onChange={this.handleOnChange}
                path="users"
                finalPath="picture"
                source="image"
                id={userId}
              />
            }
          </PictureContainer>
        )}
        <>
          {
            <Form
              formLayout="vertical"
              autoReset={false}
              readOnly={!isMe}
              onSubmit={this.onSubmit}
              footer={
                isMe && (
                  <div>
                    <Row type="flex" justify="start" align="middle">
                      <Button
                        laoding={this.state.laoding}
                        disabled={!isMe}
                        size="large"
                        block={isMobile}
                        /* style={{ minWidth: "32%" }} */
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                      >
                        Update Account
                      </Button>
                    </Row>
                  </div>
                )
              }
            >
              <div>
                <Input
                  size="large"
                  style={{ width: "50%" }}
                  label="First Name"
                  placeholder="First Name"
                  initial={first_name}
                  name="first_name"
                  validator={[
                    {
                      required: false,
                      message: "Please input your First name!"
                    }
                  ]}
                />

                <Input
                  size="large"
                  style={{ width: "50%" }}
                  label="Last Name"
                  placeholder="Last Name"
                  initial={last_name}
                  name="last_name"
                  validator={[
                    { required: false, message: "Please input your last name!" }
                  ]}
                />
                <Input
                  size="large"
                  style={{ width: "50%" }}
                  label="Email"
                  placeholder="Email"
                  initial={email}
                  name="email"
                  validator={[
                    { required: true, message: "Please input your email!" }
                  ]}
                />
                <Input
                  size="large"
                  label="Phone"
                  placeholder="Phone"
                  initial={phone}
                  name="phone"
                  validator={[
                    { required: false, message: "Please input your phone!" }
                  ]}
                />
                <Input
                  size="large"
                  flex={1}
                  label="Address"
                  placeholder="Address"
                  initial={address}
                  name="address"
                  style={{ width: "100%" }}
                  validator={[
                    { required: false, message: "Please input your adress!" }
                  ]}
                />
                <Input
                  flex={0.3}
                  size="large"
                  style={{ width: "40%" }}
                  label="City"
                  placeholder="City"
                  initial={city}
                  name="city"
                  validator={[
                    { required: false, message: "Please input your city!" }
                  ]}
                />
                <Input
                  flex={0.3}
                  size="large"
                  style={{ width: "40%" }}
                  label="State"
                  placeholder="State"
                  initial={state}
                  name="state"
                  validator={[
                    { required: false, message: "Please input your state!" }
                  ]}
                />
                <Input
                  flex={0.3}
                  size="large"
                  style={{ width: "20%" }}
                  label="Zip"
                  placeholder="Zip"
                  initial={state}
                  name="zipcode"
                  validator={[
                    { required: false, message: "Please input your Zip Code!" }
                  ]}
                />
                {isMe && (
                  <Input.TextArea
                    size="large"
                    flex={1}
                    label="About me"
                    placeholder="About me"
                    initial={about_me}
                    name="about_me"
                    style={{ width: "100%" }}
                    validator={[{ required: false }]}
                  />
                )}
                {isMe && (
                  <Input.Password
                    size="large"
                    style={{ width: "100%" }}
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    label="Current Password"
                    placeholder="Current Password"
                    type="current_password"
                    name="current_password"
                    validator={[
                      {
                        required: true,
                        message: "Please input your current password!"
                      }
                    ]}
                  />
                )}
              </div>
            </Form>
          }
        </>
      </>
    );
  }
}
export default ProfileForm;
