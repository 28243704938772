import { List, Menu, Dropdown, Button } from "antd";
import React, { useEffect, useState } from "react";
import { ListingListWrapper } from "./Styles";
import _ from "lodash";
import { getService } from "../../services";
import ListingCard from "../../components/listings/ListingCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const SortBy = (props) => {
  let { onClick } = props;
  const menu = (
    <Menu
      selectedKeys={[props.value]}
      onClick={onClick}
      style={{
        width: "100%",
      }}
    >
      <Menu.Item key="LOW-PRICE">Price Low-High</Menu.Item>
      <Menu.Item key="HIGH-PRICE">Price High-Low</Menu.Item>
      <Menu.Item key="OLD-LISTINGS">Date Old-New</Menu.Item>
      <Menu.Item key="NEW-LISTINGS">Date New-Old</Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} className={props.className} trigger={["click"]}>
      <Button size="large">
        <FontAwesomeIcon
          className="icon-fab"
          icon={["fas", "sort-amount-down-alt"]}
        />{" "}
        Sort By
      </Button>
    </Dropdown>
  );
};

const ListingCardList = ({
  title,
  pageSize = 10,
  onLoad,
  reference,
  ...props
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState();
  const [filterDefaultValues, setFilterDefaultValues] = useState([]);
  const sortByChange = (e) => {
    let value = e.key || "";
    console.log("onChange: ", e);
  };
  const defaultRenderItem = (item) => {
    return (
      <List.Item>
        <ListingCard
          displayActions={true}
          /* handleAddFavorite={this.addFavorite} */
          {...item}
        />
      </List.Item>
    );
  };
  const getData = () => {
    if (reference) {
      const service = getService(reference);
      setLoading(true);
      service
        .find({
          query: {
            ...filterDefaultValues,
            $sort: { createdAt: -1 },
            $limit: pageSize,
          },
        })
        .then(({ data, meta }) => {
          setPagination(meta);
          setDataSource(data);
          setLoading(false);
          if (onLoad) onLoad(data);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (!_.isEqual(filterDefaultValues, props.filterDefaultValues))
      setFilterDefaultValues(props.filterDefaultValues);
  }, [props.filterDefaultValues]);
  useEffect(() => {
    if (filterDefaultValues) getData();
  }, [filterDefaultValues]);
  return (
    <ListingListWrapper>
      {title && (
        <div className="listing-title">
          <div className="container-section-dropdown">
            <h3>{title}</h3>
            <div>
              <SortBy
                className="btn-action"
                /* value={String(this.state.order_by)}
                 */
                onClick={sortByChange}
                style={{
                  width: "100%",
                }}
              />
            </div>
          </div>

          {pagination && (
            <span>
              {pagination.TotalRecords > 1
                ? `${pagination.TotalRecords} total listings`
                : " listing"}
            </span>
          )}
        </div>
      )}
      <List
        grid={{
          gutter: 8,
          xl: 2,
          lg: 2,
          md: 2,
          sm: 2,
          xs: 1,
        }}
        loading={loading}
        dataSource={dataSource}
        renderItem={defaultRenderItem}
        /* pagination={{
          onChange: (page) => {
            setPagination(page);
          },
          // showTitle: "hola",
          pageSize: 6,
        }} */
      />
    </ListingListWrapper>
  );
};
export default ListingCardList;
