import React, { Component } from "react";
// import { notification , message, Icon } from 'antd';
import { Progress } from "antd";

import S3Uploader from "react-s3-uploader";
import { URL_S3, URL_S3_SERVER } from "../../../utils/constants";
import styled from "styled-components";
import { Icon } from "antd";
import uuid from "react-uuid";
const Logo = "/images/avatar.svg";
const Wrapper = styled.label`
  cursor: pointer;
  :hover .btn-upload {
    visibility: visible;
    opacity: 1;
  }
  width: 110px;
  height: 110px;
  display: block;

  box-shadow: 4px 4px 4px rgba(244, 244, 244, 0.8);
  border-radius: 50%;
  overflow: hidden;
  margin: 0px auto;
  border: 1px solid rgba(244, 244, 244, 1);
  & img {
    object-fit: contain;
    background: #fff;

    vertical-align: middle;
    border-style: none;
  }
  &.circle {
  }
`;

const Container = styled.div`
  width: 110px;
  height: 110px;
  display: block;
  position: relative;
  margin-bottom: 1rem !important;
  overflow: hidden;

  & .content {
    overflow: hidden;
  }

  /* background: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%); */
`;
const UploadButton = styled.div`
  visibility: hidden;
  height: 110px;
  width: calc(110px - 4px);
  background: #40a9ffc4;
  z-index: 99;
  position: absolute;
  top: 0px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: white;

  transition: 0.3s;
  opacity: 0.1;

  i {
    padding: 20px;
    /* border-radius: 50%;
    background: #ffffffc7; */
  }
`;

const ProgreessBar = styled(Progress)`
  position: absolute;
  z-index: 1000;
  position: absolute;
  top: 8px;
  left: calc(50% - 60px);
`;
class S3Button extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    progress: 0,
    image: null,
    idComponent: uuid(),
    onChange: null
  };

  componentWillReceiveProps({ record, source, value }) {
    this.setState({
      image: value
    });
  }
  componentDidMount() {
    let { record, source, value } = this.props;

    this.setState({
      image: value || record ? record[source] : null,
      id: this.props.id || uuid()
    });
  }
  onUploadStart = (file, next) => {
    this.setState({ name_file: file.name });
    next(file);
  };
  onUploadProgress = (progress, ...props) => {
    /* console.log(progress); */
    this.setState({ progress });
  };

  /* onSignedUrl = (...props) => {};

  */

  /* onUploadError = error => {};
   */
  onUploadFinish = urls => {
    let { onChange, id } = this.props;
    let image = urls.fileKey;
    this.setState({
      image
    });
    if (onChange) onChange(image, id);
  };

  render() {
    let { file = {}, match, label, source } = this.props;
    const { progress, id } = this.state;

    if (file.name) return <div className="s3Button mr-2">{file.name}</div>;
    return (
      <Container>
        <div className="content">
          {
            <Wrapper
              htmlFor={this.state.idComponent}
              className="wrapper"
              variant="outlined"
              color="primary"
            >
              <img
                src={this.state.image ? URL_S3 + "/" + this.state.image : Logo}
              />
              {!this.props.disabled && (
                <UploadButton className="btn-upload">
                  <Icon type={this.state.image ? "edit" : "upload"} />
                </UploadButton>
              )}
              {progress > 0 && progress < 100 && (
                <ProgreessBar
                  strokeLinecap="square"
                  type="circle"
                  percent={progress}
                />
              )}
            </Wrapper>
          }
        </div>
        <label className="s3Button">
          <S3Uploader
            disabled={this.props.disabled}
            id={this.state.idComponent}
            signingUrl="/s3Client/sign"
            signingUrlMethod="GET"
            accept="*/*"
            s3path={`${this.props.path}/${id}/${this.props.finalPath}/`}
            preprocess={this.onUploadStart}
            onSignedUrl={this.onSignedUrl}
            onProgress={this.onUploadProgress}
            onError={this.onUploadError}
            onFinish={this.onUploadFinish}
            signingUrlWithCredentials={true} // in case when need to pass authentication credentials via CORS
            uploadRequestHeaders={{ "x-amz-acl": "public-read" }} // this is the default
            contentDisposition="auto"
            scrubFilename={filename => filename.replace(/[^\w\d_\-.]+/gi, "")}
            server={URL_S3_SERVER}
            // inputRef={cmp => this.uploadInput = cmp}
            autoUpload={true}
            className="s3-uploader"
            style={{ visibility: "hidden", display: "none" }}
          />
        </label>
      </Container>
    );
  }
}

export default S3Button;
