import React, { Component } from "react";
import LoginForm from "../../../components/com/authentication/login/LoginForm";
import { message } from "antd";
import { authentication, getService } from "../../../services/services";
import "./login.css";

import logo from "../../../images/bruno-square.jpg";

class Login extends Component {
  onSubmit = (err, params) => {
    let { email } = params;
    let { history, location } = this.props;

    if (err) {
      return message.error(err.message);
    }

    return authentication(params)
      .then(({ user, accessToken }) => {
        let { email } = user;
        let username = email.substr(0, email.indexOf("@"));
        localStorage.setItem("userId", user._id);
        localStorage.setItem("role", user.role);
        localStorage.setItem("access_token", accessToken);
        localStorage.setItem("username", username);
        localStorage.setItem("user", JSON.stringify(user));

        /* Go to previous route */

        let { pathname, search } = location.state || {
          pathname: "/"
        };
        history.push({
          pathname,
          search,
          autoSave: true
        });
      })
      .catch(err => message.error(err.message));
  };
  validateToken = token => {
    let { history } = this.props;
    let service = getService("user-confirmation");
    service
      .create({
        token
      })
      .then(({ success }) => {
        message.info("Use your user and password to access!");
        history.push("/login");
      })
      .catch(err => message.error(err.message));
  };
  componentDidMount() {
    let { history, token } = this.props;
    if (typeof localStorage.userId !== "undefined") history.push("/");
  }
  componentWillMount() {
    let { token } = this.props.match.params;
    if (token) {
      this.validateToken(token);
    }
    console.log(window.previousLocation);
  }
  render() {
    return (
      <section id="wrapper" className="login-register">
        <div className="login-box login-sidebar">
          <div className="white-box">
            <div className="conteim">
              <a href="/" className="db">
                <img src={logo} width="90" />
              </a>
            </div>
            <LoginForm formLayout="vertical" onSubmit={this.onSubmit} />
          </div>
        </div>
      </section>
    );
  }
}
export default Login;
