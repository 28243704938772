import React, { useState, useEffect } from 'react';
import { Radio } from 'antd';
import { WrapperRadioGroupList } from "./Styles";
const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
};
const RadioGroupList = ({ ...props }) => {
    const [dataSource, setDataSource] = useState([]);
    const [value, setValue] = useState(props.initialValue);
    const onChange = e => {
        let { value } = e.target;
        setValue(value);
        if (props.onChange)
            props.onChange(value);
    };
    const renderItem = (item, index) => {
        return (<Radio
            className={item.color}
            style={radioStyle} value={item.value}>
            {item.text}
        </Radio>);
    }
    useEffect(() => {
        if (props.dataSource)
            setDataSource(props.dataSource);
    }, [props.dataSource])
    return (<WrapperRadioGroupList>
        <Radio.Group onChange={onChange} value={value}>
            {dataSource.map(renderItem)}
        </Radio.Group>
    </WrapperRadioGroupList>);
}
export default RadioGroupList;