import styled from "styled-components";
export const Wrapper = styled.div`
    & .menu-list .ant-menu {
        border: 0px !important;
        margin: 10px 20px;
    }
    & .menu-list .ant-menu-item {
        font-weight: bold!important;
        font-size: 16px!important;
    }
`;