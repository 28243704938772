import React, { useEffect, useState } from "react";
import "./dropdownlist.css";
import { isMobile } from "react-device-detect";
import { Menu, Icon, Dropdown, Button, Checkbox } from "antd";
import { Wrapper } from "./Styles";
const style = {
  minWidth: 140
};
const MenuList = props => {
  const [checked, setChecked] = useState(true);
  const [selection, setSelection] = useState([]);
  let value = props.value || [];
  let { title, selectAll } = props;
  if (typeof value === "string") value = value.split(",");
  let data = props.data || [];
  /* let selection = props.selection || []; */
  /*  selection = [...value, ...selection]; */
  const handleClick = (e) => {
    let { key } = e;
    let new_selection = selection;

    if (key == "footer") {

      return;
    }
    if (key == "select-all") {
      console.log("Items:: ", data);
      new_selection = data
        .filter(it => (it.value != "select-all"))
        .map(it => (it.value));
      if (checked) {
        new_selection = [];
      }
      setSelection(new_selection);
      setChecked(checked => !checked);
      if (props.onSelectAll)
        props.onSelectAll(new_selection);
      return;
    }
    if (props.onChange) {
      if (!new_selection.includes(key)) {
        new_selection = [...selection, key];
      } else {
        new_selection = selection.filter(it => (it != key));
      }
      setChecked(!(new_selection.length < data.length));
      setSelection(new_selection);
      props.onChange(new_selection);
    }
  }
  const handleDone = () => {
    if (props.onDone) props.onDone();
  }
  useEffect(() => {
    console.log("Selection: ", value, props.selection)
    if (props.selection) {
      setSelection([...value, ...props.selection]);
    }
    if (checked && data) {
      let new_selection = data
        .filter(it => (it.value != "select-all"))
        .map(it => (it.value));
      setSelection(new_selection);
    }
  }, [])
  return (
    <Menu
      onClick={handleClick}
      selectedKeys={selection}
      defaultSelectedKeys={["select-all"]}
      mode="vertical"
      multiple={true}
      selectable={true}
    >
      <Menu.Item
        className="item-select-all" key={"select-all"}>
        {selectAll && <div className="select-all-container">
          <Button
            block
            size="large"
            type="link">
            <Icon
              style={{
                color: "red"
              }}
              size="large" type={!checked ? "check-circle" : "close-circle"} theme="filled" />
            {!checked ? "Select All" : "Deselect All"}
          </Button>
        </div>}
      </Menu.Item>
      {data.map((item, index) => (
        <Menu.Item key={item.value}>
          <Checkbox checked={selection.includes(item.value)} />{" "}
          {item.text}
        </Menu.Item>
      ))}
      <Menu.Item
        className="item-footer" key={"footer"}>
        {<div className="footer-container">
          <Button
            onClick={handleDone}
            className="btn-white"
            size="large"
            type="primary">
            Done
          </Button>
        </div>}
      </Menu.Item>
    </Menu>
  );
};
class DropDownList extends React.Component {
  state = {
    selection: [],
    visible: false
  };
  handleClick = e => {
    let value = e.key;
    let { selection } = this.state;
    if (selection.indexOf(value) === -1) {
      selection.push(value);
    } else {
      selection = selection.filter(item => item !== value);
    }
    this.setState({
      selection
    });
    if (typeof this.props.onChange !== "undefined")
      this.props.onChange(selection);
  };
  componentWillReceiveProps(props) {
    let { value } = props;
    if (value === null || value === "") value = [];
    if (typeof value === "string") value = value.split(",");
    this.setState({
      selection: value || []
    });
  }
  handleDone = () => {
    this.setState({
      visible: false
    });
  }
  render() {
    let { selection } = this.state;
    return (
      <Dropdown
        visible={this.state.visible}
        overlayClassName="menu-list"
        style={{ width: 200 }}
        trigger={[isMobile ? "click" : "hover"]}
        overlayStyle={this.props.overlayStyle}
        onVisibleChange={visible => {
          this.setState({ visible });
        }}
        overlay={
          <MenuList
            value={this.props.value}
            onDone={this.handleDone}
            /* defaultSelectedKeys={this.props.defaultValues} */
            data={this.props.data}
            data={this.props.data}
            onSelectAll={this.props.onSelectAll}
            onChange={this.props.onChange}
            selection={selection}
            title={this.props.title}
            footer={this.props.footer}
            selectAll={this.props.selectAll || true}
          />
        }
      >
        <Button style={style}>
          {this.props.placeholder || ""}{" "}
          {selection.length > 0 ? `(${selection.length})` : ""}{" "}
          <Icon type="down" />
        </Button>
      </Dropdown>
    );
  }
}
export default DropDownList;
