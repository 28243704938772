/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, {Component} from "react";
import PropTypes from "prop-types";

//import Header from "../../components/header";
import Menu from "../../components/menu";
import { Layout, Row, Col, Icon } from "antd";
import "./realestateclasses.css";

const { Content } = Layout;
const background_header = require("./images/slide-2.jpg");
//const imgbruno = require("./images/images.jpg");
const img2 = require("./images/icon-home-apply.png");
const img3 = require("./images/icon-home-visit.png");
const img1 = require("./images/icon-home-course.png");


class RealEstateClasses extends Component {
  constructor(){
    super();
  }

  state = {
      show0: 'block',
      show1: 'none',
      show2: 'none',
      show3: 'none'
  };

  show1 = () =>{
    //document.getElementsByClassName("show1").style.display = 'block';
    this.setState(
      { 
        show1: 'block',
        show2: 'none',
        show3: 'none',
        show0: 'none'
      }
    );
  };

  show2 = () =>{
    //document.getElementsByClassName("show1").style.display = 'block';
    this.setState(
      { 
        show1: 'none',
        show2: 'block',
        show3: 'none',
        show0: 'none'
      }
    );
  };

  show3 = () =>{
    //document.getElementsByClassName("show1").style.display = 'block';
    this.setState(
      { 
        show1: 'none',
        show2: 'none',
        show3: 'block',
        show0: 'none'
      }
    );
  };

  render(){
    return (
        <div className="realestateclasses">
            <header className="head_real">
                <Menu />
                <div className="conte-middle">
                </div>
            </header>

            <Layout style={{background: '#fff'}}>
              <div id="block-block-3" className="block block-block">
                <div className="content">
                  <div className="container_main">
                    <div className="row" id="main-boxes">
                      <Col className="span4">
                        <div className="box-style-2 green c_show1" onClick={this.show1}>
                              <img src={img1} alt="Real Estate Classes" /><h3>Real Estate Classes</h3>
                              <p>One World International offers discount on real estate education.
                              We sponsor discounted registration for the Real Estate Pre-License Course (FREC 63). Contact us to receive your credit.
                              </p>
                        </div>
                      </Col>
                      
                      <Col className="span4">
                        <div className="box-style-2 orange c_show2" onClick={this.show2}>
                              <img src={img2} alt="Apply now" /><h3>Register Now</h3>
                              <p>Launch your real estate career by taking the Sale Associate Real Estate Course now.
                                    Call us now for more information. 
                              </p>
                        </div>
                      </Col>
                      
                      <Col className="span4">
                        <div className="box-style-2 red c_show3" onClick={this.show3}>
                              <img src={img3} alt="Questions on Education" /><h3>Classes Information</h3>
                              <p>Contact One World International on how to become a successful license real estate agent in Miami. We can provide your with a great discount on your real estate course.</p>
                        </div>
                      </Col>
                    </div>
                  </div>
                </div>
              </div>


              <div className="container_main">
                <div className="row">
                  <aside className="span4 ">
                          
                    <div id="block-block-10" className="block block-block">
                      <div className="content">
                        <div className="box-style-1 borders">
                            <div className="feat">
                              <h3><Icon type="team" style={{ fontSize: '24px', color: '#3fc1c0' }}/>{" "} Expert teachers</h3>
                              <p>Real Estate classes are taught by expert teachers with deep knowledge of the industry. Real life situation help students learn how to figure out the best way to solve problems and close the deal. </p>
                            </div>
                            
                            <hr className="" /><div className="feat">
                            <h3><Icon type="audio" style={{ fontSize: '24px', color: '#3fc1c0' }}/>{" "} Online courses</h3>
                              <p>Real Estate Pre-License Courses are available in online format. Learn at your own pace; consult with teachers who are available to address all your questions and concerns. </p>
                            </div>
                            
                            <hr className="" /><div className="feat">
                              <i className="icon-book icon-3x"></i>
                              <h3><Icon type="book" style={{ fontSize: '24px', color: '#3fc1c0' }}/>{" "}Continuous Education</h3>
                              <p>When you are a part of the One World International Team, you always have to learn. We provide ongoing education in marketing and social media for agents to grow and improve their skills. </p>
                            </div>
                            
                        </div>
                      </div>
                    </div>
                  </aside>


                  <section className="span8">
                    <div className="col-right">
                      <div className="block block-system">
                        <div className="content">
                          <div className="node clearfix">

                            <div className="content">
                              
                              <div className="field field-name-body">

                                <div className="field-items master">
                                  <div className="field-item even show0" style={{'display': this.state.show0}}><h2>Upcoming Courses</h2>
                                      <div className="strip-courses gray">
                                       <div className="title-course">
                                        <h3>Days (1 week)</h3>
                                           <ul>
                                              <li><Icon type="calendar" theme="filled" />{" "}Mon- Sun 9:00am- 6:00pm</li>
                                           </ul>
                                        </div>
                                        <div className="description">
                                          <div className="subti4">63 Hour Real Estate Pre-License Course</div>
                                          <table className="tbl1">
                                            <tbody>
                                              <tr>
                                                <td className="cellpas">
                                                  <div className="tido">Doral | North Dade | Broward | Boca Raton | Lantana</div>
                                                </td>
                                              </tr>
                                              <tr>
                                                  <td className="cellpas">
                                                      <table className="tbl2">
                                                      <tbody>
                                                      <tr>
                                                        <td className="td2">Jun 6</td>
                                                        <td className="td2">Jul 25</td>
                                                        <td className="td2">Aug 8</td>
                                                        <td className="td2">Sep 26</td>
                                                        <td className="td2">Oct 10</td>
                                                        <td className="td2">Nov 28</td>
                                                        <td className="td2">Dec 12</td>    
                                                      </tr>
                                                      </tbody>
                                                      </table>
                                                  </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <div className="strip-courses ">
                                        <div className="title-course">
                                          <h3>Mornings (4 weeks)</h3>
                                             <ul>
                                                <li><Icon type="calendar" theme="filled" />{" "}Mon/ Wed/ Fri 9:00am-1:00pm – Plus Last Weekend</li>
                                             </ul>
                                        </div>
                                        <div className="description">
                                            <div className="subti4">63 Hour Real Estate Pre-License Course</div>
                                            <table className="tbl1">
                                              <tbody>
                                              <tr>
                                                <td className="cellpas">
                                                  <div className="tido">Doral | North Dade | Broward | Boca Raton | Lantana</div>
                                                </td>
                                              </tr>
                                              <tr>
                                                  <td className="cellpas">
                                                      <table className="tbl2">
                                                      <tbody>
                                                      <tr>
                                                        <td className="td2">Jun 1</td>
                                                        <td className="td2">Jun 27</td>
                                                        <td className="td2">Jul 25</td>
                                                        <td className="td2">Aug 22</td>
                                                        <td className="td2">Sep 19</td>
                                                        <td className="td2">Oct 17</td>
                                                        <td className="td2">Nov 14</td>  
                                                      </tr>
                                                      </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                              </tbody>
                                            </table>
                                        </div>
                                      </div>
                                      <div className="strip-courses gray">
                                        <div className="title-course">
                                          <h3>Evenings (4 weeks)</h3>
                                          <ul>
                                            <li><Icon type="calendar" theme="filled" />{" "}Mon/ Wed/ Fri 6:30pm- 10:30pm – Plus Last Weekend</li>
                                          </ul>
                                        </div>
                                        <div className="description">
                                            <div className="subti4">63 Hour Real Estate Pre-License Course</div>
                                            <table className="tbl1">
                                              <tbody>
                                              <tr>
                                                <td className="cellpas">
                                                  <div className="tido">Doral | North Dade | Broward | Boca Raton | Lantana</div>
                                                </td>
                                              </tr>
                                              <tr>
                                                  <td className="cellpas">
                                                      <table className="tbl2">
                                                      <tbody>
                                                      <tr>
                                                        <td className="td2">Jun 1</td>
                                                        <td className="td2">Jun 27</td>
                                                        <td className="td2">Jul 25</td>
                                                        <td className="td2">Aug 22</td>
                                                        <td className="td2">Sep 19</td>
                                                        <td className="td2">Oct 17</td>
                                                        <td className="td2">Nov 14</td>  
                                                      </tr>
                                                      </tbody>
                                                      </table>
                                                  </td>
                                              </tr>
                                              </tbody>
                                            </table>
                                        </div>
                                      </div>
                                      <div className="strip-courses">
                                        <div className="title-course">
                                          <h3>Evenings (5 weeks)</h3>
                                           <ul>
                                              <li><Icon type="calendar" theme="filled" />{" "}Mon/ Wed/ Thu 6:30pm- 10:30pm – No Weekends</li>
                                           </ul>
                                        </div>
                                        <div className="description">
                                          <div className="subti4">63 Hour Real Estate Pre-License Course</div>
                                          <table className="tbl1">
                                            <tbody>
                                            <tr>
                                              <td className="cellpas">
                                                <div className="tido">Doral | North Dade | Broward | Boca Raton | Lantana</div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="cellpas">
                                                <table className="tbl2">
                                                  <tbody>
                                                  <tr>
                                                    <td className="td2">Jul 25</td>
                                                    <td className="td2">Oct 17</td>  
                                                  </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            </tbody>
                                          </table>
                                        </div> 
                                      </div>
                                      <br/>
                                  </div>

                                  <div className="field-items show1" style={{'display': this.state.show1}}>
                                        <div className="field-item even"><h2>Real Estate Classes</h2>
                                          <p>One World International selected 2 options <a href="https://goldcoastschools.com/courses/real-estate/sales-associate-pre-license"><b>Gold Coast Schools </b></a> and <a href="http://www.miamire.com/education"><b>Miami Association of Realtors </b></a>as  provider of real estate education for all future real estate agents. </p>
                                          <p>63 Hour Real Estate Pre-License Course (FREC 63) is a requirement to become a licensed real estate agent.<br/><br/>
                                          <strong><span className="cturqueza">Real School Benefits:  </span></strong></p>
                                          <ul>
                                            <li><Icon type="check-circle" theme="outlined"/> Flexibility of classes: offers  classroom and online courses.</li>
                                            <li><Icon type="check-circle" theme="outlined"/> Classes available in Spanish for Sales  Associate Pre-License program. </li>
                                            <li><Icon type="check-circle" theme="outlined"/> Cram course (2½ day comprehensive review) is included in your tuition (sales and  broker classes). </li>
                                            <li><Icon type="check-circle" theme="outlined"/> Unlimited review of you classroom course for 2 years. </li>
                                            <li><Icon type="check-circle" theme="outlined"/> Online students have 1 year to complete their  online course. </li>
                                            <li><Icon type="check-circle" theme="outlined"/> Retake classes within 2 years <b style={{color: 'red'}} >FREE</b> </li>
                                            <li><Icon type="check-circle" theme="outlined"/> Gold Coast exclusive copyrighted materials. </li>
                                            <li><Icon type="check-circle" theme="outlined"/> New technology available for students: LCD projector, computer, Power Point  Presentations. </li>
                                            <li><Icon type="check-circle" theme="outlined"/> Phone and email support for students questions. </li>
                                          </ul>
                                          <br/>
                                          <p><strong className="cturqueza">Discount Code from One World International</strong></p>
                                          <p>
                                          By getting your real estate from one of the schools you are getting a quality and in-depth knowledge about real  estate. The quality of education will ultimately drive your careers success.<br/><br/>
                                          The Sales Associate class is also  offered in an online learning format. The online version of this course is  supported by a fulltime real estate instructor available to answer any  questions that you may have.
                                          </p>
                                          <p>
                                          Register to receive more information about classes and benefits or call to (305) 279-1073 and email to <span className="cturqueza">info@gooneworld.com</span>
                                          </p>
                                          <br/>

                                          {/*
                                          <p style={{color:'#3fc1c0',textAlign: 'center'}}>
                                          For the 63 Hour Real Estate Pre-License Course
                                          </p>
                                          <div className="conte_form">
                                            <div style={{textAlign:'center',color:'orange',paddingTop:'30px',paddingBottom: '20px'}}>
                                              Enter your information to receive a <br/>Discount Code from One World International
                                            </div>
                                            <form id="frm_contact_2" name="frm_contact_2" method="post">
                                              
                                              <div style={{maxWidth: '610px',margin:'auto'}}>
                                                <div className="coninp mr20">
                                                  <label>First Name</label>
                                                  <input type="text" name="firstname"/>
                                                </div>
                                                <div className="coninp">
                                                  <label>Last Name</label>
                                                  <input type="text" name="lastname"/>
                                                </div>
                                              </div>

                                              <div style={{maxWidth: '610px',margin:'auto'}}>
                                                <div className="coninp mr20">
                                                  <label>Phone</label>
                                                  <input type="text" name="phone_contact"/>
                                                </div>
                                                <div className="coninp">
                                                  <label>Email</label>
                                                  <input type="text" name="email_contact"/>
                                                </div>
                                              </div>

                                              <div style={{maxWidth: '610px',margin:'auto'}}>
                                                <div className="coninp mr20">
                                                  <label>Confirm Email</label>
                                                  <input type="text" name="confirm_email"/>
                                                </div>
                                                <div className="coninp">
                                                  <label>Best Time Contact</label>
                                                  <input type="text" name="best_time"/>
                                                </div>
                                              </div>
                                              <div style={{maxWidth: '610px','margin':'auto'}}>
                                                <label>Message</label>
                                                <textarea name="message"></textarea>
                                              </div>
                                              <div>
                                                <div className="msg-response-contact-2"></div>
                                              </div>
                                              <div style={{textAlign: 'center','marginTop': '20px'}}>
                                                <input type="button" id="send_message_2" className="btn btn-warning" value="Send Message"/>
                                              </div>
                                            </form>
                                          </div>
                                        */}
                                          <br/><br/>
                                        </div>
                                  </div>

                                  <div className="field-items show2" style={{display: this.state.show2}}>
                                      <div className="field-item even"><h2>Register Now</h2>
                                        <p>Applying for the 63 Hour Real Estate Pre-License Course is easy. Follow this three step process to get your Real Estate Sales Associate License</p>
                                        <p><strong className="dorange">Step 1:</strong> Submit a state  application along with fingerprints. The <a href="http://www.myfloridalicense.com/dbpr/re/documents/DBPR_RE_1_Sales_Associate_Application.pdf">application </a>can be found at the Department of Business and Professional Regulation's Application Center.</p>
                                        <p>Get your <strong className="dorange">fingerprints</strong> done at one of the schools. No appointment is needed.</p><br />
                                        <p><strong className="dorange">Step 2:</strong> Take and pass the <strong>required 63 hour course (FREK 63)</strong> from Gold Coast Schools.</p>
                                        <p><strong className="dorange">Step 3:</strong> Upon approval of the application, <a href="http://www.pearsonvue.com/fl/realestate/">Pearson Vue</a> will send an approval letter to the applicant.<br />Next step is to take <strong>State Real Estate Exam.</strong></p>
                                        {/*
                                        <p style={{color:'#3fc1c0', textAlign: 'center'}}>For the 63 Hour Real Estate Pre-License Course</p>
                                        <div className="conte_form">
                                              <div style={{textAlign:'center',color:'orange',paddingTop:'30px',paddingBottom: '20px'}}>
                                                Enter your information to receive a <br/>Discount Code from One World International
                                              </div>
                                              <form id="frm_contact" name="frm_contact" method="post">
                                                
                                                <div style={{maxWidth: '610px','margin':'auto'}}>
                                                  <div className="coninp mr20">
                                                    <label>First Name</label>
                                                    <input type="text" name="firstname"/>
                                                  </div>
                                                  <div className="coninp">
                                                    <label>Last Name</label>
                                                    <input type="text" name="lastname"/>
                                                  </div>
                                                </div>

                                                <div style={{maxWidth: '610px','margin':'auto'}}>
                                                  <div className="coninp mr20">
                                                    <label>Phone</label>
                                                    <input type="text" name="phone_contact"/>
                                                  </div>
                                                  <div className="coninp">
                                                    <label>Email</label>
                                                    <input type="text" name="email_contact"/>
                                                  </div>
                                                </div>

                                                <div style={{maxWidth: '610px','margin':'auto'}}>
                                                  <div className="coninp mr20">
                                                    <label>Confirm Email</label>
                                                    <input type="text" name="confirm_email"/>
                                                  </div>
                                                  <div className="coninp">
                                                    <label>Best Time Contact</label>
                                                    <input type="text" name="best_time"/>
                                                  </div>
                                                </div>
                                                <div style={{maxWidth: '610px','margin':'auto'}}>
                                                  <label>Message</label>
                                                  <textarea name="message"></textarea>
                                                </div>
                                                <div>
                                                  <div className="msg-response-contact"></div>
                                                </div>
                                                <div style={{'textAlign': 'center','marginTop': '20px'}}>
                                                  <input type="button" id="send_message" className="btn btn-warning" value="Send Message"/>
                                                </div>
                                              </form>
                                        </div>
                                        <br/>
                                      */}
                                        <div style={{textAlign:'center'}}>
                                          <table className="tblclases">
                                            <tbody>
                                            <tr>
                                              <th colspan="6" className="subti_box">63- Hour Real Estate Sales Associate Pre-License Course Schedule</th>
                                            </tr>
                                            <tr>
                                              <th colspan="6">Days (1 week) | Mon-Sun 9:00am- 6:00pm</th></tr>
                                            <tr>
                                              <th colspan="6">Doral | North Dade | Broward | Boca Raton | Lantana</th>
                                            </tr>
                                            <tr>
                                              <td>Jan 4</td>
                                              <td>Feb 29</td>
                                              <td>Apr 25</td>
                                              <td>Jun 20</td>
                                              <td>Aug 22</td>
                                              <td>Oct 24</td>
                                            </tr>
                                            <tr>
                                              <td>Jan 18</td>
                                              <td>Mar 14</td>
                                              <td>May 9</td>
                                              <td>Jul 11</td>
                                              <td>Sep 12</td>
                                              <td>Nov 7</td>
                                            </tr>
                                            <tr>
                                              <td>Feb 1</td>
                                              <td>Mar 28</td>
                                              <td>May 23</td>
                                              <td>Jul 25</td>
                                              <td>Sep 26</td>
                                              <td>Nov 28</td>
                                            </tr>
                                            <tr>
                                              <td>Feb 15</td>
                                              <td>Apr 11</td>
                                              <td>Jun 6</td>
                                              <td>Aug 8</td>
                                              <td>Oct 10</td>
                                              <td>Dec 12</td>
                                            </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                        <div style={{textAlign:'center'}}>
                                          <table className="tblclases">
                                            <tbody>
                                            <tr>
                                              <th colspan="6">Mornings (4 weeks) | Mon/    Wed/ Fri 9:00am-1:00pm – Plus Last Weekend</th>
                                            </tr>
                                            <tr>
                                              <th colspan="6">Doral | North Dade | Broward | Boca Raton | Lantana</th>
                                            </tr>
                                            <tr>
                                              <td>Jan 4</td>
                                              <td>Mar 7</td>
                                              <td>May 2</td>
                                              <td>Jun 27</td>
                                              <td>Aug 22</td>
                                              <td>Oct 17</td>
                                            </tr>
                                            <tr>
                                              <td>Feb 1</td>
                                              <td>Apr 4</td>
                                              <td>Jun 1</td>
                                              <td>Jul 25</td>
                                              <td>Sep 19</td>
                                              <td>Nov 14</td>
                                            </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                        <div style={{textAlign:'center'}}>
                                          <table className="tblclases">
                                            <tbody>
                                            <tr>
                                              <th colspan="6">Evenings (4 weeks) | Mon/    Wed/ Fri 6:30pm- 10:30pm – Plus Last Weekend</th>
                                            </tr>
                                            <tr>
                                              <th colspan="6">Doral | North Dade | Broward | Boca Raton | Lantana</th>
                                            </tr>
                                            <tr>
                                              <td>Jan 4</td>
                                              <td>Mar 7</td>
                                              <td>May 2</td>
                                              <td>Jun 27</td>
                                              <td>Aug 22</td>
                                              <td>Oct 17</td>
                                            </tr>
                                            <tr>
                                              <td>Feb 1</td>
                                              <td>Apr 4</td>
                                              <td>Jun 1</td>
                                              <td>Jul 25</td>
                                              <td>Sep 19</td>
                                              <td>Nov 14</td>
                                            </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                        <div style={{textAlign:'center'}}>
                                          <table className="tblclases">
                                            <tbody>
                                            <tr>
                                              <th colspan="2">Evenings (5 weeks) | Mon/   Wed/Thu 6:30pm- 10:30pm – No Weekends</th>
                                            </tr>
                                            <tr>
                                              <th colspan="2">JUPITER</th>
                                            </tr>
                                            <tr>
                                              <td style={{width:'50%'}}>Feb 1</td>
                                              <td style={{width:'50%'}}>Jul 25</td>
                                            </tr>
                                            <tr>
                                              <td>May 2</td>
                                              <td>Oct 17</td>
                                            </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                        <br/>
                                        <p>Choose the best course that fits your schedule:</p>
                                        <ul>
                                          <li><Icon type="check-circle" theme="outlined"/> <strong className="dorange">7-day class</strong> runs Monday through  Sunday from 9:00a-6:00p. You can complete this course in one week, take State  Real Estate Exam and start your career as Real Estate Sales Associate. </li>
                                          <li><Icon type="check-circle" theme="outlined"/> The <strong className="cturqueza">4 week evening class</strong> runs Monday, Wednesday and Friday evenings from  6:30p-10:30p, for 4 weeks. </li>
                                          <li><Icon type="check-circle" theme="outlined"/> The <strong className="cturqueza">4 week morning class</strong> runs Monday, Wednesday and Friday mornings from  9:00a-1:00p, for 4 weeks. The class also meets the last weekend, Saturday and  Sunday from 9:00a-6:00p. </li>
                                          <li><Icon type="check-circle" theme="outlined"/> The <strong className="cturqueza">5 week evening class</strong> runs Monday, Wednesday and Thursday evenings from  6:30p-10:30p, for 5 weeks. This format is only offered in Jupiter. This class  does not meet on a weekend. </li>
                                        </ul>
                                        <strong className="dorange">Spanish Courses</strong>
                                        <br/>
                                        <ul>
                                          <li><Icon type="check-circle" theme="outlined"/> Spanish  Day Course – 1 Semana, Lun – Dom, 9:00a – 6:00p en Doral y Broward y Lantana. </li>
                                          <li><Icon type="check-circle" theme="outlined"/> Spanish  Evening Course – 4 Semenas, Lun / Mier / Vier, 5:45p – 11:00p en Doral, North  Dade, Broward </li>
                                        </ul>
                                        <br/>
                                        <p>
                                          Register to receive more information about classes and benefits or call to (305) 279-1073 and email to <span className="dorange">info@gooneworld.com</span>
                                        </p>
                                  </div>
                                  </div>

                                  <div class="field-items show3" style={{display: this.state.show3}}>
                                    <div class="field-item even"><h2>Classes Information</h2>
                                    <p>Contact One World  International with questions on how to become a license real estate agent in  the state of Florida. We can recommend you real estate class and provide  discount code to register.</p>
                                    <p><strong class="cturqueza">Application Requirements</strong></p>
                                    <ul>
                                      <li><Icon type="check-circle" theme="outlined"/> Be at least of 18 years of age</li>
                                      <li><Icon type="check-circle" theme="outlined"/> Have a valid Social Security number</li>
                                      <li><Icon type="check-circle" theme="outlined"/> Have a high school diploma or equivalency</li>
                                      <li><Icon type="check-circle" theme="outlined"/> Be able to answer questions regarding any criminal background.</li>
                                    </ul>
                                    <br/>
                                    <p class="cturqueza"><strong>Pre-Course Requeriment</strong></p>
                                    <ul>
                                      <li><Icon type="check-circle" theme="outlined"/> Successfully complete course with passing grade of a 70%</li>
                                      <li><Icon type="check-circle" theme="outlined"/> Students can only miss 8 hours of this course for any reason as per state requirements (Missed classes must be made up within 30 days of the scheduled course completion)</li>
                                      <li><Icon type="check-circle" theme="outlined"/> To get a Real Estate License, applicant must score <strong>above 75%</strong>.</li>
                                      <li><Icon type="check-circle" theme="outlined"/> If you do not get required score, you can continue reviewing the pre-course at Gold Cost School for 2 years <strong>free of charge</strong> and retake State Real Estate Exam. </li>
                                      <li><Icon type="check-circle" theme="outlined"/> If you took real estate class and it has been over 2 years, you will have to retake the course, following by Real Estate State Exam</li>
                                      <li><Icon type="check-circle" theme="outlined"/> After becoming a licensed real estate sales associate in Florida, you will have the opportunity to join our successful One World International Team.</li>
                                      <li><Icon type="check-circle" theme="outlined"/> We are proud to provide our real estate agents with sales tools, including CRM, social media training, marketing advice and many more.</li>
                                    </ul>
                                    <br/>
                                    <p><strong class="cturqueza">Out of State Licensees</strong>
                                      <br/><br/>
                                      Florida has mutual recognition agreements with other states. If you are a licensed real estate agent in sates  of AL, AR, CT, GA, IL, MS, NE, and OK you may be eligible to receive a license through mutual recognition. Check the <a href="http://www.myfloridalicense.com/dbpr/re/index.html">Division of Real Estate website </a> for more information.
                                    </p>
                                    <p>
                                      <a style={{color:'#3fc1c0',fontWeight: 'bold'}} href="http://www.miamire.com/education">Miami Association of Realtors Location</a>
                                      <br/><br/>
                                      <strong class="cturqueza">Coral Gables Office</strong><br/>
                                      245 Alcazar Avenue<br />
                                      Coral Gables FL 33134
                                      </p>
                                    
                                    <p><strong class="cturqueza">Gold  Coast School Locations</strong><br /><br />
                                      <strong class="cturqueza">North  Miami</strong><br />
                                      3577 N.E. 163rd Street<br />
                                      North Miami, FL 33160<br /><br />
                                      <strong class="cturqueza">South  Dade (Doral)</strong>
                                      11250 N.W. 20th Street <br />
                                      Miami, FL 33172<br /><br />
                                      <strong class="cturqueza">Boca  Raton</strong><br />
                                      7035 Beracasa Way Suite 206<br />
                                      Boca Raton, FL 33433<br /><br />
                                      <strong class="cturqueza">West  Palm Beach (Lantana)</strong><br />
                                      6216 S. Congress Avenue<br />
                                      Lantana, FL 33462<br /><br />
                                      <strong class="cturqueza">Tamarac  (Broward)</strong><br />
                                      5600 Hiatus Road<br />
                                      Tamarac, FL 33321 <br /><br />
                                      </p>
                                      <br/><br/>
                                    </div>
                                  </div>


                                </div>
                              </div>
                            </div>

                          </div>
                      </div>
                    </div>

                    </div>
                  </section>

                </div>
              </div>


            </Layout>
        </div>
      )
  }

}



/*LayoutRealEstateClasses.propTypes = {
  children: PropTypes.node.isRequired
};*/

export default RealEstateClasses;
