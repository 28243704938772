/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";

import Header from "../components/header";
import { Layout, Row, Col } from "antd";
import "./layouthome.css";
import Footer from "../components/com/footer/Footer";
const { Content } = Layout;
const LayoutHome = ({ children }) => (
  <div
    className="home-container"
    style={{
      height: "100vh"
    }}
  >
    <Header siteTitle={"GooNew"} />

    <Layout>
      <Content>
        <Row type="flex" justify="center">
          <Col span={24}>
            <div className="home" type="flex">
              <main>{children}</main>
            </div>
          </Col>
        </Row>
      </Content>
      <Footer />
    </Layout>
  </div>
);

LayoutHome.propTypes = {
  children: PropTypes.node.isRequired
};

export default LayoutHome;
