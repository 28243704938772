import styled from "styled-components";
export const Wrapper = styled.div`
    & .container-section{
        padding-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    /* & .ant-btn-link{
        padding-top: 10px;
        display: flex;
        justify-content: end;
        text-align: center;
        width: 100%;
    } */
`;