/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";

import Header from "../../components/header_oneestimate";
import { Layout, Row, Col } from "antd";
import "./oneestimate.css";

const { Content } = Layout;


const LayoutOneEstimate = ({ children }) => (
  <div className="oneestimate">
    <Header siteTitle={"GooNew"} />

    <footer>
	    <div className="container">
	        
	        <div className="sitem">
	            <div className="conul"></div>
	            <div className="conul"></div>
	        </div>


	        <div className="row about">
	          <div className="span3">
	            <h3>About Us</h3> 
	            <span className="condet">One World International Company was founded in 2008 with headquarters in Miami, Florida. Our mission is to provide real estate agents with the technology and tools that can increase their productivity and stay connected with the customers. </span>
	          </div>
	        </div>
	    </div>

	    <div className="subfooter">
	        <div className="container">
	            <div className="row">
	                <div className="subfoo">
	                    Copyiright 2019 - One World Int'l Real Estate
	                </div>

	                <div className="subfoo">
	                    <div className="social-icons">
	                        <span itemscope itemtype="http://schema.org/Organization">
	                            <a itemprop="sameAs" href="https://www.facebook.com/gooneworld"><span className="sprite sprite-facebook" data-toggle="tooltip" title="" data-original-title=".sprite.sprite-facebook"></span></a>
	                            <a itemprop="sameAs" href="https://twitter.com/gooneworld"><span className="sprite sprite-twitter" data-toggle="tooltip" title="" data-original-title=".sprite.sprite-twitter"></span></a>
	                            <a itemprop="sameAs" href="https://plus.google.com/+OneWorldInternationalRealEstateMiami"><span className="sprite sprite-google_" data-toggle="tooltip" title="" data-original-title=".sprite.sprite-google_"></span></a>
	                            <a itemprop="sameAs" href="https://www.linkedin.com/company/one-world-international-real-estate"><span className="sprite sprite-linkedin" data-toggle="tooltip" title="" data-original-title=".sprite.sprite-linkedin"></span></a>
	                        </span>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </div>

	</footer>
  </div>
);

LayoutOneEstimate.propTypes = {
  children: PropTypes.node.isRequired
};

export default LayoutOneEstimate;
