import React, { useState, useEffect } from "react";
import axios from "axios";

const URL_BASE = "http://transit.walkscore.com";
const URL_TRANSIT = "/transit/score/";
const URL_WALK_SCORE = "/score/";
const KEY_API = "9a01b5374639d6da62556ea007cf5247";

const params = {
  wsapikey: KEY_API,
  lat: 26.2770356,
  lon: -80.12894469999999,
  city: "Deerfield Beach",
  state: "FL"
};
const Widget = props => {
  axios.defaults.withCredentials = true;
    axios.defaults.headers["wsapikey"] = KEY_API;
  axios.defaults.headers["Access-Control-Allow-Origin"] = "*";

  const getDataTransit = params => {
    return axios.get(`${URL_BASE}${URL_TRANSIT}`, { params });
  };
  const getWalkScore = params =>
    axios.get(`${URL_BASE}${URL_WALK_SCORE}`, { params });

  useEffect(() => {
    getDataTransit(params)
      .then(response => {
        let { data } = response;
        alert(JSON.stringify(data));
      })
      .catch(err => console.log(err.message));
    return () => {
      console.log("unMount");
    };
  }, []);
  return <div></div>;
};

export default Widget;
