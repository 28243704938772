import React, { useState } from "react";
import { Card, List, Icon, message, Button, Modal } from "antd";
import ListCard from "../../components/listings/ListingCard";
import Helper from "../../utils/Helper";
import styled from "styled-components";
import { getService } from "../../services/services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { Link } from "react-router-dom";
import Constants from "../../utils/constants";
const { Meta } = Card;
const { money } = Helper;

const Container = styled(Card)`
  border-radius: 10px !important;
  box-shadow: rgba(69, 90, 100, 0.3) 0px 2px 10px -1px;

  height: 330px;

  & .ant-card-cover {
    height: 200px;
  }
  & .ant-card-cover img {
    height: 100% !important;
  }
  & .ant-card-meta-title h2 {
    margin-bottom: 0px !important;
    font-size: var(--font-size-large) !important;
    font-weight: bold;
  }
  & .ant-card-cover:hover .view-detail {
    display: block !important;
  }
  & .ant-card-body {
    padding: 8px !important;
  }
  & .ant-card-meta-title svg {
    color: #fafafa !important;
  }
  & .ant-card-meta-title small {
    background-color: #ececec;
    border-radius: 50px;
    padding: 5px 0px;
  }
  & .icon-fa {
    padding: 4px !important;
    border-radius: 50% !important;
    height: 30px !important;
    width: 30px !important;
    vertical-align: middle !important;
  }
  & .icon-orange {
    background: var(--orange) !important;
  }
  & .icon-yellow {
    background: var(--yellow) !important;
  }
  & .ant-card-meta-title span {
    color: #444 !important;
    font-size: 14px !important;
    padding: 0 !important;
    margin: 0 6px !important;
  }
  & .address {
    font-size: 14px;
    color: var(--primary);
    white-space: nowrap;
    text-overflow: ellipsis !important;
    max-width: 100% !important;
    display: block;
    overflow: hidden;
  }
`;

const Detail = styled.div`
  display: none !important;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  max-height: 200px;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
`;
const Favorites = () => {
  const [refresh, setRefresh] = useState();
  const refinement = (it) => {
    it = {
      ...it,
      ...(it.listing && JSON.parse(it.listing)),
    };
    delete it.listing;
    return it;
  };
  const removeItem = (id) => {
    getService("properties-favorites")
      .remove(id)
      .then(({ data }) => {
        setRefresh(true);
        message.info("Element removed!");
      })
      .catch((err) => console.log(err));
  };
  const handleDelete = (id) => {
    Modal.confirm({
      title: "Confirm",
      icon: <Icon type="exclamation-circle" />,
      content: "Do you want to delete the record?",
      okText: "Ok",
      cancelText: "Cancel",
      onOk: () => removeItem(id),
    });
  };
  const renderItem = (item, index) => {
    return (
      <List.Item>
        <Container
          hoverable
          cover={
            <>
              <img
                alt="example"
                src={`${Constants.URL_API_PHOTOS}${item.ListingKeyNumeric}/0.jpg?width=400&height=400`}
              />
              <Detail className="view-detail">
                <div
                  span={24}
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button size="large" type="primary">
                    <Link
                      to={`/listings/details/${item.City}/${item.StateOrProvince}/${item.Slug}`}
                      target="_blank"
                    >
                      {" "}
                      VIEW DETAIL
                    </Link>
                  </Button>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                  }}
                >
                  <Button
                    type="danger"
                    shape="circle"
                    icon="dislike"
                    onClick={() => handleDelete(item._id)}
                  />
                </div>
              </Detail>
            </>
          }
        >
          <Meta
            title={
              <>
                {item.ListPrice && <h2>{money(item.ListPrice)}</h2>}
                {typeof item.BedroomsTotal !== "undefined" &&
                item.BedroomsTotal !== 0 &&
                item.BedroomsTotal !== null ? (
                  <small>
                    <FontAwesomeIcon
                      className="icon-fa icon-orange"
                      size="lg"
                      icon={["fas", "bed"]}
                    />{" "}
                    <span>
                      {item.BedroomsTotal}{" "}
                      {item.BedroomsTotal > 1 ? "bd" : "bd"}
                    </span>
                  </small>
                ) : null}{" "}
                {item.BathroomsFull && (
                  <small
                    className={
                      typeof item.BedroomsTotal !== "undefined" &&
                      item.BedroomsTotal !== 0
                        ? "item-property divider-left"
                        : "item-property"
                    }
                  >
                    <FontAwesomeIcon
                      className="icon-fa icon-yellow"
                      size="lg"
                      icon={["fas", "bath"]}
                    />{" "}
                    <span>
                      {item.BathroomsFull}{" "}
                      {item.BathroomsFull > 1 ? "ba" : "ba"}
                    </span>
                  </small>
                )}
                {item.LivingArea && (
                  <small
                    className={
                      item.BedroomsTotal
                        ? "item-property divider-left"
                        : "item-property"
                    }
                  >
                    <FontAwesomeIcon
                      className="icon-fa"
                      size="lg"
                      icon={["fas", "ruler"]}
                    />{" "}
                    <span>{item.LivingArea}</span>
                  </small>
                )}
              </>
            }
            description={
              <>
                <span className="address">{item.address}</span>
                <small
                  style={{
                    width: "100%",
                    display: "block",
                  }}
                >
                  {moment(item.createdAt).format("YYYY-MM-DD H:mm:ss")}
                </small>
              </>
            }
          />
        </Container>
      </List.Item>
    );
  };
  return (
    <div>
      <ListCard
        switchLayout={false}
        column={4}
        refresh={refresh}
        onReload={() => {
          setRefresh(false);
        }}
        renderItem={renderItem}
        source={"properties-favorites"}
        refinement={refinement}
      />
    </div>
  );
};
export default Favorites;
