import styled from "styled-components";

export const Wrapper = styled.div`
  min-height: 18vh;
  & .listign-title > h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.2;
    color: var(--color-title-dark2);
    margin-bottom: 0 !important;
  }
  & .listign-title > span {
    font-size: 16px;
    color: #869099;
    font-weight: 400;
    display: flex;
    margin: 0;
  }
  & .ant-affix {
    width: 100% !important;
  }
  & .map-container {
    margin: 0px;
    padding: 0px;
    background-color: #e8e9ea;
    height: 100vh;
    position: relative;
    /* position: fixed;
    top: 64px;
    height: 100vh;
    width: 100%;
    overflow: hidden; */
  }
  & #map {
    margin: 0px;
    padding: 0px;
    background-color: #e8e9ea;
    height: 100vh;
    min-width:700px;
  }
`;
export const ListingListWrapper = styled.div`
  min-height: 18vh;
  margin: 10px;
  & .listing-title {
    padding: 8px 0px;
    margin: 0px;
    border-bottom: 1px solid #e8e8e8;
  }
  & .listing-title h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.2;
    color: var(--color-title-dark2);
    margin-bottom: 0px !important;
  }
  & .listing-title > span {
    font-size: 16px;
    color: #869099;
    font-weight: 400;
    display: flex;
    margin: 0;
  }
  & .ant-pagination {
    background: #fff;
    border-radius: 25px;
    display: flex;
    align-items: start;
    justify-content: center;
    height: 30px;
    padding: 0px;
  }
  & .container-section-dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
