import React, { Component } from "react";
import { Button, Icon, Input, AutoComplete, message } from "antd";
import debounce from "lodash/debounce";
import { withRouter } from "react-router-dom";
import {
  search_autocomplete,
  get_listings_by_address_mls
} from "../../../services";
import axios from "axios";

import "./searchfieldcategory.css";
import { Constants } from "../../../utils/index";

const Option = AutoComplete.Option;
const OptGroup = AutoComplete.OptGroup;

class searchFieldCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      loading: false,
      open: false,
      value: null,
      option: null
    };
    /*  this.query = debounce(this.query, 300); */
  }

  handleChange = value => {
    this.setState({
      value
    });
    if (this.props.onChange && value) {
      this.props.onChange(value);
    }
  };
  handleSearch = (value = "") => {
    if (value.length == 0)
      return this.setState({
        open: false,
        loading: false
      });
    if (value.length >= 3) {
      this.setState({ loading: true });
      return this.query(value, options =>
        this.setState({ options, open: true, loading: false })
      );
    }
    this.setState({ open: false, loading: false });
  };
  handleSelect = (value, option) => {
    this.setState({
      value,
      open: false,
      option
    });

    if (this.props.onSelect) {
      this.props.onSelect(value, option);
    }
  };
  handleOpen = () => {
    this.setState({ open: !this.state.open });
    let { value, option, options } = this.state;
    if (options.length > 0) {
      return this.setState({
        open: true
      });
    }
    if (value) {
      this.setState({ loading: true });

      return this.search_address(value, options =>
        this.setState({ options, open: true, loading: false })
      );
    }
    this.setState({ loading: false });

    /* 
    ESTO PUEDE QUE VAYA
    try {
      if (!option) {
        if (value) {
          var values = value[0].split(",");
          option = {
            props: {
              record: {
                City: values[0],
                State: values[1].replace(/ /g, "")
              }
            }
          };
        }
        this.setState({
          value,
          option
        });
      }
    } catch (err) {
      console.log("ERROR: ", err);
    }
    if (typeof this.props.onChange !== "undefined") {
      if (typeof value === "object") {
        value = value[0];
      }
      this.props.onChange(value, option);
    }
    if (typeof this.props.onOK !== "undefined") {
      if (typeof value === "object") {
        value = value[0];
      }
      this.props.onOK(value, option);
    } */
  };

  search_address = (value, callback) => {
    let params = {
      query: { value }
    };

    get_listings_by_address_mls(params)
      .then(data => {
        if (Array.isArray(data))
          if (data)
            data = data
              .filter(item => item.children.length > 0)
              .map(group => (
                <OptGroup key={group.title} label={group.title}>
                  {group.children.map(opt => (
                    <Option
                      key={opt.title}
                      value={opt.title}
                      record={{ ...opt }}
                      category={group.title}
                    >
                      {opt.title.replace(/undefined/g, "")}
                      <span className="certain-search-item-route">
                        {group.title.replace(/undefined/g, "")}
                      </span>
                    </Option>
                  ))}
                </OptGroup>
              ));

        if (typeof callback !== "undefined") {
          if (Array.isArray(data)) callback(data);
        }
      })
      .catch(err => message.error(err.message));
  };
  query = (value, callback) => {
    let { url } = this.props;
    let params = {
      query: { value }
    };

    if (typeof url === "undefined") {
      url = `${Constants.URL_API_COMPROPERTEE}${Constants.URL_AUTOCOMPLETE}`;
    }

    return search_autocomplete(params)
      .then(data => {
        if (Array.isArray(data))
          data = data
            .filter(item => item.children.length > 0)
            .map(group => (
              <OptGroup key={group.title} label={group.title}>
                {group.children.map(opt => (
                  <Option
                    key={opt.title}
                    value={opt.title}
                    record={{ ...opt }}
                    category={group.title}
                  >
                    {opt.title.replace(/undefined/g, "")}
                    <span className="certain-search-item-route">
                      {group.title.replace(/undefined/g, "")}
                    </span>
                  </Option>
                ))}
              </OptGroup>
            ));

        if (typeof callback !== "undefined") {
          if (Array.isArray(data)) callback(data);
        }
      })
      .catch(err => message.error(err.message));

    return axios
      .get(url, { params })
      .then(res => {
        let options = res.data
          .filter(item => item.children.length > 0)
          .map(group => (
            <OptGroup key={group.title} label={group.title}>
              {group.children.map(opt => (
                <Option
                  key={opt.title}
                  value={opt.title}
                  record={{ ...opt }}
                  category={group.title}
                >
                  {opt.title.replace(/undefined/g, "")}
                  <span className="certain-search-item-route">
                    {group.title.replace(/undefined/g, "")}
                  </span>
                </Option>
              ))}
            </OptGroup>
          ));

        if (typeof callback !== "undefined") {
          callback(options);
        }
      })
      .catch(err => console.log(err));
  };
  componentDidMount() {
    let { defaultValue } = this.props;
    this.setState({
      value: defaultValue
    });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.history)
      this.setState({
        history: nextProps.history
      });
  }
  render() {
    let { placeholder, size, className, style } = this.props;
    let { options, value, open, loading } = this.state;

    return (
      <div
        className="certain-category-search-wrapper"
        style={style || { width: 200 }}
      >
        <AutoComplete
          className={className || "certain-category-search"}
          dropdownClassName="certain-category-search-dropdown"
          dropdownMatchSelectWidth={false}
          dropdownStyle={{ width: 300 }}
          value={value}
          defaultValue={this.props.defaultValue}
          onChange={this.handleChange}
          onSearch={this.handleSearch}
          onSelect={this.handleSelect}
          size={size || "large"}
          style={{ width: "100%" }}
          dataSource={options}
          open={open}
          placeholder={placeholder || "input here"}
          optionLabelProp={this.props.optionLabelProp || "value"}
        >
          <Input
            suffix={
              <Button
                className="search-btn btn-search-listing"
                size="large"
                type="primary"
                onClick={this.handleOpen}
              >
                <Icon type={loading ? "loading" : "search"} />
              </Button>
            }
          />
        </AutoComplete>
      </div>
    );
  }
}
export default withRouter(searchFieldCategory);
