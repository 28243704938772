import React, { Component } from "react";
import ChangePasswordForm from "../../../components/com/authentication/changepassword/ChangePasswordForm";
import { message } from "antd";
import { login } from "../../../services";

import { authentication } from "../../../services/services";
import "./changepassword.css";

import logo from "../../../images/bruno-square.jpg";

class ChangePassword extends Component {
  onSubmit = (err, params) => {
    let { email } = params;
    let { history } = this.props;
    if (err) {
      return message.error(err.message);
    }

    return authentication(params).then(({ user, accessToken }) => {
      let { email } = user;
      let username = email.substr(0, email.indexOf("@"));
      localStorage.setItem("userId", user.id);
      localStorage.setItem("access_token", accessToken);
      localStorage.setItem("username", username);

      /* Go to previous route */
      let { pathname, search } = window.previousLocation || {
        pathname: "/"
      };
      history.push({
        pathname,
        search
      });
    });
    login(params)
      .then(response => {
        let { data } = response;
        let username = email.substr(0, email.indexOf("@"));
        console.log("Response: ", data);
        localStorage.setItem("userId", data.userId);
        localStorage.setItem("access_token", data.id);
        localStorage.setItem("username", username);
        /* Go to previous route */
        let { pathname, search } = window.previousLocation || {
          pathname: "/"
        };
        history.push({
          pathname,
          search
        });
      })
      .catch(err => message.error(err.message));
  };
  componentDidMount() {
    let { history } = this.props;
    if (typeof localStorage.userId !== "undefined") history.push("/");
  }
  componentWillMount() {
    console.log(window.previousLocation);
  }
  render() {
    return (
      <section id="wrapper" className="login-register">
        <div className="login-box login-sidebar">
          <div className="white-box">
            <div className="conteim">
              <a href="/" className="db">
                <img src={logo} width="90" />
              </a>
            </div>
            <ChangePasswordForm
              formLayout="vertical"
              onSubmit={this.onSubmit}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default ChangePassword;
