import React from "react";
import { Icon } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./widget.css";
import { Divider } from "rc-menu";
const WidgetCard = ({ icon,title, description, valueField, ...props }) => {
  return (
    <div className="widget">
      <FontAwesomeIcon
        size={"lg"}
        className="icon-social"
        icon={["fas", icon || "map-marker-alt"]}
      />{" "}
      <span>{title}</span> <span className="value-field">{props[valueField]}</span>
      <div className="divider-container" />
      <span className="description-widget">{description}</span>
    </div>
  );
};

export default WidgetCard;
