import React, { useState, useEffect } from 'react';
import { Button, Icon, message, Select } from "antd";
import { getService } from '../../../services';
import _ from 'lodash';
const { Option } = Select;
const SelectField = ({ optionValue, onBlur, onSelect, width = 150, filterDefaultValues, resource, reference, source, record, form, optionText, placeholder, name, onChange, onSearch, ...props }) => {
    const [initialized, setInitialized] = useState(false);
    const [defaultValue, setDefaultValue] = useState(props.defaultValue);
    const [choices, setChoices] = useState(props.choices || []);
    const [loading, setLoading] = useState(false);

    const setInitialValue = () => {
        let item = choices.find(item => {
            return (source && (item[optionValue] == record[source]));
        });
        if (item) {
            setDefaultValue(item[optionValue]);
        }
    }
    const getData = async () => {
        if (resource || reference) {
            const service = getService(resource || reference);
            let token = localStorage.getItem("feathers-jwt");
            if (!token) return;
            service.find({
                query: {
                    $limit: 500000,
                    ...filterDefaultValues,
                }
            })
                .then(({ data }) => {
                    setLoading(false);
                    setChoices(data)
                    setInitialValue();
                })
                .catch(err => {
                    setLoading(false);
                    message.error(err.message);
                });
        }
    }
    useEffect(() => {
        getData();
    }, []);
    useEffect(() => {
        if (props.defaultValue && choices) {
            setDefaultValue(props.defaultValue)
        }
    }, [props.defaultValue]);
    useEffect(() => {
        if (props.initial && choices) {
            setDefaultValue(props.initial);
        }
    }, [props.initial]);
    useEffect(() => {
        setChoices(props.choices)
    }, [props.choices]);
    useEffect(() => {
        setLoading(props.loading)
    }, [props.loading]);

    const handleOnChange = (value) => {
        if (onChange)
            onChange(value)
    }
    const handleOnSelect = (value) => {
        if (onSelect)
            onSelect(value);
    }
    const handleOnClear = () => {
        setDefaultValue(null);
        if (props.onClear) props.onClear();
    }
    const handleSelect = _.debounce(handleOnSelect, 1500);
    /* if (loading) return <span>Loading...</span>; */
    return (<Select
        {...props}
        style={{ width, ...props.style }}
        initial={defaultValue}
        defaultValue={defaultValue}
        showSearch
        size={props.size || "large"}
        placeholder={placeholder || "Select"}
        optionFilterProp="children"
        onChange={handleOnChange}
        onSelect={handleSelect}
        onSearch={onSearch}
        allowClear={props.allowClear || false}
        filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        clearIcon={<span>
            {<Button
                onClick={handleOnClear}
                style={{
                    margin: 0,
                    padding: 0,
                    position: "absolute",
                    left: -10,
                    top: -4,
                }} size="small" shape="circle" icon="close" type="default" />}
        </span>
        }
        loading={loading}
        name={name || source}
    >
        {choices && choices.map((it, index) => {
            return (<Option key={index} value={it[optionValue || "id"]}>
                {it[optionText || "name"]}
            </Option>)
        })}
    </Select>)
};

export default SelectField;