import { ApiFeathers as api } from "../utils/Api";
export const reAuthenticate = api.reAuthenticate;
export const Logout = api.logout;
export const authentication = ({ strategy = "local", email, password }) => {
  return api.authenticate({
    strategy: "local",
    email,
    password
  });
};

export const search = api.service("search");
export const new_listings = api.service("new-listings");
export const new_listings_under_300 = api.service("new-listing-under-300-k");
export const new_listings_apartament_rent = api.service(
  "new-listings-apartament-rent"
);
export const property = api.service("property-search");
export const property_detail = api.service("property-detail");
export const property_history = api.service("properties-histories");
export const walkscores = api.service("walkscores");
export const transit_scores = api.service("transit-scores");
export const nearby_schools = api.service("nearby-schools");
export const listings_by_subdivisionname = api.service(
  "listings-by-subdivision-name"
);
export const findLatLngService = api.service("find-lat-lng");
export const userSaveService = api.service("user-saved-listings");
export const propertyFavoriteService = api.service("properties-favorites");
export const listingClosedService = api.service("listings-closed");
export const propertyHistoryService = api.service("properties-histories");
export const listingByAddressMls = api.service("search-listing-by-address-mls");
export const EmailService = api.service("send-message");
export const userService = api.service("users");
export const recoveryPassword = api.service("recovery-password");
export const changePassword = api.service("change-password");
export const getService = service => api.service(service);
