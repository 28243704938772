import React, { Component } from "react";
import { withRouter } from "react-router";
import { Menu, Dropdown, Button, Icon } from "antd";
import "./menulistings.css";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import Logo from "../images/logo-color.svg";
import SearchField from "../components/com/searchfield/SearchFieldCategory";
import ProfileMenu from "./ProfileMenu";
import { URL_GOONEWORLD } from "../constants";
class MenuListings extends Component {
  state = {
    visible: false,
    placement: "left",
    current: "home",
    TransactionType: "",
    address: "",
    transaction_type: window.localStorage.getItem("transaction_type") || "Sale"
  };
  handleClick = e => {
    let value = e.key;
    let { history, match, location } = this.props;
    let { search } = location;
    let {
      params: { City, StateOrProvince, transaction_type }
    } = match;

    switch (value) {
      case "login":
        return history.push("/login");
        break;
      case "signup":
        return history.push("/signup");
        break;
      case "about":
        return history.push("/about");
        break;
      case "contactus":
        return history.push("/contactus");
        break;

      case "home":
        return history.replace("/");

        break;
      default:
        if (transaction_type && value !== "cmp" && value !== "menu") {
          if (value !== transaction_type) {
            history.replace(
              `/listings/search/${City}/${StateOrProvince}/${value}`
            );
            window.location.reload();
          }
        }
        break;
    }
    if (value !== "cmp" && value !== "menu")
      this.setState({
        current: value,
        transaction_type: value
      });
  };

  showDrawer = () => {
    this.setState({
      visible: !this.state.visible
    });
    /*console.log('Clic: -- ', this.state.visible);*/
  };

  onChangeSearch = (value, option) => {
    /* console.log(">>>>option:", value, option.props) */
    /* return alert("Value:" + JSON.stringify(option.category)); */
    if (value)
      this.setState({
        address: value
      });
    this.goToSearch(value, option.props);
  };
  /* goToSearch = (value, option) => {
    let { match, history } = this.props;
    let { transaction_type } = this.state;
    let {
      props: { record }
    } = option;
    let { City, State } = record;
    let StateOrProvince = State;
    let path = `/listings/search/${City}/${StateOrProvince}/${transaction_type}`;
    return history.replace(path);
  }; */
  goToSearch = (value, option) => {
    try {
      const { history, location } = this.props;
      const { url } = this.props.match;
      let { address } = this.state;
      const { params } = this.props.match;
      const { transaction_type, TransactionType } = params;
      let {
        record, category
      } = option;
      let { City, State, Slug, Zip, ListingId, _id } = record;
      console.log(">>> CATEGORY: ", category, option);
      let pathname = ``;
      let _href = url.split("/");
      /* if (_id) {
        return window.location.href =
          `${URL_GOONEWORLD}listings/details/${City.replace(/\//g, "-")}/${State.replace(
            /\//g,
            "-"
          )}/${Slug.replace(/\//g, "-")}`;
      } */
      if (category == "City") {
        if (this.props.actions)
          this.props.actions.setSearchParam({
            address,
            limit: 30,
            City,
            State
          });
        window.location.href =
          `${URL_GOONEWORLD}listings/search/${City.replace(/\//g, "-")}/${State.replace(
            /\//g,
            "-"
          )}/${transaction_type}`;
        /*  if (address) {
           address = address.split(",");
 
           _href[3] = City || "Miami";
           _href[4] = State.replace(/ /g, "") || "FL";
 
           _href.forEach(loc => {
             if (loc !== "") {
               pathname = `${pathname}/${loc}`;
             }
           });
           if (this.props.actions)
             this.props.actions.setSearchParam({
               address,
               limit: 30,
               City,
               State
             });
           return history.replace(
             `/listings/search/${City.replace(/\//g, "-")}/${State.replace(
               /\//g,
               "-"
             )}/${transaction_type}`
           );
         } */
      }
      if (category == "Zip") {
        /*  this.props.actions.setSearchParam({
           PostalCode: Zip
         }); */
        return window.location.href =
          `${URL_GOONEWORLD}zipcode/${transaction_type || this.state.transaction_type}/${Zip.replace(/\//g, "-")}`
      }
      if (category == "Address") {
        return window.location.href =
          `${URL_GOONEWORLD}listings/details/${City.replace(/\//g, "-")}/${State.replace(
            /\//g,
            "-"
          )}/${Slug.replace(/\//g, "-")}`
      }
    } catch (err) {
      console.log("ERROR: ", err);
    }
  };
  componentDidMount() {
    let { match } = this.props;
    let { params } = match;
    this.setState({
      current: params.transaction_type || "Sale"
    });
  }
  componentWillReceiveProps(nextProps) {
    let { match } = nextProps;
    let { params } = match;
    this.setState({
      current: params.transaction_type || "Sale"
    });
  }
  render() {
    const { params } = this.props.match;
    const { City, StateOrProvince } = params;
    const address = `${City || ""}${City ? "," : ""}${StateOrProvince || ""}`;
    return (
      <div className="menu-listings">
        <div className="logo">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        <Menu
          onClick={e => this.handleClick(e)}
          selectedKeys={[this.state.current]}
          className="header-menu menu-left-list"
          mode="horizontal"
          defaultSelectedKeys={["home"]}
        >
          {this.props.search && (
            <Menu.Item className="item-menu-cmp" key="cmp">
              <SearchField
                defaultValue={[address]}
                /* size="small" */
                /* defaultValue={["Miami, FL"]} */
                placeholder="*Where do you like to live?"
                onSelect={this.onChangeSearch}
                onOK={this.onChangeSearch}
              />
            </Menu.Item>
          )}

          {this.props.search && (
            <Menu.Item className="item-menu-simple" key="Sale">
              For Sale
            </Menu.Item>
          )}
          {this.props.search && (
            <Menu.Item className="item-menu-simple" key="Lease">
              For Rent
            </Menu.Item>
          )}
          {this.props.search && (
            <Menu.Item className="item-menu-simple" key="Home">
              Home
            </Menu.Item>
          )}
        </Menu>
        <Menu
          onClick={this.handleClick}
          className="header-menu menu-right"
          mode="horizontal"
          defaultSelectedKeys={["signup"]}
        >
          {/* !isMobile && (
            <Menu.Item key="about" className="item-menu-desktop">
              About us
            </Menu.Item>
          ) */}
          {/* !isMobile && (
            <Menu.Item key="contactus" className="item-menu-desktop">
              Contacts
            </Menu.Item>
          ) */}
          {!localStorage.userId && (
            <Menu.Item key="login" className="item-menu-desktop">
              Sign In
            </Menu.Item>
          )}
          {!localStorage.userId && (
            <Menu.Item key="signup" className="item-menu-desktop">
              Sign Up
            </Menu.Item>
          )}

          {/*localStorage.userId && (
            <Menu.Item
              key="save_search"
              className="item-menu-btn item-menu-desktop"
            >
              <Button
                className="btn-menu"
                type="primary"
                ghost
                onClick={this.handleSaveSearch}
              >
                {<Icon type="bell" />} Save Search
              </Button>
            </Menu.Item>
          )*/}

          {
            <Menu.Item
              onClick={this.showDrawer}
              key="menu"
              className="item-menu-simple menu-profile-container"
            >
              <Icon type="menu" style={{ padding: 10 }} theme="outlined" />
            </Menu.Item>
          }
        </Menu>
        <ProfileMenu
          title={""}
          onVisible={this.state.visible}
          onClose={this.showDrawer}
        />
      </div>
    );
  }
}
export default withRouter(MenuListings);
