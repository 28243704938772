import React, { useEffect, useState } from "react";
import Favorites from "./Favorites";
import { Layout, Row, Col, Divider } from "antd";
import styled from "styled-components";
import Menu from "../../components/menuListings";
import ProfileForm from "../../components/com/profileform/ProfileForm";
import S3Button from "../../components/com/form/S3Button";
import { getService } from "../../services";
import { isMobile } from "react-device-detect";
const service = getService("users");

const { Content } = Layout;
const Container = styled(Row)`
  padding: 20px !important;
  @media (max-width: 480px) {
    padding: 0px !important;
    min-width: 100% !important;
    height: 100% !important;

    background: #40a9ff;

    &.user-profile .col-right {
      display: none;
    }
    & .item-form {
      width: 100% !important;
    }
    &.my-profile-mb .col-left {
      display: none;
    }
    &.my-profile-mb h2 {
      color: #fff !important;
    }

    &.my-profile-mb .login-form-button {
      background: #165bfb !important;
    }
    &.user-profile-mb .col-left > div,
    &.my-profile-mb .col-right > div {
      padding: 10px !important;
      background: #40a9ff !important;
    }
    &.my-profile-mb .col-right .ant-form {
      padding-top: 130px !important;
    }
    &.user-profile-mb {
      padding: 10px !important;
    }
    &.user-profile-mb .wrapper {
      background: red;
    }
    &.user-profile-mb .col-right {
      display: none;
    }
  }
`;
const Wrapper = styled(Layout)`
  min-height: 100vh !important;
  backfround: #f5f6f8 !important;
  @media (max-width: 480px) {
    min-width: 100% !important;
    height: 100vh !important;
  }
`;
const FormContainer = styled.div`
  background: white;
  min-width: 450px;
  height: auto;
  display: block;
  border-radius: 10px;
  border: 0px solid #d9d9d9;
  box-shadow: 5px 5px 5px rgba(117, 117, 117, 0.3);
  overflow: hidden;

  padding: 10px 20px;
  @media (max-width: 992px) {
    border-radius: 0px;
  }
  @media (max-width: 480px) {
    min-width: 100% !important;
    min-height: 100vh;
    height: auto !important;
    margin: 0px !important;
    overflow-y: auto;
  }
`;
const HeadLine = styled.div`
  color: #8f8f8f;
  & h2 {
    color: rgb(61, 81, 112);
    font-size: 1.625rem;
    margin-bottom: 0px !important;
  }
  & p {
    color: "rgba(0, 0, 0, .45)";
  }
  &.description h4 {
    font-size: 1.263rem;
  }
  & h4 {
    color: rgb(61, 81, 112);
    font-weight: 500;
    font-size: 1.563rem;
    line-height: 2rem;
    margin-bottom: 0px !important;
  }
  & h3 {
    /*  color: rgb(61, 81, 112); */
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.2rem;
    margin-bottom: 0px !important;
  }
  & span {
    font-size: 12px;
    color: #8f8f8f;
  }
  &.profile-picture-content span {
    font-size: 15px;
    font-weight: 500;
  }
  &.head-top {
    margin-bottom: 20px;
  }
`;
const Profile = ({ history, ...props }) => {
  const [user, setUser] = useState({});
  const [isMe, setIsMe] = useState(false);
  const [loading, setLaoding] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const getUserData = _id => {
    const service = getService("users");
    const user = JSON.parse(localStorage.getItem("user"));
    setLaoding(true);
    if (_id)
      service
        .find({
          query: {
            _id,
            status: "active"
          }
        })
        .then(({ data }) => {
          setLaoding(false);
          if (data.length > 0) {
            setIsMe(user ? _id === data[0]._id : false);
            setUser(data[0]);
          } else {
            history.push("/404");
          }
        })
        .catch(err => history.push("/login"));
  };
  const updateAccount = url => {
    setLaoding(true);
    if (user._id)
      service
        .patch(user._id, {
          picture: url
        })
        .then(record => {
          setLaoding(false);
          setUser(record);
        });
  };
  useEffect(() => {
    let { match } = props;
    let { _id } = match.params;
    if (_id) getUserData(_id);
    return () => {};
  }, [props.location]);
  return (
    <Wrapper className="wrapper">
      <header>
        <Menu search={false} />
      </header>
      <Container
        className={
          isMe
            ? `my-profile${isMobile ? "-mb" : ""}`
            : `user-profile${isMobile ? "-mb" : ""}`
        }
        gutter={16}
        type="flex"
        justify="center"
        align="top"
      >
        {!isMobile && (
          <Col span={24}>
            <HeadLine className="head-top">
              <span>OVERVIEW</span>
              <h2>User Profile</h2>
            </HeadLine>
          </Col>
        )}
        {
          <Col className={"col-left"} xl={8} lg={8} md={24} xs={24}>
            <Row type="flex" justify="center" align="middle">
              <Col gutter={16} type="flex" justify="center" align="center">
                <FormContainer>
                  <div style={{}}>
                    {
                      <S3Button
                        disabled={!isMe}
                        record={user}
                        value={user.picture}
                        idComponent="btn-send"
                        onChange={updateAccount}
                        path="users"
                        finalPath="picture"
                        source="image"
                        id={user._id}
                      />
                    }
                    {user.first_name && (
                      <div>
                        <HeadLine className="profile-picture-content">
                          <h4>{`${user.first_name} ${user.last_name ||
                            ""}`}</h4>
                          <h3>{user.role}</h3>
                          <div>{user.phone}</div>
                          <div>{user.email}</div>
                        </HeadLine>
                        <Divider />
                        {user.about_me && (
                          <HeadLine className="description">
                            <h4>Description</h4>
                            <p>{user.about_me}</p>
                          </HeadLine>
                        )}
                      </div>
                    )}
                  </div>
                </FormContainer>
              </Col>
            </Row>
          </Col>
        }
        {
          <Col className={"col-right"} xl={16} lg={16} md={24} xs={24}>
            <FormContainer>
              <HeadLine>
                <h2>Account Details</h2>
              </HeadLine>
              <ProfileForm record={user} isMe={isMe} />
            </FormContainer>
          </Col>
        }
      </Container>
    </Wrapper>
  );
};
export default Profile;
