import React from "react";
import Menu from "../components/menuListings";
import Footer from "../components/com/footer/Footer";
import { Layout } from "antd";
const { Content } = Layout;
const LayoutAbout = ({ children }) => (
  <div className="page-container">
    <Layout>
      <header>
        <Menu search={true} />
      </header>
      <Content>{children}</Content>
      <Footer className="footer-page" />
    </Layout>
  </div>
);
export default LayoutAbout;
