import React, { Component } from "react";
import { AdvancedForm } from "../../index";
import { Input, Icon, Button, Checkbox, Row, Col } from "antd";
import { Link } from "react-router-dom";

import "./loginform.css";
import SimpleForm from "../../form/SimpleForm";
class LoginForm extends Component {
  onChange = value => {
    //console.log("Value: ",value);
  };

  render() {
    return (
      <SimpleForm
        onSubmit={this.props.onSubmit}
        autoSubmit={false}
        className="form-login"
        footer={
          <div>
            <Row type="flex" justify="center" align="middle">
              <Col>
                <Checkbox>Remember me</Checkbox>
                <a href="/password-reset" className="login-form-forgot">
                  Forgot Password
              </a>
              </Col>
            </Row>
            <Row
              type="flex" justify="center" align="middle"
              style={{
                margin: 10
              }}
            >
              <Col align="center" span={24}>
                <Button
                  block
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Log in
                </Button>
              </Col>
              <Col
                style={{
                  padding: "10px 0px"
                }}
              >
                Or <Link to="/signup">Register Now!</Link>
              </Col>
            </Row>
          </div>
        }
      >
        <Input
          onChange={this.onChange}
          validator={[
            { required: true, message: "Please input your email!" }
          ]}
          size="large"
          style={{ width: "100%" }}
          prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="Email"
          name="email"
        />
        <Input.Password
          onChange={this.onChange}
          validator={[
            { required: true, message: "Please input your password!" }
          ]}
          size="large"
          style={{ width: "100%" }}
          prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
          type="password"
          placeholder="Password"
          name="password"
        />
      </SimpleForm>
    );
  }
}
export default LoginForm;
