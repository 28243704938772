import React, { useState, useEffect } from "react";
import { Col, Divider, InputNumber, List, Row } from "antd";
import { WrapperPrice } from "./Styles";
import Helper from "../../../utils/Helper";
const ListPrice = ({ source, min, max, anyPrice = false, steps, prefix, initialValue, ...props }) => {
    const [dataSource, setDataSource] = useState([]);
    const handleOnClick = value => {
        if (props.onChange)
            props.onChange(source, value);
    }
    const renderItem = it => {
        return <List.Item
            onClick={() => handleOnClick(it.value)}
        >
            <div className="item-container">
                <span>
                    {
                        (it.value % 1000000 == 0 || it.value > 1000000) && it.value > 0 ?
                            it.value > 1000000 ? it.text.substring(0, it.text.indexOf(',') + 2) :
                                it.text.substring(0, it.text.indexOf(',')) + "M" : it.text
                    }
                    {it.prefix || ""}</span>
            </div>
        </List.Item>
    }
    const getList = () => {
        let items = Array.apply(null, { length: Math.floor(max / steps) });
        items = items
            .map((it, index) => ({
                prefix,
                text: Helper.money(index > 0 ? (index * steps) : index),
                value: index > 0 ? (index * steps) : index
            }))
            .filter(it => (source == "min" ? it.value >= 0 : it.value > min));

        if (anyPrice) items.push({
            text: "Any Price",
            value: null
        });
        setDataSource(items);
    }
    useEffect(() => {
        getList();
    }, []);
    return (<List
        renderItem={renderItem}
        dataSource={dataSource}
    />)
}
const PriceRange = ({
    start,
    end,
    startField = "PriceFrom",
    endField = "PriceTo",
    maxStartValue,
    minStartEndValue = 0,
    maxEndStartValue,
    maxSteps = 100000,
    minSteps = 100000,
    minEndEndValue = 1,
    ...props }) => {
    const [min_value, setMinValue] = useState(start);
    const [max_value, setMaxValue] = useState(end);
    const [max_list, setMaxList] = useState([]);
    const [show_max, setShowMax] = useState(false);
    const [show_min, setShowMin] = useState(true);
    const handleOnChange = (field, value) => {
        switch (field) {
            case 'min':
                if (max_value < min_value) return setMinValue(0);;
                setMinValue(value);
                if (props.onChange)
                    props.onChange(startField, value);
                break;
            case 'max':
                setMaxValue(value);
                if (props.onChange)
                    props.onChange(endField, value);
                break;
            default:
                break;
        }
    }
    const handleOnFocus = (name) => {
        setShowMax(name == "max");
        setShowMin(name == "min");
    }
    return (<WrapperPrice>
        <Row type="flex" gutter={4}>
            <Col span={11}>
                <InputNumber
                    onChange={(value) => handleOnChange("min", value)}
                    value={min_value}
                    size="large"
                    step={minSteps}
                    formatter={value =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    max={maxStartValue}
                    min={minStartEndValue}
                    defaultValue={start}
                    style={{ width: "100%", margin: "5px" }}
                    label="Minimum Price"
                    placeholder="No Min"
                    /* onChange={value => handlePrice(value, startField)} */
                    onFocus={() => handleOnFocus("min")}

                />
                {
                    show_min && <ListPrice
                        source="min"
                        onChange={handleOnChange}
                        prefix="+"
                        initialValue={0}
                        steps={minSteps}
                        max={minSteps * 10}
                        min={0}
                    />
                }
            </Col>
            <Col span={2}>
                <Divider style={{
                    width: 10,
                    color:"var(--color-secondary)!important"
                }} layout="horizontal" />
            </Col>
            <Col span={11}>
                <InputNumber
                    onChange={(value) => handleOnChange("max", value)}
                    value={max_value}
                    size="large"
                    step={maxSteps}
                    formatter={value =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    defaultValue={end}
                    style={{ width: "100%", margin: "5px" }}
                    min={maxEndStartValue}
                    /* max={minEndEndValue} */
                    label="Maximum Price"
                    placeholder="No Max"
                    /* onChange={value => handlePrice(value, endField)} */
                    onFocus={() => handleOnFocus("max")}
                />
                {
                    show_max && <ListPrice
                        source="max"
                        anyPrice
                        onChange={handleOnChange}
                        prefix="+"
                        initialValue={0}
                        steps={minSteps}
                        max={minSteps * 14}
                        min={min_value}
                    />
                }
            </Col>
        </Row>
    </WrapperPrice>)
}
export default PriceRange;