import React, {Component} from 'react';
import './carousel.css';
import {isMobile} from 'react-device-detect';
import ItemsCarousel from 'react-items-carousel';
import range from 'lodash/range';

import { Button} from 'antd';


class MyCarousel extends Component{
    constructor(props){
        super(props);
        this.state = {
            numberOfCards:3
        }
    }

    componentWillMount() {
        this.setState({
          children: [],
          activeItemIndex: 0,
        });
    
        setTimeout(() => {
          this.setState({
            children: this.createChildren(20),
          })
        }, 100);
    }
    createChildren = n => range(n).map(i => <div key={i} style={{ height: 200, backgroundColor: '#333' }}>{i}</div>);
    changeActiveItem = (activeItemIndex) => this.setState({ activeItemIndex });

    render(){
        let {
            activeItemIndex,
            numberOfCards
          } = this.state;
          
          let {children,total,className} = this.props;
          if(typeof className === "undefined"){
            className = "my-carousel ";
          }

          console.log(">>> TOTAL", total,numberOfCards);
          if(total < numberOfCards){
            numberOfCards = total;
          }
          if(isMobile){
            numberOfCards = 1;
          }
          if(numberOfCards === 1){
            className +=" simple-card";
          }
          
          return (
            <ItemsCarousel
              className={className}
              // Placeholder configurations
              enablePlaceholder
              numberOfPlaceholderItems={5}
              minimumPlaceholderTime={1000}
              placeholderItem={<div style={{ height: 200, backgroundColor: '#900' }}>Placeholder</div>}
      
              // Carousel configurations
              numberOfCards={numberOfCards}
              gutter={0}
              showSlither={true}
              firstAndLastGutter={true}
              freeScrolling={false}
      
              // Active item configurations
              requestToChangeActive={this.changeActiveItem}
              activeItemIndex={activeItemIndex}
              activePosition={'center'}
            
              chevronWidth={24}
              rightChevron={<Button  shape="circle" icon="right" size={"large"} />}
              leftChevron={<Button  shape="circle" icon="left" size={"large"} />}
              outsideChevron={false}
            >
              {children}
            </ItemsCarousel>
          );  
    }
}
export default MyCarousel;