import axios from "axios";
import Constants from "./constants";
import feathers from "@feathersjs/client";

const { URL_BASE_NEW_API, URL_AUTHENTICATION } = Constants;
const ApiFeathers = feathers();
ApiFeathers.configure(
  feathers.rest(URL_BASE_NEW_API).fetch(window.fetch.bind(window))
);
ApiFeathers.configure(
  feathers.authentication({
    path: URL_AUTHENTICATION,
    entity: "user",
    service: "users",
    cookie: "feathers-jwt",
    strategy: "local",
    storageKey: "feathers-jwt",
    storage: window.localStorage
  })
);
export default ApiFeathers;

//const timeout = 60000;
const ApiGO = axios.create({
  baseURL: Constants.URL_API_GOONEWORLD,
  headers: {
    "Content-Type": "application/json"
  }
});
const Api = axios.create({
  baseURL: Constants.URL_API_COMPROPERTEE,
  headers: {
    "Content-Type": "application/json"
  }
});

const Api2 = axios.create({
  baseURL: "/",
  headers: {
    "Content-Type": "application/json"
  }
});

export { ApiGO, Api, Api2, ApiFeathers };
