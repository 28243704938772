import React,{Component} from 'react';
import {PasswordResetForm} from '../../../components/com/authentication';
import './passwordreset.css';

class PasswordReset extends Component{
    render(){
        return(
            <section id="wrapper" className="login-register">
                <div className="login-box login-sidebar">
                    <div className="white-box">
                        <div className="conteim">
                            <a href="/" className="db"><img src="https://static.gooneworld.com/oneworld_logo_teal_3.png" alt="GoOneWorld" /></a>
                        </div>
                        <PasswordResetForm  formLayout="vertical"/>
                    </div>
                </div>
            </section>
        );
    }
}
export default PasswordReset;