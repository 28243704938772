import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { withRouter } from "react-router-dom";

const Image = styled.div`
  background-image: url("/images/404.png");
  max-width: 763px;
  width: 100%;
  margin: 0px auto !important;
  height: 596px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const Wrapper = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;

  margin: 0px auto !important;
  width: 100% !important;
`;

const H3 = styled.h3`
  text-align: center;
`;
const Container = styled.div`
  text-align: center;
`;

const NotFound = ({ history }) => {
  return (
    <Wrapper className="d-flex flex-column justify-content-center align-items-center">
      <Image />
      <H3>Oops, it looks like you missed ...</H3>
      <Container>
        <Button type="primary" size="large" onClick={() => history.push("/")}>
          <strong>BACK TO START</strong>
        </Button>
      </Container>
    </Wrapper>
  );
};

export default withRouter(NotFound);
