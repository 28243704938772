import React, { Component } from "react";
import { withRouter } from "react-router";
import { Input, Icon, Button, Row, message, Col } from "antd";
import "./passwordreset.css";
import { AdvancedForm } from "../../index";
import { Link } from "react-router-dom";
import { recovery_password } from "../../../../services";

class PasswordResetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      loading: false
    };
  }

  onSubmit = (err, params) => {
    let { history } = this.props;
    if (!err) {
      params["origin"] = window.location.origin;
      this.setState({
        loading: true
      });
      recovery_password(params)
        .then(({ success, msg }) => {
          if (!success) return message.error(msg);
          if (typeof this.props.onSubmit !== "undefined")
            this.props.onSubmit(params);

          this.setState({
            loading: false
          });
          localStorage.clear();
          history.push("/login");
          message.info(msg);
        })
        .catch(err => {
          this.setState({
            loading: false
          });
          message.error(err.message);
        });
    }
  };
  render() {
    return (
      <AdvancedForm
        formLayout="vertical"
        onSubmit={this.onSubmit}
        footer={
          <div>
            <Row
              type="flex"
              justify="start"
              align="middle"
              style={{
                margin: 10
              }}
            >
              <Col span={24}>
                <Button
                  loading={this.state.loading}
                  size="large"
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  SEND
                </Button>
              </Col>
              <Col
                style={{
                  padding: "10px 0px"
                }}
              >
                <span>Do u have an account?</span>{" "}
                <Link to="/login">Sign In</Link> <span>Or </span>
                <Link to="/signup">register now!</Link>
              </Col>
            </Row>
          </div>
        }
      >
        <div>
          <Row
            style={{
              width: "100%"
            }}
            type="flex"
            justify="center"
            align="middle"
          >
            <Col span={16}>
              <p style={{ textAlign: "center" }}>
                Please input your email to recover your password.
              </p>
            </Col>
          </Row>
          <Input name="origin" initial={window.location.origin} type="hidden" />
          <Input
            size="large"
            style={{ width: "100%" }}
            prefix={<Icon type="email" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Email"
            type="email"
            name="email"
            validator={[
              { required: true, message: "Please input your email!" }
            ]}
          />
        </div>
      </AdvancedForm>
    );
  }
}
export default withRouter(PasswordResetForm);
