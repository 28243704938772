import React, { Component, useState, useEffect } from "react";
import { Card, Icon, Row, Button, Carousel, message, Modal } from "antd";
import { Link } from "react-router-dom";
import "./listingcard.css";
import Constants from "../../utils/constants";
import Helper from "../../utils/Helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { Meta } = Card;
const { money } = Helper;

const URL_COMING_SOON = require("../../images/coming_soon.png");
const scales = {
  small: "150",
  default: "200",
  large: "400"
}
const WrapperCover = props => {
  let { PhotosCount, ListingKeyNumeric, ListingType } = props;
  const ext = ListingType == "GOW" ? "jpeg" : "jpg";
  const [image, setImage] = useState();

  useEffect(() => {
    if (props.size) {
      let URL_PHOTO =
        PhotosCount > 0
          ? `${Constants.URL_API_PHOTOS}${ListingKeyNumeric}/0.${ext}?width=${scales[props.size || "default"]}&height=${scales[props.size || "default"]}`
          : URL_COMING_SOON;
      setImage(URL_PHOTO);
    }
  }, [props.size])
  return (
    <div
      className="cover-card"
      alt="example"
      style={{
        backgroundImage: `url(${image})`,
        width: "100%",
        height: "100%",
        backgroundSize: "100% 100%",
        backgroundPosition: "center"
      }}
    >
      {props.children}
    </div>
  );
};
class ListingMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0
    };
  }

  handleAddFavorite = () => {
    let { ListingKeyNumeric } = this.props;
    if (this.props.handleAddFavorite)
      this.props.handleAddFavorite(ListingKeyNumeric);
  };
  onChange = current => {
    this.setState({
      current
    });
  };

  render() {
    let address = "";
    let {
      StreetNumber,
      StreetDirPrefix,
      StreetName,
      UnitNumber,
      City,
      StateOrProvince,
      transaction_type,
      Matrix_Unique_ID,
      ListingKeyNumeric,
      PhotosCount,
      Slug,
      viewDetail,
      showActions,
      displayGalery,

      BedroomsTotal,
      BathroomsFull,
      PropertyType,
      size,
      record
    } = this.props;

    address += StreetNumber !== "No Data" ? " " + StreetNumber : "";
    address += StreetDirPrefix !== "No Data" ? " " + StreetDirPrefix : "";
    address += StreetName !== "No Data" ? " " + StreetName : "";
    /* address +=
      UnitNumber !== "No Data" && UnitNumber !== "" && UnitNumber != null
        ? " # " + UnitNumber
        : ""; */

    if (!transaction_type)
      transaction_type = PropertyType === "Residential" ? "Sale" : "Lease";
    var address_show = address.split(",")[0].toUpperCase();
    var address_listing = address_show.substr(0, 24).replace(/NULL/g, "");
    var lenadd = address_show.length;
    var puntos = lenadd > 24 ? ".." : "";
    //console.log("--> ", StreetNumber, StreetDirPrefix, StreetName, UnitNumber);

    var typeprop = "";
    var size_sqft = "";

    if (
      this.props.StyleofProperty !== "" &&
      this.props.StyleofProperty !== "No Data"
    ) {
      typeprop = this.props.StyleofProperty;
    } else if (
      this.props.TypeofProperty !== "" &&
      this.props.TypeofProperty !== "No Data"
    ) {
      typeprop = this.props.TypeofProperty;
    } else {
      typeprop = "Other";
    }

    if (this.props.LivingArea != null && this.props.LivingArea > 0) {
      size_sqft = this.props.LivingArea + " Sqft";
    } else {
      size_sqft = "";
    }

    showActions =
      typeof this.props.showActions !== "undefined"
        ? this.props.showActions
        : true;
    displayGalery = typeof displayGalery !== "undefined" ? displayGalery : true;
    transaction_type = PropertyType === "Residential" ? "Sale" : "Rent";
    let TransactionType = PropertyType === "Residential" ? "Sale" : "Lease";
    /* console.log(
      "Link: ",
      `/listings/details/${City}/${StateOrProvince}/${transaction_type}/${Slug}/${ListingKeyNumeric}`
    ); */

    return (
      <Card
        className={`listing-card ${size ? "card-" + size : ""}`}
        hoverable
        size={this.props.size}
        style={{ width: "100%" }}
        cover={<div>
          <WrapperCover
            {...this.props}
            size={this.props.size}
            current={this.state.current}
            onChange={this.onChange}
            displayGalery={displayGalery}
          >
            {viewDetail && (
              <div className="cover-container">
                <div className="cover-detail">
                  <Button
                    size="large"
                    className="btn-detail"
                    onClick={e => console.log(e.target)}
                  >
                    VIEW DETAILS
                  </Button>
                </div>
              </div>
            )}
            {showActions && (
              <div className="card-actions">
                <div>
                  <Button
                    className="heart-btn"
                    onClick={this.handleAddFavorite}
                    type="link"
                  >
                    <Icon type="heart" theme="filled" />
                  </Button>
                </div>
              </div>
            )}
          </WrapperCover>
          <div className="card-tools">
            <Button
              onClick={() => {
                if (this.props.onClose) this.props.onClose();
              }}
              type="ghost" icon="close" shape="circle" size="small" />
          </div>
        </div>
        }
        onMouseEnter={() => {
          if (this.props.onOver) this.props.onOver(record);
        }}
        {...this.props}
      >
        <Meta
          title={
            <div className="title-card">
              {/*   <a
                href={`/listings/details/${City}/${StateOrProvince}/${transaction_type}/${Slug}/${ListingKeyNumeric}`}
              >
                <h3 className="list-price">{money(this.props.ListPrice)}</h3>
              </a> */}
              <div className="title-card">
                <h3 className="list-price txt-ellipsis">
                  {money(this.props.ListPrice)}
                </h3>
              </div>
            </div>
          }
          description={
            <div>
              <Row>
                <div className="type-card">
                  {BedroomsTotal && (
                    <small>
                      <FontAwesomeIcon
                        className="icon-fa"
                        size="lg"
                        icon={["fas", "bed"]}
                      />{" "}
                      <span>
                        {BedroomsTotal} {BedroomsTotal > 1 ? "bd" : "bd"}
                      </span>
                    </small>
                  )}
                  {!BedroomsTotal && !BedroomsTotal && !size_sqft && (
                    <small>
                      <FontAwesomeIcon
                        className="icon-fa"
                        icon={["fas", "house-damage"]}
                      />{" "}
                      without detail.
                    </small>
                  )}
                  {BathroomsFull && (
                    <small
                      className={
                        BathroomsFull
                          ? "item-property divider-left"
                          : "item-property"
                      }
                    >
                      <FontAwesomeIcon
                        className="icon-fa"
                        size="lg"
                        icon={["fas", "bath"]}
                      />{" "}
                      <span>
                        {BathroomsFull} {BathroomsFull > 1 ? "ba" : "ba"}
                      </span>
                    </small>
                  )}

                  {size_sqft && (
                    <small
                      className={
                        BedroomsTotal
                          ? "item-property divider-left"
                          : "item-property"
                      }
                    >
                      <FontAwesomeIcon
                        className="icon-fa"
                        size="lg"
                        icon={["fas", "ruler"]}
                      />{" "}
                      <span>{size_sqft}</span>
                    </small>
                  )}
                </div>
                <Row className="card-address">
                  <Link
                    className={"txt-ellipsis"}
                    to={`/listings/details/${City}/${StateOrProvince}/${TransactionType}/${Slug}/${ListingKeyNumeric}`}
                  >
                    {address_listing.replace("ct", "Ct").replace("ht", "Ht")}
                  </Link>
                </Row>
                <div className="comple_add">
                  {this.props.City} {this.props.StateOrProvince}{" "}
                  {this.props.PostalCode}
                </div>
              </Row>
            </div>
          }
        />
      </Card>
    );
  }
}

export default ListingMap;
