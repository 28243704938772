import React, { Component } from "react";
import RegisterForm from "../../../components/com/authentication/register/RegisterForm";
import { signup } from "../../../services";
import { message, Button, Col, Row } from "antd";
import { Link } from "react-router-dom";
import "./register.css";

import logo from "../../../images/bruno-square.jpg";

class Register extends Component {
  state = {
    view: "register"
  };
  onSubmit = (err, params) => {
    let { history } = this.props;
    if (!err) {
      if (params["password"] !== params["rp_password"])
        return message.error("Passwords do not match!");
      delete params["rp_password"];

      signup(params)
        .then(response => {
          let { data } = response;
          console.log("Response: ", data);
          this.setState({
            view: "check-email"
          });
          /* history.push("/login"); */
        })
        .catch(err => message.error(err.message));
    }
  };
  render() {
    let { history } = this.props;
    return (
      <section id="wrapper" className="login-register">
        <div className="login-box login-sidebar">
          <div className="white-box">
            <div className="conteim">
              <a href="/" className="db">
                <img src={logo} width="90" />
              </a>
            </div>
            {this.state.view == "register" ? (
              <RegisterForm formLayout="vertical" onSubmit={this.onSubmit} />
            ) : (
              <div
                style={{
                  textAlign: "center"
                }}
              >
                <h2>Message Sent</h2>
                <p>Please go to your inbox and validate your email</p>
                <div>
                  <Row
                    type="flex"
                    justify="start"
                    align="middle"
                    style={{
                      margin: 10
                    }}
                  >
                    <Col span={24}>
                      <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        onClick={() => {
                          history.push("/login");
                        }}
                      >
                        GO TO LOGIN
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}
export default Register;
