import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../../redux/actions/action_search";
import qs from "qs";
import { Button, Row, Col, Affix, BackTop, message } from "antd";
import "../listings.css";
import debounce from "lodash/debounce";
import ListingsCards from "../../components/listings/ListingsCards";
import Layout from "../../layouts/layoutlistings";
/* import Layout search={false} from "../../layouts/layoutcontesubdivision"; */
import Map from "../../components/com/Map/Map";
import { isMobile } from "react-device-detect";
import axios from "axios";
import store from "../../redux/stores";
import { Constants } from "../../utils";

import { property_search, api_search } from "../../services";
import { MapBox } from "../../components";
const MAX_PAGE_SIZE = 30;

let source;
class ListingZipCode extends Component {
  constructor(props) {
    super(props);
    this.search = debounce(this.search, 800);
  }
  state = {
    top: 0,
    data: [],
    pagination: {},
    filter: false,
    loading: true,
    loadingMap: true
  };

  componentWillMount() {
    const self = this;
    store.subscribe(() => {
      let _state = store.getState();
      let { search } = _state;
      console.log("Change Store: ", search);
      if (search) {
        let data = search.data || {};
        let {
          match: { params }
        } = self.props;
        params["PropertyType"] =
          params.transaction_type == "Sale"
            ? "Residential"
            : "ResidentialLease";
        params = {
          ...params,
          ...data
        };
        params["limit"] = 30;

        self.search(params);
      }
    });
    this.changeQuery();
    //this.searchListings();
  }
  search = params => {
    const self = this;
    //return console.log("Search Data: ", this.state.filter, params);
    if (!this.state.filter) {
      if (source && !axios.isCancel()) {
        source.cancel("Request canceled.");
        source = axios.CancelToken.source();
      } else {
        source = axios.CancelToken.source();
      }
      this.setState({
        loadingMap: true,
        loading: true,
        filter: true
      });

      let { URL_BASE, URL_API_SEARCH } = Constants;
      //{"PropertySubType": 'SingleFamilyResidence,Condominium,Townhouse'}

      return property_search(params)
        .then(({ data, meta }) => {
          console.log("DATA:: ", data);
          this.setState({
            data,
            pagination: meta,
            filter: false,
            loading: false
          });
        })
        .catch(err => message.error(err.message));

      return axios
        .get(`https://api.gooneworld.com/api/Properties/search/res?cache=120`, {
          params: { params },
          cancelToken: source.token
        })
        .then(response => {
          let res = response.data || [];
          let { data, meta } = res;

          console.log("DATA::: ", data);
          source = null;

          this.setState({
            data,
            pagination: meta,
            filter: false,
            loading: false
          });
        })
        .catch(thrown => {
          if (axios.isCancel(thrown)) {
            console.log("Axios Cancel: ", thrown.message);
          } else {
            this.setState({
              loading: false,
              filter: false,
              loadingMap: false
            });
          }
        });

      params["cancelToken"] = this.source.token;

      //return console.log("Filtrado: ", this.state.filter, params);
      return api_search({ params })
        .then(response => {
          let res = response.data || [];
          let { data, meta } = res;
          this.setState({
            data,
            pagination: meta,
            filter: false,
            loading: false
          });
        })
        .catch(thrown => {
          if (axios.isCancel(thrown)) {
            console.log("Axios Cancel: ", thrown.message);
          } else {
            this.setState({
              loading: false,
              filter: false,
              loadingMap: false
            });
          }
        });
    }
  };
  searchListings = () => {
    let { match, search, location } = this.props;
    let data = search.data || {};
    let { params } = match;
    let { PostalCode, transaction_type } = params;
    /* Get Query String */
    let query = location.search || "";
    let values = qs.parse(query.replace("?", ""));
    data = {
      PostalCode,
      PropertyType:
        transaction_type == "Sale" ? "Residential" : "ResidentialLease",
      ...data
    };
    alert(JSON.stringify(data));
    this.search(data);
    // this.props.actions.changeSearch(data);
  };
  changeQuery = (name, value) => {
    let { location, history } = this.props;
    let { search } = location;
    let query = qs.parse(search.replace(/\?/, ""));
    if (name && value) {
      query[name] = query[name] !== value ? value : undefined;
      history.replace(`?${qs.stringify(query)}`);
    } else {
      this.setState({
        ...query
      });
    }
  };
  sortByChange = order_by => {
    let { search } = this.props;
    let data = search.data || {};
    data = { ...data, order_by, limit: 30 };
    this.changeQuery("order_by", order_by);
    this.props.actions.changeSearch(data);
  };
  handlePagination = page => {
    let { search } = this.props;
    let data = search.data || {};
    console.log("pagination: ", data);
    data = { ...data, page, limit: 30 };
    this.props.actions.changeSearch(data);
  };
  render() {
    let { data, pagination, loading, loadingMap } = this.state;
    let { match, } = this.props;
    let { params } = match;
    let { PostalCode, transaction_type } = params;
    return (
      <Layout>
        <Row
          style={{
            height: "100%"
          }}
        >
          <Col
            md={12}
            /* lg={13} */
            style={{
              padding: "0px!important"
            }}
          >
            <ListingsCards
              data={data}
              pagination={pagination}
              loading={loading}
              sortByChange={this.sortByChange}
              onChange={this.handlePagination}
            />
            {isMobile && (
              <BackTop>
                <Button type="primary" shape="circle" icon="arrow-up" />
              </BackTop>
            )}
          </Col>
          <Col
            span={12}
            className="conte_map"
            style={{
              paddingLeft: "0px!important"
            }}
          >
            <Affix offsetTop={this.state.top}>
              <div className="map">
                <Map
                  dataSource={data}
                  reference="get-listing-geocode"
                  filterDefaultValues={{
                    PostalCode,
                    PropertyType: transaction_type == "Sale" ? "Residential" : "ResidentialLease"
                  }}
                />
              </div>
            </Affix>
          </Col>
        </Row>
      </Layout>
    );
  }
}
const mapStateToProps = state => {
  const { search } = state;
  return {
    search: search
  };
};
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ListingZipCode));
