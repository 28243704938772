/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";

//import Header from "../../components/header";
import Menu from "../../components/menu";
import { Layout, Row, Col } from "antd";
import "./leadgeneration.css";

const { Content } = Layout;
//const background_header = require("./images/slide_1.jpg");
const imgbruno = require("./images/images.jpg");

const LayoutLeadGeneration = ({ children }) => (
  <div className="leadgeneration">
    <div className="row">
    <header className="head-lead">
        <Menu />
        <div className="conte-middle">
          <h1 className="header-title">
            <div className="title-main">LEAD GENERATION FOR REALTORS</div>
          </h1>
          <h2 className="subtitle-main"><p>Get Leads From Buyers, Sellers And Tenants<br/>Convert Prospects Into Sales</p></h2>
        </div>
    </header>
    </div>

    <Layout>
      {/*<Content>
        <Row type="flex" justify="center">
          <Col span={24}>
            <div className="home" type="flex">
              <main></main>
            </div>
          </Col>
        </Row>
      </Content>*/}
      
        <div className="span12">
          <div className="content_box box">
            <Row>
              <Col md={24} sm={24} lg={8}>
                
                  <div className="bloq ">
                          <section> <span className="dropcap">Leads</span>
                            <div className="service-box ">
                              <div className="service-box_body">
                                <h2>Lead</h2>
                                <h5>Generation</h5>
                                <div className="service-box_txt">
                                  <p>Get Leads from Home Buyers searching your website.</p>
                                  <p>Capture Home Sellers prospects.</p>
                                  <p>Agent website with RETS Feeds with ALL MLS listings.</p>
                                  <p>Notifications for new Foreclosure filing.</p>
                                  <p>Alerts for Expired Listings.</p>
                                  <p>Landing Pages Design Creation.</p>
                                  <p>Live Chat interaction with clients.</p>
                                </div>
                                <div className="btn-align finbo"></div>
                              </div>
                            </div>
                          </section>
                  </div>
                
              </Col>

              <Col md={24} sm={24} lg={8}>
                
                  <div className="bloq ">
                      <section> <span className="dropcap">CRM</span>
                            <div className="service-box ">
                              <div className="service-box_body">
                                <h2>Customer</h2>
                                <h5>Relation Management</h5>
                                <div className="service-box_txt">
                                  <p>Track your Leads and Prospects in one place.</p>
                                  <p>Monitor your listings</p>
                                  <p>Setup automated email messages to follow up with prospects.</p>
                                  <p>Create tasks, alerts to manage client outreach.</p>
                                </div>
                                <div className="btn-align finbo"></div>
                              </div>
                            </div>
                      </section>
                  </div>
                
              </Col>

              <Col md={24} sm={24} lg={8}>
                
                  <div className="bloq ">
                      <section> <span className="dropcap">Marketing</span>
                            <div className="service-box ">
                              <div className="service-box_body">
                                <h2>Marketing</h2>
                                <h5>Tools</h5>
                                <div className="service-box_txt">
                                <p>Reach customers by mass email and social media.</p>
                                <p>Personalized responsive SEO website for ALL One World agents.</p>
                                <p>Create your own Blog for news articles, industry updates and market reports.</p>
                                <p>Own Social Sharing Application to market listings.</p>
                                <p>Agent Branding – Logo, Domain, Videos, Photos.</p>
                                <p>Marketing Analytics results.</p>
                                </div>
                                <div className="btn-align finbo"></div>
                              </div>
                            </div>
                      </section>
                  </div>
                
              </Col>
            </Row>


          </div>
        </div>
      

      <div className="span12">
        <Row className="bloq_three">
            <Col md={12} sm={24} lg={6}>
                <section className="sec_service">
                      <div className="title-box clearfix ">
                        <h2 className="title-box_primary">Our Services</h2>
                      </div>
                      <p>One World International provides several free services for all our real estate agents to help them to succeed in the bussines</p>
                </section>
            </Col>
            <Col md={12} sm={24} lg={18}>
                <section> 
                  <ul className="posts-grid list-1">
                    <li className="span4">
                          <figure className="featured-thumbnail thumbnail">
                            <div className="tim freecrm">
                                <div className="sombra"></div>
                            </div>
                          </figure>
                          <div className="clear"></div>
                          <h5>FREE CRM</h5>
                    </li>
                    <li className="span4">
                          <figure className="featured-thumbnail thumbnail">
                            <div className="tim freeagent">
                              <div className="sombra"></div>
                            </div>
                          </figure>
                          <div className="clear"></div>
                          <h5>FREE Agent Website</h5>
                    </li>
                    <li className="span4">
                          <figure className="featured-thumbnail thumbnail">
                            <div className="tim freelead">
                              <div className="sombra"></div>
                            </div>
                          </figure>
                          <div className="clear"></div>
                          <h5>FREE Lead Generation Tools</h5>
                    </li>
                  </ul>
                </section>
            </Col>
        </Row>

        <div className="spacer"></div>
                
        <Row className="bloq_four">
            <section >
              <div className="title-box clearfix ">
                <h2 className="title-box_primary">A little About us</h2>
              </div>
              <div className="content_box info_box">
                <Col span={5}>
                  <img src={imgbruno}/>
                </Col>
                <Col span={18}>
                  <h5>Bruno Linares</h5>
                  <h6>Real Estate Technology Innovator</h6>
                  <p>One World Int'l Real Estate is one of the fastest and more experienced real estate organization in South Florida. We are dedicated to providing buyers and sellers of real estate with the highest quality services possible. One World Int'l is actively increasing and globally servicing International clients with the primary focus in local Florida market.</p>
                  <p>One World International brokers and sales associates use cutting-edge technology to make the transaction as smooth and convenient as possible. Our experienced sales team can offer a broad list of choices from primary property to investment and commercial opportunities.</p>
                </Col>
                <div className="clear"></div>
              </div>
            </section>
        </Row>
      </div>


      {/*<footer>
        <div>Test</div>© {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
      </footer>*/}
    </Layout>
  </div>
);

LayoutLeadGeneration.propTypes = {
  children: PropTypes.node.isRequired
};

export default LayoutLeadGeneration;
