import React, { Component } from "react";
import { Layout, Row } from "antd";
import "./layoutdetails.css";
import Menu from "../components/menuListings";
import Preloader from "../components/com/preloading/Preloader";
import Footer from "../components/com/footer/Footer";

const { Content } = Layout;

const LayoutDetails = ({ children, loading }) => (
  <div className="listingsdetail-container">
    {!loading ? (
      <div className="listingsdetail">
        <header>
          <Menu search={true} />
        </header>
        {/*  <Header className="menu-listingsdetail">
        <Search />
      </Header> */}
        <div>
          <div>
            <Row
              
              type="flex"
              justify="center"
            >
              {children}
            </Row>
          </div>
        </div>
        {<Footer>Footer</Footer>}
      </div>
    ) : (
      <Layout>
        <Content
          style={{
            width: "100%",
            margin: "0px auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Preloader />
        </Content>
      </Layout>
    )}
  </div>
);
export default LayoutDetails;
