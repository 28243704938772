import React, { Component } from "react";
import { withRouter } from "react-router";
import { Card, Icon, Row, Button, Carousel, message, Modal, Tag } from "antd";
import { Link } from "react-router-dom";
import "./condocard.css";
import Constants from "../../utils/constants";
import Helper from "../../utils/Helper";
import { add_favorite } from "../../services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageLoader from "react-load-image";
import Preloader from "../com/preloading/Preloader";
const house = require("../../images/house.svg");

const { confirm } = Modal;
const { Meta } = Card;
const { money } = Helper;

const URL_COMING_SOON = require("../../images/coming_soon.png");
const ImageError = () => (
  <div
    className="cover-card"
    alt="example"
    style={{
      backgroundImage: `url(${URL_COMING_SOON})`,
      width: "100%",
      height: "100%",
      backgroundSize: "cover",
      backgroundPosition: "center"
    }}
  />
);
const BackgroundImage = ({ src, style = {}, ...props } = {}) => (
  <div
    style={Object.assign({ backgroundImage: `url(${src})` }, style)}
    {...props}
  />
);
const Image = props => (
  <ImageLoader {...props}>
    <BackgroundImage
      className="cover-card"
      style={{
        width: "100%",
        height: "200px",
        backgroundSize: "cover",
        backgroundPosition: "center"
      }}
    />
    <div
      style={{
        /*  backgroundImage: house, */
        width: "100%",
        height: "210px",
        background: "#fafafa",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        position: "relative"
      }}
    >
      <ImageError />
    </div>

    <div
      style={{
        backgroundImage: house,
        width: "100%",
        minHeight: "100px",
        maxHeight: "200px",
        background: "#fafafa",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        position: "relative"
      }}
    >
      <Preloader />
    </div>
  </ImageLoader>
);

const WrapperCover = props => {
  let { PhotosCount, ListingKeyNumeric, current } = props;
  let URL_PHOTO =
    PhotosCount > 0
      ? `/imgcondos/${ListingKeyNumeric}/1.jpg`
      : URL_COMING_SOON;

  /*let images = Array.apply(null, { length: PhotosCount })
    .map((item, index) => {
      if (index === 0) return;
      return `${
        Constants.URL_API_PHOTOS
      }${ListingKeyNumeric}/${index}.jpg?width=400&height=400`;
    })
    .filter(item => item != undefined);*/

    let images = Array.apply(null, { length: PhotosCount })
    .map((item, index) => {
      console.log("index ",index);
      if (index === 0) return;
      return `/imgcondos/${ListingKeyNumeric}/${index+1}.jpg`;
    })
    .filter(item => item != undefined);
  
  images.push(URL_PHOTO);


  const myRef = React.createRef();
  const imageRef = React.createRef();

  return (
    <>
      {props.displayGalery ? (
        <>
          <Image src={URL_PHOTO} />

          {props.children}
          {props.displayActions && PhotosCount > 1 && (
            <div className="tools-carousel">
              <Button
                className="btn-left"
                type="link"
                icon="left"
                onClick={() => {
                  if (props.handleClick) {
                    console.log("Images: ", images);

                    props.handleClick();
                  }
                }}
              />
              <Button
                className="btn-right"
                type="link"
                icon="right"
                onClick={() => {
                  props.handleClick();
                }}
              />
            </div>
          )}
          {props.displayActions && (
            <div className="footer-cover">
              <div
                style={{
                  float: "right",
                  display: "inline-block",
                  padding: "5px"
                }}
              >
                {PhotosCount} {PhotosCount > 1 ? "Photos" : "Photo"}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <Carousel
            ref={myRef}
            afterChange={props.onChange}
            className="carousel"
            autoplay={false}
            dots={false}
            displayGalery={props.displayGalery}
            style={{
              height: 400,
              background: "red"
            }}
          >
            {images.map((url, index) => {
              return (
                <div key={index} className="carousel-card">
                  {/* <div
                    className="cover-card"
                    alt="example"
                    style={{
                      backgroundImage: `url(${url})`,
                      width: "100%",
                      height: "100%",
                      backgroundSize: "cover",
                      backgroundPosition: "center"
                    }}
                  /> */}
                  <Image src={url} />
                </div>
              );
            })}
          </Carousel>
          <div className="footer-cover">
            {PhotosCount > 0 && (
              <div
                style={{
                  float: "right",
                  display: "inline-block",
                  padding: "5px"
                }}
              >
                <span>{current === PhotosCount - 1 ? " 1 " : current + 2}</span>
                {" of "}
                {PhotosCount} {PhotosCount > 1 ? "Photos" : "Photo"}
              </div>
            )}
          </div>
          <div>{props.children}</div>
          <div className="tools-carousel">
            <Button
              className="btn-left"
              type="link"
              icon="left"
              onClick={() => {
                myRef.current.prev();
              }}
            />
            <Button
              className="btn-right"
              type="link"
              icon="right"
              onClick={() => {
                //props.next(current++);
                myRef.current.next();
              }}
            />
          </div>
        </>
      )}
    </>
  );
};
class ListingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      displayGalery: true
    };
  }

  /*handleAddFavorite = ListingKeyNumeric => {
    const me = this;
    let { history, location } = this.props;
    //let { ListingKeyNumeric } = this.props;
    let paramas = {
      hostApp:'GOW',
      userId: localStorage.userId,
      ListingKeyNumeric
    };
    if (!localStorage.userId)
      return confirm({
        width: 300,
        title: <strong>Do You Like This Listing?</strong>,
        content: "Sign In to Save It",
        onOk() {
          history.push("/login");
        }
      });
    me.add_to_favorite(paramas);
  };
  add_to_favorite = paramas => {
    add_favorite(paramas)
      .then(response => {
        let { data } = response;
        message.info(data.description);
      })
      .catch(error => message.error(error.message));
  };
  onChange = current => {
    this.setState({
      current
    });
  };*/

  render() {
    let showActions = "";

    let {
      ListingKeyNumeric
      ,City
      ,State
      ,SubdivisionName
      ,TotalUnits
      ,TotalFloor
      ,YearBuilt
      ,namecondo
    } = this.props;

    let { displayGalery,PhotosCount } = this.state;

    /*address += StreetNumber !== "No Data" ? " " + StreetNumber : "";
    address += StreetDirPrefix !== "No Data" ? " " + StreetDirPrefix : "";
    address += StreetName !== "No Data" ? " " + StreetName : "";*/


    //var address_show = address.split(",")[0].toUpperCase();
    //var address_listing = address_show.substr(0, 30).replace(/NULL/g, "");
    //var lenadd = address_show.length;
    //var puntos = lenadd > 30 ? ".." : "";

    var typeprop = "";
    var size_sqft = "";

    /*if (
      this.props.StyleofProperty !== "" &&
      this.props.StyleofProperty !== "No Data"
    ) {
      typeprop = this.props.StyleofProperty;
    } else if (
      this.props.TypeofProperty !== "" &&
      this.props.TypeofProperty !== "No Data"
    ) {
      typeprop = this.props.TypeofProperty;
    } else {
      typeprop = "Other";
    }

    if (
      this.props.LivingArea != null &&
      this.props.LivingArea > 0
    ) {
      size_sqft = this.props.LivingArea + " Sqft";
    } else {
      size_sqft = "";
    }*/

    const URL_PHOTO =
      PhotosCount > 0
        ? `${
            Constants.URL_API_PHOTOS
          }${ListingKeyNumeric}/0.jpg?width=400&height=400`
        : URL_COMING_SOON;

    showActions =
      typeof this.props.showActions !== "undefined"
        ? this.props.showActions
        : true;
    displayGalery = typeof displayGalery !== "undefined" ? displayGalery : true;
    

    return (
      <Card
        hoverable={this.props.hoverable || true}
        className="listing-card"
        style={{ width: "100%" }}
        cover={
          <WrapperCover
            next={current => {
              this.setState({
                current
              });
            }}
            {...this.props}
            current={this.state.current}
            onChange={this.onChange}
            handleClick={() => {
              let displayGalery =
                typeof this.props.displayGalery !== "undefined"
                  ? this.props.displayGalery
                  : true;
              if (displayGalery) this.setState({ displayGalery: false });
            }}
            displayGalery={displayGalery}
          >
          </WrapperCover>
        }
      >
        <Meta
          title={
            <div className="title-card">
              <Link
                to={`/miami-beach/${namecondo}`}
              >
                {SubdivisionName}
              </Link>
            </div>
          }
          description={
            <div>
              <Row>
                <div className="comple_add">
                  Stories: {this.props.TotalFloor} Units: {this.props.TotalUnits}{" "}
                  <br/>
                  Year Built: {this.props.YearBuilt}
                </div>
                {/*<div className="type-card">
                  {!TotalUnits && !TotalFloor && !YearBuilt && (
                    <small>
                      <FontAwesomeIcon
                        className="icon-fa"
                        icon={["fas", "house-damage"]}
                      />{" "}
                      without detail.
                    </small>
                  )}
                  {TotalUnits && (
                    <small>
                      <FontAwesomeIcon
                        className="icon-fa"
                        size="lg"
                        icon={["fas", "bath"]}
                      />{" "}
                      Units: {TotalUnits}
                    </small>
                  )}

                  {TotalFloor && (
                    <small
                      className={
                        TotalFloor
                          ? "item-property divider-left"
                          : "item-property"
                      }
                    >
                      <FontAwesomeIcon
                        className="icon-fa"
                        size="lg"
                        icon={["fas", "bed"]}
                      />{" "}
                      Stories: {TotalFloor}
                    </small>
                  )}
                  {size_sqft && (
                    <small
                      className={
                        BedroomsTotal
                          ? "item-property divider-left"
                          : "item-property"
                      }
                    >
                      <FontAwesomeIcon
                        className="icon-fa"
                        size="lg"
                        icon={["fas", "ruler"]}
                      />{" "}
                      {size_sqft}
                    </small>
                  )}
                </div>*/}
                {/* <div className="type-card">
                  <small>{typeprop}</small>
                </div> */}
              </Row>
              <Row>
                {/*<h3 className="list-price">
                  {money(this.props.ListPrice)}
                </h3>*/}
              </Row>
            </div>
          }
        />
      </Card>
    );
  }
}

export default withRouter(ListingCard);
