import React, { Component } from "react";
import { Menu, Button, Dropdown, Checkbox, Row, Col, Icon } from "antd";
import { isMobile } from "react-device-detect";
import "./dropdown.css";
const onCheck = e => {
  console.log(e.target);
};

class DropDownMenu extends Component {
  static count = 0;
  static current = null;
  static opened = false;

  constructor(props) {
    super(props);
    DropDownMenu.count++;

    this.name = props.name || "drop_down_" + DropDownMenu.count;
  }
  state = {
    visible: false
  };
  handleChange = values => {
    let { selection } = this.props;
    if (values.length > 0) selection = values;
    if (typeof this.props.onChange !== "undefined") this.props.onChange(values);
  };
  handleDone = () => {
    DropDownMenu.current = null;
    if (this.props.onDone) this.props.onDone();
  }
  render() {
    let props = this.props;
    let { label, onChange, minChecked } = props;
    let defaultValue = props.defaultValue || [];
    var menu = props.menu || (
      <Menu
        className="property-type-menu"
        style={
          props.menuStyle || {
            width: 200
          }
        }
      >
        <Menu.Item>{props.children}</Menu.Item>
        <Menu.Item
          className="item-footer"
          key={"footer"}>
          {<div className="footer-container">
            <Button
              onClick={this.handleDone}
              className="btn-done"
              type="link"
              icon="close"
              size="default"
            >
              Close
          </Button>
          </div>}
        </Menu.Item>
      </Menu>
    );
    let selection =
      typeof props.selection !== "undefined"
        ? props.selection.length > 0
          ? props.selection
          : defaultValue
        : defaultValue;
    let visible = this.state.visible || false;
    let { name } = this;
    DropDownMenu.opened = DropDownMenu.current === name;
    let style = {
      minWidth: "320px",
      maxWidth: "400px"
    };
    if (props.size === "small") {
      style = {
        minWidth: "200px",
        maxWidth: "400px"
      };
    }
    return (
      <Dropdown
        className="drop-down-menu"
        overlay={menu}
        overlayStyle={{ width: "auto" }}
        visible={DropDownMenu.opened}
        trigger={[isMobile ? "click" : "hover"]}
        onVisibleChange={e => {
          visible = !visible;
          if (typeof props.onClick !== "undefined") {
            props.onClick();
          }
        }}
        onClick={() => {
          let { name } = this;
          if (DropDownMenu.current !== name) {
            DropDownMenu.current = name;
          } else {
            DropDownMenu.current = null;
          }
          this.setState({
            visible: !this.state.visible
          });
          if (typeof props.onClick !== "undefined") {
            props.onClick();
          }
        }}
      >
        <Button
          size={props.size || "large"}
          className={props.className || "btn-icon-right"}
          style={props.style || { margin: 0, width: "100%" }}
          block
          icon={props.icon || "down"}
        >
          {props.prefix && (
            <span style={{ float: "left", verticalAlign: "middle" }}>
              {props.prefix}
            </span>
          )}
          {label}{" "}
        </Button>
      </Dropdown>
    );
  }
}

export default DropDownMenu;
