//ContactForm.js
import React, { Component, useState, useEffect } from "react";
import $ from "jquery";
import { Input, Button, Row, Card, Icon, Avatar, message, Col } from "antd";
import AdvancedForm from "../form/AdvancedForm";
import Helper from "../../../utils/Helper";
import "./contactform.css";

import { send_email } from "../../../services";
import ButtomTerm from "../../buttom-term/ButtomTerm";
/* import axios from "axios"; */
const { TextArea } = Input;
const { Meta } = Card;

class ContactForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      buttonText: "Request Information",
      firstname_contact: "",
      lastname_contact: "",
      email_contact: "",
      phone_contact: "",
      message_contact: "",
      Slug: "",
      Matrix_Unique_ID: "",
      Address: "",
      City: "",
      msg: null,
      StateOrProvince: "",
      PostalCode: "",
      url_site: ""
    };
  }

  onChange = e => {
    let { value, name } = e.target;
    console.log("value y name", value, name);

    this.setState({
      [name]: value
    });
  };

  onSendMessage = e => {
    let payload = this.state;

    if (typeof this.props.onSubmit !== "undefined") {
      this.props.onSubmit(payload);
    }
  };

  componentDidMount() {
    let {
      Slug,
      Matrix_Unique_ID,
      Address,
      City,
      StateOrProvince,
      PostalCode,
      url_site
    } = this.props;

    this.setState({
      Slug,
      Matrix_Unique_ID,
      Address,
      City,
      StateOrProvince,
      PostalCode,
      url_site
    });
  }
  firtNameRef = React.createRef();
  setFocus = () => {
    this.firtNameRef.current.input.focus();
  };
  onSubmit = (err, data) => {
    const self = this;

    let {
      firstname_contact,
      lastname_contact,
      phone_contact,
      email_contact,
      message_contact
    } = data;
    if (
      typeof firstname_contact === "undefined" ||
      typeof lastname_contact === "undefined" ||
      typeof phone_contact === "undefined" ||
      typeof email_contact === "undefined" ||
      typeof message_contact === "undefined"
    )
      return message.error("Some fields are required.");

    if (err) return message.error(err);
    /* data = Object.keys(data).map(key => {
      return `${key}=${data[key]}&`;
    }).join(""); */
    console.log(Helper.parseFormData(data));

    let msg = `<div>
        <h2>Contact</h2>
        <ul>
          <li><strong>Name:</strong> ${firstname_contact}</li>
          <li><strong>LastName:</strong> ${lastname_contact}</li>
          <li><strong>Phone: </strong>${phone_contact}</li>
          <li><strong>Email: </strong>${email_contact}</li>
        </ul>
        <div>
          <p>
            ${message_contact}
          </p>
        </div>
      </div>
      `;
    let params = {
      number: "13053362403",
      //number: "573042052067",
      name: `${firstname_contact} ${lastname_contact}`,
      phone: phone_contact,
      email: email_contact,
      message: `Contact:\n
        Name: ${firstname_contact}\n
        LastName: ${lastname_contact}\n
        Phone: ${phone_contact}\n
        Email: ${email_contact}\n
        ${message_contact}
      `,
      to: "okiloco2@gmail.com",
      name: "Bruno Linares",
      subject: `Goonew ${firstname_contact} ${lastname_contact}`,
      text: `${message_contact} ${firstname_contact} ${lastname_contact} from:  ${phone_contact} - ${email_contact}`,
      html: msg
    };

    send_email(params)
      .then(({ msg }) => {
        self.setState({
          msg,
          buttonText: "Sent"
        });
      })
      .catch(err => {
        self.setState({
          msg: err.message
        });
      });

    /*  $.ajax({
      type: "GET",
      url: "https://api.gooneworld.com/api/Properties/sendEmail",
      data: params,
      dataType: "json",
      success: function(datos) {
        params["subject"] = "Gooneworld";
        $.ajax({
          type: "GET",
          url: "https://api.gooneworld.com/api/Properties/sendSMS",
          data: params,
          dataType: "json",
          success: function(datos) {
            message.info("Message sent successfully");
            self.setState({
              msg: "Message sent successfully"
            });
            if (self.props.onSubmit)
              self.props.onSubmit(null, {
                message: "Message sent successfully"
              });
          }
        });
      }
    }); */
  };

  render() {
    let {
      firstname_contact,
      lastname_contact,
      email_contact,
      phone_contact,
      message_contact,
      Slug,
      Matrix_Unique_ID,
      Address,
      url_site,
      msg,
      buttonText
    } = this.state;

    let { data } = this.props;
    let {
      ListAgentDirectPhone,
      AgentsOfficeExtension,
      ListAgentFullName,
      City,
      PostalCode,
      StateOrProvince,
      StandardStatus
    } = data;

    let { message_send, address_listing, showAvatar = true } = this.props;
    let description = this.props.description || true;
    if (address_listing)
      message_contact = `${this.props.description || "I am interested in your listing"} ${address_listing.toUpperCase()}  ${City}, ${StateOrProvince} ${PostalCode}`;

    return (
      <AdvancedForm
        formLayout="vertical"
        className={this.props.className}
        onSubmit={this.onSubmit}
        style={this.props.style}
        autoSubmit={false}
        footer={
          <Row type="flex" justify="center" align="middle" style={{ width: "100%" }}>
            <Col>
              {msg && <span className="text-orange">{msg}</span>}
              <Button
                size="large"
                className="btn-submit"
                htmlType="submit"
                style={{ width: "100%", marginTop: 5 }}
              >
                {buttonText}
              </Button>
              <ButtomTerm />
            </Col>
          </Row>
        }
      >
        <div
          style={{
            width: "100%"
          }}
        >
          {showAvatar && (
            <div
              style={{
                width: "100%"
              }}
            >
              <Row style={{ width: "100%", margin: "0px", padding: "0px" }}>
                <Card
                  type="inner"
                  bordered={false}
                  className="info-agent"
                /* style={{ width: 250 }} */
                >
                  <Meta
                    avatar={
                      <Avatar
                        className="avatar-contact-form"
                        size="large"
                        src="/images/avatar.jpg"
                      />
                    }
                    title={"Bruno Linares"}
                    description={`(305) 336.2403`}
                  />
                </Card>
              </Row>
            </div>
          )}
          {showAvatar && (
            <div
              style={{ width: "100%", padding: "0px 5px", margin: "0px 5px" }}
            >
              <p
                style={{
                  fontSize: 15,
                  padding: "0px 1px",
                  margin: 0,
                  lineHeight: 1
                }}
              >
                {StandardStatus == "Active" ? `I would like to give you more information about this home and
                schedule a tour. Contact me now.`: null}
              </p>
            </div>
          )}
          <Input
            size="large"
            style={{ width: "50%" }}
            ref={this.firtNameRef}
            placeholder="First Name"
            name="firstname_contact"
          /* validator={[
            { required: true, message: "Please input your First Name!" }
          ]} */
          />
          {
            <Input
              size="large"
              style={{ width: "50%" }}
              placeholder="Last Name"
              name="lastname_contact"
            /* validator={[
              { required: true, message: "Please input your Last Name!" }
            ]} */
            />
          }
          <Input
            size="large"
            placeholder="Phone number"
            style={{ width: "100%" }}
            name="phone_contact"
          /* validator={[
            { required: true, message: "Please input your Phone number!" }
          ]} */
          />

          <Input
            size="large"
            placeholder="Email Address"
            name="email_contact"
            style={{ width: "100%" }}
          /* validator={[
            {
              required: true,
              message: "Please input your Email Address!"
            }
          ]} */
          />
          <TextArea
            size="large"
            style={{ width: "100%", height: 100 }}
            placeholder={`Input your message.`}
            autosize={{ minRows: 2, maxRows: 8 }}
            name="message_contact"
            initial={message_contact}
          /* validator={[
            { required: true, message: "Please input your Message!" }
          ]} */
          />
        </div>
      </AdvancedForm>
    );
  }
}
export default ContactForm;
