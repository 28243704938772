import React, { Component, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../redux/actions/action_search";
import LayoutDetail from "../layouts/layoutdetails";
import Widget from "../components/com/widget/Widget";
import qs from "qs";
import { find_lat_lng, getService } from "../services";
/* import Carousel from "../components/com/carousel/MyCarousel"; */
import Constants from "../utils/constants";
import { URL_COMING_SOON } from "../utils/constants";
import {
  Button,
  Tooltip,
  Collapse,
  Col,
  Icon,
  Row,
  Affix,
  Layout,
  Table,
  Slider,
  Input,
  InputNumber,
  Progress,
  Badge,
  Tag,
  Modal,
  BackTop,
  Breadcrumb,
  Carousel,
  message,
  Avatar,
  Popover,
  Rate,
} from "antd";
import {
  get_photos_by_slug,
  save_search,
  get_walk_score,
  get_transit_score,
  get_schools,
  get_walkscores,
  get_transit_scores,
  get_nearby_schools,
  save_listing_search,
} from "../services";

import "./listingdetails.css";
import ContactForm from "../components/com/contactform/ContactForm";
import { DropDownMenu } from "../components/com";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Map from "../components/com/Map/Map";
import axios from "axios";
import { Select } from "antd";

import {
  get_property_detail,
  get_property_history,
  get_property_closed,
} from "../services";

import ImageLoader from "react-load-image";
import Preloader from "../components/com/preloading/Preloader";
import MetaTags from "react-meta-tags";
import Helper from "../utils/Helper";
/* import moment from "moment"; */

import WidgetCard from "../components/widgets/WidgetCard";
import { async } from "q";
import { propertyHistoryService } from "../services/services";

const status = ["Active", "Pending", "ActiveUnderContract"];
const { CamelCaseSplit, moment } = Helper;
const format = "MM-DD-YYYY";
const { confirm } = Modal;
const { Panel } = Collapse;
const customPanelStyle = {
  background: "#f7f7f7",
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: "hidden",
};

/* const URL_COMING_SOON = require("../images/coming_soon.png"); */

const house = require("../images/house.svg");
const ButtonGroup = Button.Group;
/* Galery */
const ImageError = () => (
  <div
    className="cover-card coming-soon"
    alt="example"
    style={{
      backgroundImage: `url(${URL_COMING_SOON})`,
      width: "100%",
      height: "100%",
      backgroundSize: "cover",
      backgroundPosition: "center",
    }}
  />
);
const BackgroundImage = ({ src, style = {}, ...props } = {}) => (
  <div
    style={Object.assign({ backgroundImage: `url(${src})` }, style)}
    {...props}
  />
);
const Image = (props) => (
  <ImageLoader {...props}>
    <BackgroundImage
      className="cover-card"
      style={{
        width: "100%",
        height: "200px",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    />
    <div
      style={{
        width: "100%",
        height: "210px",
        background: "#fafafa",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        position: "relative",
      }}
    >
      <ImageError />
    </div>

    <div
      style={{
        backgroundImage: house,
        width: "100%",
        height: "100%",
        background: "#fafafa",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Preloader />
    </div>
  </ImageLoader>
);
/* Galery */
const AGENT_PHONE = "305.336.2403";
const cleanCommas = (value) => {
  return value.replace(/\,/g, ", ");
};
const { money } = Helper;
const { Option } = Select;

const styleCol = {
  padding: "0px 10px",
};
//<Carousel dataSource={dataSource} />
const Years = (props) => (
  <Select
    className="select-primary"
    showSearch={false}
    style={{ width: 200 }}
    placeholder="Select a year"
    optionFilterProp="children"
    defaultValue={"30"}
    onChange={props.onChange}
  >
    <Option value="30">30-year-fixed</Option>
    <Option value="20">20-year-fixed</Option>
    <Option value="15">15-year-fixed</Option>
    <Option value="10">10-year-fixed</Option>
  </Select>
);
const WrapperForm = ({ children }) => {
  return !isMobile ? (
    <Affix className="form-contact-affix" top={160}>
      {children}
    </Affix>
  ) : (
    <div>{children}</div>
  );
};
const style = {
  display: "inline-block",
  marginTop: "0px",
  verticalAlign: "middle",
  //height: 300,
  width: "100%",
  marginLeft: 15,
  marginBottom: 8,
};
const InputGroup = Input.Group;
const CalculateForm = (props) => {
  console.log("props.percent_down_payment", props.percent_down_payment);

  let percent_down_payment =
    props.percent_down_payment == 0 ? 5 : props.percent_down_payment;
  let calc_price =
    Number(props.price_home) -
    (Number(percent_down_payment) / 100) * Number(props.price_home);

  calc_price = calc_price === 0 ? props.price_home : calc_price;
  return (
    <Row>
      <Col span={24}>
        <Row
          className="container-mortgage-fields"
          type="flex"
          justify="space-between"
          gutter={8}
        >
          <Col span={8}>
            <div style={style}>
              <span>
                <h3>
                  <strong>Loan Amount</strong>
                </h3>
              </span>
              <InputNumber
                size="large"
                min={1}
                max={props.price_home}
                /*  max={100} */
                style={{
                  background: "#fff",
                }}
                initial={props.price_home}
                defaultValue={props.price_home}
                disabled={true}
                formatter={(value) => {
                  return `${money(value)}`;
                }}
                style={{ marginLeft: 16, width: "100%" }}
                value={calc_price}
              />
            </div>
          </Col>
          <Col span={8}>
            <div style={style}>
              <span>
                <h3>
                  <strong>Down Payment</strong>
                </h3>
              </span>
              <div
                style={{
                  display: "inline-block",
                  marginLeft: 10,
                  width: "calc(100% - 20px)",
                  verticalAlign: "middle",
                }}
              >
                <InputNumber
                  size="large"
                  style={{ width: "100%" }}
                  disabled={true}
                  defaultValue={50}
                  initial={50}
                  value={percent_down_payment}
                  min={0}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace("%", "")}
                />
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div style={style}>
              <span>
                <h3>
                  <strong>Interest Rate</strong>
                </h3>
              </span>
              <InputNumber
                size="large"
                min={1}
                style={{
                  background: "#fff",
                }}
                disabled={true}
                formatter={(value) => {
                  return `${props.rate_in}%`;
                }}
                style={{ marginLeft: 16, width: "100%" }}
                value={props.rate_in}
              />
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row className="slide-bar" type="flex" gutter={45}>
          <Col style={{ ...styleCol, marginTop: "15px!important" }} span={8}>
            <Slider
              min={1}
              max={props.price_home}
              tooltipVisible={false}
              onChange={(value) => {
                props.onChange(value, "price_home");
              }}
              value={calc_price}
            />
          </Col>
          <Col style={styleCol} span={8}>
            <Slider
              tooltipVisible={false}
              min={0}
              max={75}
              step={5}
              onChange={(value) =>
                props.onChange(value, "percent_down_payment")
              }
              value={percent_down_payment}
            />
          </Col>
          <Col style={styleCol} span={8}>
            <Slider
              tooltipVisible={false}
              min={0}
              max={6.5}
              step={0.25}
              onChange={(value) => props.onChange(value, "rate_in")}
              value={props.rate_in}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row type="flex" justify="center">
          <Col xs={24} sm={12}>
            <div style={style}>
              <span
                style={{
                  marginRight: "8px",
                }}
              >
                <strong
                  style={{
                    whiteSpace: "pre",
                  }}
                >
                  Loan Type
                </strong>
              </span>
              <Years
                size="large"
                onChange={(value) => props.onChange(value, "years")}
              />
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <Row className="fotter-buttons" type="flex" justify="end">
              <Button type="primary" size="large" onClick={props.onSubmit}>
                Prequalified
              </Button>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
const Arrow = ({ direction, onClick, glyph }) => (
  <div className={`slide-arrow ${direction}`} onClick={onClick}>
    <Icon type={direction} />
  </div>
);
const Cover = (props) => {
  let { data } = props;
  const [dataSource, setDataSource] = useState([URL_COMING_SOON]);
  const [show, setShow] = useState(true);
  let {
    StreetNumber,
    StreetDirPrefix,
    StreetName,
    UnitNumber,
    City,
    StateOrProvince,
    PostalCode,
    PublicRemarks,
    ListPrice,
    SpecialListingConditions,
  } = data;
  let address = "";
  let allowSpecials = [
    "RealEstateOwned",
    "ShortSale",
    "HudOwned",
    "Auction",
    "NewListing",
  ];
  UnitNumber = UnitNumber != undefined ? `#${UnitNumber}` : "";
  var adress_dir = [
    StreetNumber,
    StreetDirPrefix,
    StreetName,
    UnitNumber,
    City,
    StateOrProvince,
    PostalCode,
  ];
  address = adress_dir.join(" ");
  address = address.replace(/(null|undefined)+/g, " ");
  /* let show = props.show || true; */
  let className = props.className || "cover-photo";

  useEffect(() => {
    if (props.dataSource && props.dataSource.length > 0) {
      setDataSource(props.dataSource);
    }
    /* setShow(props.dataSource && props.dataSource.length > 0); */
  }, [props.dataSource]);
  useEffect(() => {
    if (typeof props.show !== "undefined") setShow(props.show);
  }, [props.show]);
  return (
    <div>
      <MetaTags>
        <title>{`.:: GooneWorld ${address}`}</title>
        <meta name="description" content={PublicRemarks} />
        <meta property="og:title" content={address} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={PublicRemarks} />
        <meta
          property="og:image"
          content={dataSource[props.currentImageIndex]}
        />
        <meta
          property="og:image:secure_url"
          content={dataSource[props.currentImageIndex]}
        />
      </MetaTags>
      <div>
        <div className="cover-card-photo">
          {dataSource.length > 0 && (
            <Arrow
              direction="left"
              onClick={() => props.previousSlide()}
              glyph="&#9664;"
            />
          )}
          <div>
            {
              <Image
                className={className}
                src={dataSource[props.currentImageIndex]}
              />
            }
          </div>
          {dataSource.length > 0 && (
            <Arrow
              direction="right"
              onClick={() => {
                props.nextSlide();
              }}
              glyph="&#9654;"
            />
          )}
        </div>
      </div>
      <div className="cover-tools">
        <div className="tag-cover">
          <Tag>{props.type_property_desc}</Tag>
          {SpecialListingConditions && (
            <>
              {SpecialListingConditions.split(",")
                .filter((it) => allowSpecials.includes(it))
                .map((tag) => (
                  <Tag className="red-tag">{CamelCaseSplit(tag)}</Tag>
                ))}
            </>
          )}
        </div>
        <div style={{ float: "right" }}>
          <Button size="large" className="btn-tools" onClick={props.handleSave}>
            <Icon type="heart" />
            Save
          </Button>
        </div>
        <div style={{ float: "right" }}>
          <DropDownMenu
            className="btn-tools"
            icon="share-alt"
            label="Share"
            menuStyle={{
              width: "auto",
            }}
          >
            <Row type="flex" justify="center">
              <Col
                span={24}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  justifyItems: "center",
                }}
              >
                <ButtonGroup size="large">
                  <Button
                    className="btn-social btn-facebook"
                    onClick={() => props.handleSocialMedia("facebook")}
                  >
                    <FontAwesomeIcon
                      className="icon-social"
                      icon={["fab", "facebook-f"]}
                    />
                  </Button>
                  <Button
                    className="btn-social btn-twitter"
                    onClick={() => props.handleSocialMedia("twitter")}
                  >
                    <FontAwesomeIcon
                      className="icon-social"
                      icon={["fab", "twitter"]}
                    />
                  </Button>
                  <Button
                    className="btn-social btn-whatsapp"
                    onClick={() => props.handleSocialMedia("whatsapp")}
                  >
                    <FontAwesomeIcon
                      className="icon-social"
                      icon={["fab", "whatsapp"]}
                    />
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </DropDownMenu>
        </div>
      </div>
      <div className="cover-footer">
        {props.count > 0 && show && <Carousel dataSource={dataSource} />}
        {props.count > 0 && (
          <div className="footer-tools">
            <Button icon={"file-image"} className="btn-tools btn-dark">
              {props.currentImageIndex + 1} of {props.count}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
const Field = (props) =>
  props.value ? (
    <li className="property-item">
      <Icon type="check" />{" "}
      <h3>
        <strong>{props.label}:</strong>{" "}
      </h3>
      <span className="property-value">{props.value}</span>
    </li>
  ) : null;
const WrapperHeader = ({ children }) => {
  return isMobile ? <Affix>{children}</Affix> : null;
};
const Head = (props) => (
  <Row
    style={{ padding: "0px", lineHeight: 1 }}
    className="head-detail-listing"
  >
    <Col xs={24} sm={18} className="header-detail">
      <h2>
        {typeof props.address_listing !== "undefined"
          ? props.address_listing
          : ""}
      </h2>
      <h3
        style={{
          marginBottom: "0px",
        }}
      >
        {props.City ? props.City : ""}
        {props.StateOrProvince ? ` ${props.StateOrProvince}` : ""}
        {props.PostalCode ? `, ${props.PostalCode}` : ""}
      </h3>
      <div className="property-icons">
        {typeof props.BedroomsTotal !== "undefined" &&
        Number(props.BedroomsTotal) > 0 ? (
          <small className="item-property">
            <FontAwesomeIcon
              className="icon-fa"
              size="lg"
              icon={["fas", "bed"]}
            />{" "}
            <span>
              {props.BedroomsTotal}{" "}
              {Number(props.BedroomsTotal) > 1 ? "Beds" : "Bed"}
            </span>
          </small>
        ) : null}
        {Number(props.BathroomsFull) > 0 && (
          <small
            className={
              typeof props.BedroomsTotal !== "undefined" &&
              props.BedroomsTotal != 0
                ? "item-property divider-left"
                : "item-property"
            }
          >
            <FontAwesomeIcon
              className="icon-fa"
              size="lg"
              icon={["fas", "bath"]}
            />{" "}
            <span>
              {props.BathroomsFull}{" "}
              {Number(props.BathroomsFull) > 1 ? "Baths" : "Bath"}
            </span>
          </small>
        )}
        {Number(props.BathroomsHalf) > 0 && (
          <small
            className={
              props.BathroomsHalf
                ? "item-property divider-left"
                : "item-property"
            }
          >
            <FontAwesomeIcon
              className="icon-fa"
              size="lg"
              icon={["fas", "bath"]}
            />{" "}
            <span>
              {props.BathroomsHalf}{" "}
              {Number(props.BathroomsHalf) > 1 ? "Halves" : "Half"}
            </span>
          </small>
        )}
        {Number(props.LivingArea) > 0 && (
          <small
            className={
              props.BedroomsTotal
                ? "item-property divider-left"
                : "item-property"
            }
          >
            <FontAwesomeIcon
              className="icon-fa"
              size="lg"
              icon={["fas", "ruler"]}
            />{" "}
            <span>
              {props.LivingArea} {"Sqft"}
            </span>
          </small>
        )}
      </div>
    </Col>
    {
      <Col xs={24} sm={6} className="header-detail-price">
        <Row>
          <Col xs={12} sm={24}>
            <h2>{money(props.ListPrice, ".")}</h2>
          </Col>
          {
            <Col xs={12} sm={24}>
              <div>
                {props.StandardStatus === "Active" &&
                  props.mortgage &&
                  props.PropertyType === "Residential" && (
                    <small>
                      Est. Mortgage{" "}
                      <strong
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {money(props.mortgage)}/mo
                      </strong>
                    </small>
                  )}
              </div>
              {props.StandardStatus === "Active" &&
                props.PropertyType === "Residential" && (
                  <div
                    style={{
                      width: "100%",
                      margin: "8px 0px",
                      height: "40px",
                      padding: "5px",
                      margin: "4px 0px",
                      float: "right",
                    }}
                  >
                    <Button
                      type="primary"
                      className="pre-qualified"
                      style={{
                        color: "#fff",
                        float: "right",
                      }}
                    >
                      <a
                        target="_blank"
                        href="https://www.klickmortgage.com/apply-now"
                      >
                        Get Pre-Qualified
                      </a>
                    </Button>
                  </div>
                )}
              {props.StandardStatus === "Closed" && (
                <div
                  style={{
                    width: "100%",
                    margin: "8px 0px",
                    height: "40px",
                    padding: "5px",
                    margin: "4px 0px",
                    float: "right",
                  }}
                >
                  <Button
                    type="primary"
                    className="btn-orange"
                    size="large"
                    style={{
                      color: "#fff",
                      backgroundColor: "rgb(220, 83, 83)!important",
                      float: "right",
                      fontSize: "24px",
                      textTransform: "uppercase",
                    }}
                  >
                    <strong>{props.StandardStatus}</strong>
                  </Button>
                </div>
              )}
              {status.includes(props.StandardStatus) && (
                <div>
                  {props.PropertyType === "ResidentialLease" && (
                    <small>
                      Per Month{" "}
                      <strong
                        style={{
                          fontSize: 12,
                        }}
                      />
                    </small>
                  )}
                </div>
              )}
            </Col>
          }
        </Row>
      </Col>
    }
  </Row>
);
const { Header, Content } = Layout;

/* 
ListPrice,
ClosePrice,
ListPriceLow,
OriginalListPrice,
PreviousListPrice, and
PriceChangeTimestamp

Status,	Date,	Price,	Change, StandardStatus	Source
*/
const columns = [
  {
    title: "Status",
    dataIndex: "StandardStatus",
    key: "StandardStatus",
    render: (text) => {
      return (
        <span
          style={{
            color: "var(--color-gray-darken-1)",
          }}
        >
          {text}
        </span>
      );
    },
  },
  {
    title: "Date",
    dataIndex: "PriceChangeTimestamp",
    key: "PriceChangeTimestamp",
    render: (text) => {
      return (
        <span
          style={{
            color: "var(--color-gray-darken-1)",
          }}
        >
          {moment(text).format("MMMM D [,] YYYY")}
        </span>
      );
    },
  },
  {
    title: "Price",
    dataIndex: "PreviousListPrice",
    key: "PreviousListPrice",
    render: (text) => {
      return (
        <span
          style={{
            color: "var(--color-gray-darken-1)",
          }}
        >
          {money(text)}
        </span>
      );
    },
  },
  {
    title: "Source",
    dataIndex: "ListingId",
    key: "ListingId",
    render: (text) => {
      return (
        <span
          style={{
            color: "var(--color-gray-darken-1)",
          }}
        >
          #{text}
        </span>
      );
    },
  },

  /* {
    title: "size_sqft",
    dataIndex: "size_sqft",
    key: "size_sqft",
    render: text => {
      return (
        <small>
          <FontAwesomeIcon
            className="icon-fa"
            size="lg"
            icon={["fas", "ruler"]}
          />{" "}
          {text}
        </small>
      );
    }
  } */
];
const calculatePayment = ($price, $down, $term, $rate_in) => {
  /* $price = 450000;
  $apr = 50;
  $term = 30;
  $rate_in = 2.75; */
  let $amt = $price - $price * ($down / 100);
  $rate_in /= 1200;
  let $month = Number($term) * 12;
  var $payment =
    ($amt * ($rate_in * Math.pow(1 + $rate_in, $month))) /
    (Math.pow(1 + $rate_in, $month) - 1);
  /*  let $loan = $price - $down;
  console.log($price, $down, $term, $loan);
  let $rate = $rate_in / 100 / 12;
  let $month = $term * 12;
  let $payment =
    Math.floor(
      (($loan * $rate) / (1 - Math.pow(1 + $rate, -1 * $month))) * 100
    ) / 100; */
  return $payment.toFixed(2);
};
class ListingDetails extends Component {
  state = {
    visible: false,
    loading: true,
    loadingHistory: true,
    loadingMortgage: true,
    more_detail: false,
    data: {},
    geo: {},
    dataSource: [],
    priceHistory: [],
    Slug: "",
    Matrix_Unique_ID: "",
    ListingKeyNumeric: "",
    PhotosCount: 0,
    mortgage: "",

    address_listing: "",
    down_payment: 40,
    percent_down_payment: 0,
    rate_in: 3.875,
    years: 30,
    price_home: 0,

    type_property_desc: "",
    type_property: "",
    show: false,

    show_tooltip: true,

    currentImageIndex: 0,
    currentCls: "cover-photo",

    walkscore: {},
    transitscore: {},
    schools: [],
  };

  showModal = () => {
    this.setState({
      visible: true,
      show_tooltip: !this.state.show_tooltip,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  previousSlide = () => {
    const lastIndex = this.state.dataSource.length - 1;
    const { currentImageIndex } = this.state;
    const shouldResetIndex = currentImageIndex === 0;
    const index = shouldResetIndex ? lastIndex : currentImageIndex - 1;

    this.setState({
      currentImageIndex: index,
    });
  };
  handleSave = () => {
    let { search, history, location } = this.props;

    if (!localStorage.userId)
      return confirm({
        width: 300,
        title: <strong>Do You Like This Listing?</strong>,
        content: "Sign In to Save It",
        onOk() {
          window.previousLocation = location;
          history.push("/login");
        },
      });
    if (!search) search = {};
    let data = search.data || {};
    const params = this.props.match.params || {};
    let { transaction_type } = params;
    params["userId"] = localStorage.userId;
    params["hostApp"] = "GOW";
    params["PropertyType"] = transaction_type;

    /* Query String */
    let query = location.search || "";
    let values = qs.parse(query.replace("?", ""));
    params["url"] = `${window.location.pathname}${
      window.location.search || ""
    }`;

    for (var key in values) {
      params[key] = values[key];
    }

    return save_listing_search(params)
      .then((response) => {
        let { data } = response;
        console.log("Saved!", data);
        message.info("Saved successfully!");
      })
      .catch((err) => message.error(err.message));
    /* save_search(params)
      .then(response => {
        let { data } = response;
        console.log("Saved!", data);
        message.info("Saved successfully!");
      })
      .catch(err => message.error(err.message)); */
  };
  nextSlide = () => {
    const lastIndex = this.state.dataSource.length - 1;
    const { currentImageIndex } = this.state;
    const shouldResetIndex = currentImageIndex === lastIndex;
    const index = shouldResetIndex ? 0 : currentImageIndex + 1;
    console.log(index);
    this.setState({
      currentImageIndex: index,
    });
  };

  requestListing = async () => {
    let { match, history } = this.props;
    let { Slug, ListingKeyNumeric, StandardStatus, ListingId } = match.params;
    let { down_payment, percent_down_payment, rate_in, years } = this.state;
    const service = getService("expired-listings");
    let property;

    this.setState({
      loading: true,
    });

    if (!ListingId) {
      let [resp] = await Promise.all([
        StandardStatus === "Closed"
          ? get_property_closed(ListingKeyNumeric)
          : get_property_detail({
              Slug,
              ListingKeyNumeric,
            }),
      ]);
      property = resp;
      if (!property) {
        const [result] = await Promise.all([
          service.find({
            query: {
              ListingKeyNumeric,
              MlsStatus: StandardStatus,
            },
          }),
        ]);
        if (result.data) property = result.data[0];
      }
    } else {
      const [response] = await Promise.all([
        get_property_detail({
          _id: ListingId,
        }),
      ]);

      if (!response) {
        const [resp] = await Promise.all([
          service
            .find({
              query: {
                _id: ListingId,
                StandardStatus: { $ne: "Active" },
              },
            })
            .then(({ data = [] }) => data[0]),
        ]);
        property = resp;
      } else {
        property = response;
      }
    }
    /* this.setState({
      loading: false,
      data: property
    }); */
    if (property) {
      let {
        StreetNumber,
        StreetDirPrefix,
        StreetName,
        UnitNumber,
        City,
        StateOrProvince,
        PhotosCount,
        ListingType,
        PropertyType,
      } = property;
      const ext = ListingType == "GOW" ? "jpeg" : "jpg";
      if (!ListingKeyNumeric) ListingKeyNumeric = property["ListingKeyNumeric"];
      if (!ListingKeyNumeric) ListingKeyNumeric = property["ListingKeyNumeric"];
      localStorage.setItem(
        "transaction_type",
        PropertyType === "Residential" ? "Sale" : "Rent"
      );
      /* get images */
      let count = property.PhotosCount;
      this.calculate_mortgage(null, {
        price_home: property.ListPrice,
        down_payment,
        percent_down_payment,
        rate_in,
        years,
      });
      let address = "";
      UnitNumber =
        typeof UnitNumber !== "undefined" &&
        UnitNumber != 0 &&
        UnitNumber != null
          ? `#${UnitNumber}`
          : "";
      var adress_dir = [StreetNumber, StreetDirPrefix, StreetName, UnitNumber];
      address = adress_dir.join(" ");
      address = address.replace(/(null|undefined)+/g, " ");

      var address_show = address.split(",")[0].toUpperCase();
      var address_listing = address
        ? address_show.substr(0, 24).replace(/(NULL)+/g, " ")
        : "";
      //alert(address_listing);
      let images = Array.apply(null, { length: PhotosCount }).map(
        (item, index) => {
          return `${Constants.URL_API_PHOTOS}${ListingKeyNumeric}/${index}.${ext}`;
        }
      );

      this.setState({
        loading: false,
        dataSource: images,
        price_home: property.ListPrice,
        data: property,
        address_listing,
        PhotosCount,
        loadingHistory: false,
        geo: property.Latitude
          ? {
              Latitude: property.Latitude,
              Longitude: property.Longitude,
            }
          : undefined,
      });

      this.getWalkScore();
      const [priceHistory] = await Promise.all([
        get_property_history({
          Slug,
        }),
      ]);
      console.log("PRICE HS:", priceHistory);
      this.setState({
        priceHistory: priceHistory || [],
      });
      /*
      this.getTransitScore();
      this.getSchools(); */
    }
  };
  componentWillReceiveProps(nextProps, props) {
    if (nextProps.location) {
      this.requestListing();
    }
  }
  componentDidMount() {
    let { match } = this.props;
    let { Slug, ListingKeyNumeric, StandardStatus } = match.params;

    this.setState({
      Slug,
      ListingKeyNumeric,
    });
    console.log(this.props.search, this.props);

    const self = this;

    if (!isMobile)
      this.interval = setInterval(() => {
        self.setState({
          show_tooltip: !self.state.show_tooltip,
        });
      }, 10000);

    this.requestListing();
    window.scrollTo(0, 0);
    /* this.loadPriceHistory(); */
  }
  getWalkScore = async () => {
    let { data, address_listing } = this.state;
    let { match } = this.props;
    let { Slug, ListingKeyNumeric, StandardStatus } = match.params;

    let { formattedAddress, Geocode, City, StateOrProvince, _id } = data;
    if (typeof Geocode === "undefined") {
      find_lat_lng({
        id: _id,
        StandardStatus,
      })
        .then(async (Geocode) => {
          if (Geocode) {
            this.setState({
              data: { ...this.state.data, Geocode },
            });
            let { latitude, longitude } = Geocode;
            /* alert(latitude + "/" + longitude); */

            const [walkscore, transitscore, schools] = await Promise.all([
              get_walkscores({
                address: formattedAddress || address_listing,
                lat: latitude,
                lon: longitude,
              }),
              get_transit_scores({
                address: formattedAddress || address_listing,
                lat: latitude,
                lon: longitude,
                city: City,
                state: StateOrProvince,
              }),
              get_nearby_schools({
                address: formattedAddress || address_listing,
                lat: latitude,
                lon: longitude,
                city: City,
                state: StateOrProvince,
              }),
            ]);

            //return alert(JSON.stringify(schools));
            this.setState({
              walkscore,
              transitscore,
              schools,
            });
          }
        })
        .catch((err) => console.log("ERR: ", err));
    }
  };
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  contactForm = React.createRef();
  contactFormContainer = React.createRef();

  handleSubmit = (err, data) => {
    console.log("onSubmit: ", err, data);
  };
  calculate_mortgage = (err, data = {}) => {
    //var formElement = document.getElementById("frm_mortgage");
    //var data = this.FormDataToJSON(formElement);
    if (err) return;
    data["do"] = "calcula_mort";

    /* 
      do: "calcula_mort"
      down_payment: 40
      percent_down_payment: 20
      price_home: 18584
      rate_in: 4.5
      years: 30

    */
    console.log("---->", data);
    let { price_home, percent_down_payment, years, rate_in } = data;

    percent_down_payment = percent_down_payment == 0 ? 5 : percent_down_payment;

    let payment = calculatePayment(
      price_home,
      percent_down_payment,
      years,
      rate_in
    );
    //$price, $down, $term, $rate_in;
    this.setState({
      mortgage: payment,
    });
    /* axios
      .get("https://gooneworld.com/func/calculate_mortgage.php", {
        params: data
      })
      .then(res => {
        let { data } = res;

        if (data !== "Please Complete Mortgage inputs")
          if (Number(data))
            this.setState({
              mortgage: data,
              loadingMortgage: false
            });
        //console.log("->Mortgage: ", data);
      })
      .catch(err => {
        console.error(err);
      }); */
  };
  gotoContactForm = () => {
    var form = ReactDOM.findDOMNode(this.contactFormContainer.current);
    console.log(form, form.offsetTop);
    window.scrollTo(0, form.offsetTop - 100);
    if (this.contactForm.current.setFocus) this.contactForm.current.setFocus();
  };
  handleSocialMedia = (type) => {
    //let href = window.location.href;
    let { data } = this.state;
    let { match, location } = this.props;
    let { transaction_type, StateOrProvince, City, Slug, ListingKeyNumeric } =
      match.params;

    let href = `/listings/search/${City}/${StateOrProvince}/${transaction_type}/${Slug}/${ListingKeyNumeric}`;

    let title = this.getTitle();

    let redes = {
      facebook: {
        url: `https://www.facebook.com/sharer.php?u=${href}`,
      },
      twitter: {
        url: `https://twitter.com/share?url=${href}&text=${title}`,
      },
      whatsapp: {
        url: `https://wa.me/?text=${href}`,
      },
    };

    let { url } = redes[type];
    window.open(url, "_blank");
    console.log("Red Social!", url);
  };
  getTitle = () => {
    let { data } = this.state;
    let {
      StreetNumber,
      StreetDirPrefix,
      StreetName,
      UnitNumber,
      City,
      StateOrProvince,
      PostalCode,
    } = data;
    let address = "";
    UnitNumber = UnitNumber != undefined ? `#${UnitNumber}` : "";
    var adress_dir = [
      StreetNumber,
      StreetDirPrefix,
      StreetName,
      UnitNumber,
      City,
      StateOrProvince,
      PostalCode,
    ];
    address = adress_dir.join(" ");
    address = address.replace(/(null|undefined)+/g, " ");
    return address;
  };
  render() {
    let {
      PhotosCount,
      Matrix_Unique_ID,
      ListingKeyNumeric,
      data,
      loading,
      dataSource,
      priceHistory,
      more_detail,
      loadingHistory,

      mortgage,
      down_payment,
      percent_down_payment,
      rate_in,
      years,
      price_home,

      loadingMortgage,
      type_property_desc,
      type_property,

      show_tooltip,
      walkscore,
      transitscore,
      schools,
    } = this.state;
    let address = "";
    let {
      StreetNumber,
      StreetDirPrefix,
      StreetName,
      UnitNumber,
      City,
      StateOrProvince,
      // transaction_type,
      Slug,
      StyleofProperty,
      TypeofProperty,
      ListPrice,
      AddressInternetDisplay,

      PublicRemarks,
      StandardStatus,
      PropertySubType,
      YearBuilt,
      LivingArea,

      HOA,
      Parking,
      Cooling,
      Heating,

      //Price/sqft,
      Pool,
      SubdivisionName,
      ListingId,

      CommunityFeatures,
      Furnished,
      Appliances,
      InteriorFeatures,
      ExteriorFeatures,
      Flooring,
      LotSizeSquareFeet,
      PropertyType,
      PostalCode,
      CountyOrParish,
      DaysOnMarket,
      YearBuiltDetails,
      SpecialListingConditions,
      PetsAllowed,
      Geocode,
      ModificationTimestamp,
      OriginalEntryTimestamp,
      StatusChangeTimestamp,
      Latitude,
      Longitude,
      createdAt,
    } = data;
    type_property_desc =
      PropertyType === "Residential" ? "For Sale" : "For Rent";
    type_property = PropertyType === "Residential" ? "Sale" : "Rent";

    let { match, location } = this.props;
    let { transaction_type } = match.params;

    if (match.params) {
      StandardStatus = match.params.StandardStatus || StandardStatus;
    }

    transaction_type =
      typeof transaction_type === "undefined"
        ? PropertyType === "Residential"
          ? "Sale"
          : "Lease"
        : transaction_type;
    let query = location.search || "";
    UnitNumber =
      typeof UnitNumber !== "undefined" && UnitNumber != 0 && UnitNumber != null
        ? `#${UnitNumber}`
        : "";
    var adress_dir = [StreetNumber, StreetDirPrefix, StreetName, UnitNumber];
    address = adress_dir.join(" ");
    address = address.replace(/(null|undefined)+/g, " ");

    var address_show = address.split(",")[0].toUpperCase();
    var address_listing = address
      ? address_show.substr(0, 24).replace(/(NULL)+/g, " ")
      : "";
    //console.log("address_listing: ", address_listing);
    var typeprop = "";

    if (StyleofProperty !== "" && StyleofProperty !== "No Data") {
      typeprop = StyleofProperty;
    } else if (TypeofProperty !== "" && TypeofProperty !== "No Data") {
      typeprop = TypeofProperty;
    } else {
      typeprop = "Other";
    }

    let arr_detail = [
      { title: "Community Features", value: CommunityFeatures },
      { title: "Furnished", value: Furnished },
      { title: "Interior Features", value: InteriorFeatures },
      { title: "Appliances", value: Appliances },
      { title: "Exterior  Features", value: ExteriorFeatures },
      { title: "Flooring", value: Flooring },
    ];
    arr_detail = arr_detail
      .map((item) => {
        let { title, value } = item;
        if (value)
          return {
            title,
            value: cleanCommas(value),
          };
        return null;
      })
      .filter((item) => item != null);

    let property_taxes = (Number(ListPrice) * 0.88) / 12 / 100;
    let insurance = (Number(ListPrice) * 0.42) / 12 / 100;
    return (
      <LayoutDetail loading={loading}>
        <Layout>
          <Content className="container-detail">
            <Layout style={{ width: "100%" }}>
              <Row>
                <Col xl={18} lg={24} md={24} xs={24}>
                  <Row name="InfoDetail-row" className="InfoDetail-row">
                    <Col span={24}>
                      <Row>
                        <Col name="header-info" span={24}>
                          <Affix>
                            <div className="call-actions">
                              <Button
                                className="btn-orange btn-call-actions"
                                type="link"
                                size="large"
                              >
                                <a href={`tel:${AGENT_PHONE}`}>Call</a>
                              </Button>
                              <Button
                                className="btn-orange btn-call-actions"
                                type="link"
                                size="large"
                                onClick={this.showModal}
                              >
                                Contact me
                              </Button>
                            </div>
                          </Affix>
                          <Head
                            mortgage={mortgage}
                            {...this.state.data}
                            PostalCode={PostalCode}
                            address_listing={address_listing}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row name="Breadcrumb-row" className="Breadcrumb-row">
                    <Col span={24}>
                      <Breadcrumb
                        className="breadcrumb-details"
                        separator={<Icon type="right" />}
                      >
                        <Breadcrumb.Item>
                          <Icon
                            type="arrow-left"
                            style={{
                              color: "var(--color-gray-darken-1)",
                            }}
                          />
                          <span>
                            <Link to={`/`}>Real State</Link>
                          </span>
                        </Breadcrumb.Item>
                        {StandardStatus === "Closed" && SubdivisionName && (
                          <Breadcrumb.Item>
                            <span>
                              <Link
                                to={`/miami-beach/${SubdivisionName.replace(
                                  /\s/g,
                                  "-"
                                )}`}
                              >
                                {SubdivisionName}
                              </Link>
                            </span>
                          </Breadcrumb.Item>
                        )}
                        {status.includes(StandardStatus) && (
                          <Breadcrumb.Item>
                            <Icon
                              type="arrow-left"
                              style={{
                                color: "var(--color-gray-darken-1)",
                              }}
                            />
                            <span>
                              <Link
                                to={`/listings/search/${City}/${StateOrProvince}/${transaction_type}${query}`}
                              >
                                {City}
                              </Link>
                            </span>
                          </Breadcrumb.Item>
                        )}
                        {status.includes(StandardStatus) && (
                          <Breadcrumb.Item>
                            <Link
                              target="_blank"
                              to={`/listings/search/${City}/${StateOrProvince}/${transaction_type}${query}&PostalCode=${PostalCode}`}
                            >
                              <span>{PostalCode}</span>
                            </Link>
                          </Breadcrumb.Item>
                        )}
                        {status.includes(StandardStatus) && (
                          <Breadcrumb.Item>
                            <Link
                              target="_blank"
                              to={`/listings/search/${City}/${StateOrProvince}/${transaction_type}${query}`}
                            >
                              <span>{StateOrProvince}</span>
                            </Link>
                          </Breadcrumb.Item>
                        )}
                        {status.includes(StandardStatus) && (
                          <Breadcrumb.Item>
                            <Link
                              target="_blank"
                              to={`/listings/search/${City}/${StateOrProvince}/${transaction_type}${query}`}
                            >
                              {`For ${type_property}`}
                            </Link>
                          </Breadcrumb.Item>
                        )}
                        {status.includes(StandardStatus) && (
                          <Breadcrumb.Item>
                            <Link>{address_listing}</Link>
                          </Breadcrumb.Item>
                        )}
                      </Breadcrumb>
                    </Col>
                  </Row>
                  <Row name="row-img">
                    <Col
                      span={24}
                      name="Conte-img"
                      style={{ padding: 0, background: "#fafafa" }}
                    >
                      {status.includes(StandardStatus) && (
                        <Row type="flex" justify="center">
                          <Col span={24}>
                            <Cover
                              data={data}
                              dataSource={dataSource}
                              currentImageIndex={this.state.currentImageIndex}
                              SpecialListingConditions={
                                data["SpecialListingConditions"]
                              }
                              count={PhotosCount}
                              handleSave={this.handleSave}
                              className={this.state.currentCls}
                              show={this.state.show}
                              previousSlide={this.previousSlide}
                              nextSlide={this.nextSlide}
                              handleSocialMedia={(type) => {
                                this.handleSocialMedia(type);
                              }}
                              ShowGalery={(show) =>
                                this.setState({
                                  show: show,
                                })
                              }
                              type_property_desc={type_property_desc}
                              cover={`${Constants.URL_API_PHOTOS}${ListingKeyNumeric}/0.jpg?width=800&height=550`}
                            />
                          </Col>
                        </Row>
                      )}

                      {!status.includes(StandardStatus) && (
                        <Row
                          type="flex"
                          justify="center"
                          style={{
                            height: 400,
                          }}
                        >
                          <Col span={24}>
                            {Longitude && Latitude && (
                              <Map
                                zoom={24}
                                showInfo={false}
                                center={{
                                  lat: Latitude || 0,
                                  lng: Longitude || 0,
                                }}
                                zoom={1}
                                dataSource={[
                                  {
                                    Longitude: Longitude || 0,
                                    Latitude: Latitude || 0,
                                  },
                                ]}
                                loading={loading}
                              />
                            )}
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <div className="property-detail-row">
                        <table className="property-detail-table">
                          <thead>
                            <tr>
                              <th colspan="4">
                                <h2 className="title-detail">
                                  Property Details
                                </h2>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td colspan="4">
                                <Row>
                                  <Col xs={24} sm={12} md={12}>
                                    <th>Status:</th>
                                    <td>
                                      {StandardStatus ? (
                                        <span className="text-red">
                                          {CamelCaseSplit(StandardStatus)}
                                        </span>
                                      ) : null}
                                    </td>
                                  </Col>
                                  {type_property === "Sale" && (
                                    <Col xs={24} sm={12} md={12}>
                                      <th>HOA:</th>
                                      <td>
                                        {data["AssociationFee"] ? (
                                          `${Helper.decimal(
                                            data["AssociationFee"]
                                          )} ${data["AssociationFeeFrequency"]}`
                                        ) : (
                                          <Tag>No</Tag>
                                        )}
                                      </td>
                                    </Col>
                                  )}
                                </Row>
                              </td>
                            </tr>

                            <tr>
                              <td colspan="4">
                                <Col xs={24} sm={12} md={12}>
                                  <th>Type:</th>
                                  <td>
                                    {PropertyType && PropertySubType
                                      ? CamelCaseSplit(PropertyType) +
                                        ", " +
                                        CamelCaseSplit(PropertySubType)
                                      : null}
                                  </td>
                                </Col>

                                <Col xs={24} sm={12} md={12}>
                                  <th>Parking:</th>
                                  <td>{Parking ? Parking : null}</td>
                                </Col>
                              </td>
                            </tr>

                            <tr>
                              <td colspan="4">
                                {status.includes(StandardStatus) && (
                                  <Col xs={24} sm={12} md={12}>
                                    <th>MLS#:</th>
                                    <td>{ListingId ? ListingId : null}</td>
                                  </Col>
                                )}
                                <Col xs={24} sm={12} md={12}>
                                  <th>Cooling:</th>
                                  <td>
                                    {Cooling ? CamelCaseSplit(Cooling) : null}
                                  </td>
                                </Col>
                              </td>
                            </tr>

                            <tr>
                              <td colspan="4">
                                <Col xs={24} sm={12} md={12}>
                                  <th>Year Built:</th>
                                  {/* Contenido*/}
                                  <td>{YearBuilt ? YearBuilt : null}</td>
                                </Col>
                                <Col xs={24} sm={12} md={12}>
                                  {/* Marcado*/}
                                  <th>Heating:</th>
                                  {/* Contenido*/}
                                  <td>
                                    {Heating ? CamelCaseSplit(Heating) : null}
                                  </td>
                                </Col>
                              </td>
                            </tr>

                            <tr>
                              <td colspan="4">
                                <Col xs={24} sm={12} md={12}>
                                  <th>LivArea:</th>
                                  <td>
                                    {" "}
                                    {Helper.decimal(LivingArea) &&
                                    Number(LivingArea) > 0
                                      ? Helper.decimal(LivingArea)
                                      : null}{" "}
                                    {LivingArea > 0 && "sqft"}
                                  </td>
                                </Col>
                                <Col xs={24} sm={12} md={12}>
                                  <th>Pets:</th>
                                  <td>
                                    {" "}
                                    {PetsAllowed ? (
                                      <Tag color="green">Yes</Tag>
                                    ) : (
                                      <Tag color="red">No</Tag>
                                    )}{" "}
                                  </td>
                                </Col>
                              </td>
                            </tr>

                            <tr>
                              <td colspan="4">
                                <Col xs={24} sm={12} md={12}>
                                  <th>Pool:</th>

                                  <td>
                                    {" "}
                                    <Tag>
                                      {data["PoolPrivateYN"] ? "Yes" : "No"}
                                    </Tag>
                                  </td>
                                </Col>
                                {type_property === "Sale" && (
                                  <Col xs={24} sm={12} md={12}>
                                    <th>Lot SF:</th>
                                    {/* Contenido*/}
                                    <td>
                                      {LotSizeSquareFeet
                                        ? Helper.decimal(LotSizeSquareFeet) +
                                          " Sqft"
                                        : null}{" "}
                                    </td>
                                  </Col>
                                )}
                              </td>
                            </tr>

                            <tr>
                              <td colspan="4">
                                <Col xs={24} sm={12} md={12}>
                                  <th>Subdivision:</th>
                                  <td>
                                    {" "}
                                    {SubdivisionName ? SubdivisionName : null}
                                  </td>
                                </Col>
                                {status.includes(StandardStatus) && (
                                  <Col xs={24} sm={12} md={12}>
                                    <th>Terms:</th>
                                    <td>
                                      {data["ListingTerms"]
                                        ? data["ListingTerms"].replace(
                                            /\,/g,
                                            ", "
                                          )
                                        : null}
                                    </td>
                                  </Col>
                                )}
                              </td>
                            </tr>

                            <tr>
                              <td colspan="4">
                                <Col xs={24} sm={12} md={12}>
                                  <th>County:</th>
                                  <td>
                                    {" "}
                                    {CountyOrParish ? CountyOrParish : null}
                                  </td>
                                </Col>
                                <Col xs={24} sm={12} md={12}>
                                  <th>
                                    {`${
                                      status.includes(StandardStatus)
                                        ? "Last Updated"
                                        : "Closed Date"
                                    }`}
                                    :
                                  </th>
                                  <td>
                                    {" "}
                                    {ModificationTimestamp
                                      ? moment(ModificationTimestamp).format(
                                          "MM-DD-YYYY HH:mm"
                                        )
                                      : StatusChangeTimestamp
                                      ? moment(StatusChangeTimestamp).format(
                                          format
                                        )
                                      : null}
                                  </td>
                                </Col>
                                {
                                  <Col xs={24} sm={12} md={12}>
                                    <th>Listing Date:</th>
                                    <td>
                                      {moment(OriginalEntryTimestamp).format(
                                        "MM-DD-YYYY HH:mm"
                                      )}
                                    </td>
                                  </Col>
                                }
                                {/* <Col xs={24} sm={12} md={12}>
                                  <th>ListingKey:</th>
                                  <td>{ListingKeyNumeric}</td>
                                </Col> */}

                                {status.includes(StandardStatus) &&
                                  YearBuiltDetails && (
                                    <Col xs={24} sm={12} md={12}>
                                      <th>New Construction:</th>
                                      <td>
                                        {YearBuiltDetails == "New Construction"
                                          ? "Yes"
                                          : "No"}
                                      </td>
                                    </Col>
                                  )}
                                {status.includes(StandardStatus) && (
                                  <Col xs={24} sm={12} md={12}>
                                    <th>Conditions:</th>
                                    <td>{SpecialListingConditions}</td>
                                  </Col>
                                )}
                                {status.includes(StandardStatus) &&
                                  Number(DaysOnMarket) > 0 && (
                                    <Col xs={24} sm={12} md={12}>
                                      <th>DOM:</th>
                                      <td>
                                        {DaysOnMarket
                                          ? `${Number(DaysOnMarket)} ${
                                              Number(DaysOnMarket) > 1
                                                ? " days"
                                                : "day"
                                            }`
                                          : null}{" "}
                                      </td>
                                    </Col>
                                  )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {
                          <div className="property-detail-container">
                            {status.includes(StandardStatus) && (
                              <Row>
                                <h2 className="title-detail">
                                  Listing Description
                                </h2>
                                <p
                                  className="description-detail"
                                  dangerouslySetInnerHTML={{
                                    __html: `${
                                      PublicRemarks
                                        ? PublicRemarks.replace(/\*/g, "")
                                        : ""
                                    }`,
                                  }}
                                ></p>
                              </Row>
                            )}
                            {
                              <Row className="collapse-row">
                                <Col xs={24}>
                                  <Collapse
                                    bordered={false}
                                    expandIcon={({ isActive }) => (
                                      <Icon
                                        type="caret-right"
                                        rotate={isActive ? 90 : 0}
                                      />
                                    )}
                                  >
                                    {type_property === "Sale" &&
                                      status.includes(StandardStatus) && (
                                        <Panel
                                          header="Land, Site, and Tax Information"
                                          key="1"
                                          style={customPanelStyle}
                                        >
                                          <table>
                                            <tbody>
                                              {data["TaxYear"] && (
                                                <tr>
                                                  {/* Marcado*/}
                                                  <th>Tax Year:</th>
                                                  {/* Contenido*/}
                                                  <td>{data["TaxYear"]}</td>
                                                </tr>
                                              )}

                                              {typeof data[
                                                "TaxAnnualAmount"
                                              ] !== "undefined" &&
                                                data["TaxAnnualAmount"] !==
                                                  0 && (
                                                  <tr>
                                                    <th>Tax:</th>
                                                    <td>
                                                      {money(
                                                        data["TaxAnnualAmount"],
                                                        "."
                                                      )}
                                                    </td>
                                                  </tr>
                                                )}

                                              {data["PublicSurveyTownship"] && (
                                                <tr>
                                                  {/* Marcado*/}
                                                  <th>Township:</th>
                                                  {/* Contenido*/}
                                                  <td>
                                                    {
                                                      data[
                                                        "PublicSurveyTownship"
                                                      ]
                                                    }
                                                  </td>
                                                </tr>
                                              )}
                                              {data[
                                                "SpecialListingConditions"
                                              ] && (
                                                <tr>
                                                  {/* Marcado*/}
                                                  <th>
                                                    Special Listing Conditions:
                                                  </th>
                                                  {/* Contenido*/}
                                                  <td>
                                                    {CamelCaseSplit(
                                                      data[
                                                        "SpecialListingConditions"
                                                      ]
                                                    )}
                                                  </td>
                                                </tr>
                                              )}

                                              {data["PublicSurveySection"] && (
                                                <tr>
                                                  {/* Marcado*/}
                                                  <th>Section:</th>
                                                  {/* Contenido*/}
                                                  <td>
                                                    {
                                                      data[
                                                        "PublicSurveySection"
                                                      ]
                                                    }
                                                  </td>
                                                </tr>
                                              )}

                                              {data["LotFeatures"] && (
                                                <tr>
                                                  {/* Marcado*/}
                                                  <th>Lot:</th>
                                                  {/* Contenido*/}
                                                  <td>
                                                    {data[
                                                      "LotFeatures"
                                                    ].replace("Item", "")}
                                                  </td>
                                                </tr>
                                              )}

                                              {data["LotSizeDimensions"] && (
                                                <tr>
                                                  {/* Marcado*/}
                                                  <th>Lot Size Dimensions:</th>
                                                  {/* Contenido*/}
                                                  <td>
                                                    {data["LotSizeDimensions"]}
                                                  </td>
                                                </tr>
                                              )}

                                              {data["MLSAreaMajor"] && (
                                                <tr>
                                                  {/* Marcado*/}
                                                  <th>Area:</th>
                                                  {/* Contenido*/}
                                                  <td>
                                                    {data["MLSAreaMajor"]}
                                                  </td>
                                                </tr>
                                              )}

                                              {data["FrontageType"] && (
                                                <tr>
                                                  {/* Marcado*/}
                                                  <th>Frontage Type:</th>
                                                  {/* Contenido*/}
                                                  <td>
                                                    {data["FrontageType"]}
                                                  </td>
                                                </tr>
                                              )}

                                              {data["LotSizeAcres"] && (
                                                <tr>
                                                  {/* Marcado*/}
                                                  <th>Lot Size Acres:</th>
                                                  {/* Contenido*/}
                                                  <td>
                                                    {data["LotSizeAcres"]}
                                                  </td>
                                                </tr>
                                              )}

                                              {data["ZoningDescription"] && (
                                                <tr>
                                                  {/* Marcado*/}
                                                  <th>Zoning Description:</th>
                                                  {/* Contenido*/}
                                                  <td>
                                                    {data["ZoningDescription"]}
                                                  </td>
                                                </tr>
                                              )}

                                              {data["ParcelNumber"] && (
                                                <tr>
                                                  {/* Marcado*/}
                                                  <th>Parcel Number:</th>
                                                  {/* Contenido*/}
                                                  <td>
                                                    {data["ParcelNumber"]}
                                                  </td>
                                                </tr>
                                              )}

                                              {data["LotSizeSquareFeet"] && (
                                                <tr>
                                                  {/* Marcado*/}
                                                  <th>Parcel Number:</th>
                                                  {/* Contenido*/}
                                                  <td>
                                                    {data["LotSizeSquareFeet"]}
                                                  </td>
                                                </tr>
                                              )}

                                              {data["Legal"] && (
                                                <tr>
                                                  {/* Marcado*/}
                                                  <th>Legal:</th>
                                                  {/* Contenido*/}
                                                  <td>
                                                    {data["Legal"]
                                                      ? data["Legal"]
                                                      : "not available"}
                                                  </td>
                                                </tr>
                                              )}
                                            </tbody>
                                          </table>
                                        </Panel>
                                      )}
                                    {type_property != "Sale" &&
                                      status.includes(StandardStatus) && (
                                        <Panel
                                          header="Rent Information"
                                          key="11"
                                          style={customPanelStyle}
                                        >
                                          <table>
                                            <tbody>
                                              {data["AvailabilityDate"] && (
                                                <tr>
                                                  <th>Availability Date:</th>
                                                  <td>
                                                    {CamelCaseSplit(
                                                      data["AvailabilityDate"]
                                                    )}
                                                  </td>
                                                </tr>
                                              )}
                                              {data["LeaseTerm"] && (
                                                <tr>
                                                  <th>Lease Term:</th>
                                                  <td>
                                                    {CamelCaseSplit(
                                                      data["LeaseTerm"].replace(
                                                        /Item/g,
                                                        ""
                                                      )
                                                    )}
                                                  </td>
                                                </tr>
                                              )}
                                              {data["ExistingLeaseType"] && (
                                                <tr>
                                                  <th>Existing Lease Type:</th>
                                                  <td>
                                                    {CamelCaseSplit(
                                                      data["ExistingLeaseType"]
                                                    )}
                                                  </td>
                                                </tr>
                                              )}
                                              {data["RentIncludes"] && (
                                                <tr>
                                                  <th>Rent Includes:</th>
                                                  <td>
                                                    {CamelCaseSplit(
                                                      data["RentIncludes"]
                                                    )}
                                                  </td>
                                                </tr>
                                              )}
                                              {data["ExistingLeaseType"] && (
                                                <tr>
                                                  <th>Existing Lease Type:</th>
                                                  <td>
                                                    {CamelCaseSplit(
                                                      data["ExistingLeaseType"]
                                                    )}
                                                  </td>
                                                </tr>
                                              )}
                                              {data["LeaseAmountFrequency"] && (
                                                <tr>
                                                  <th>
                                                    Lease Amount Frequency:
                                                  </th>
                                                  <td>
                                                    {
                                                      data[
                                                        "LeaseAmountFrequency"
                                                      ]
                                                    }
                                                  </td>
                                                </tr>
                                              )}
                                              {data["LeaseRenewalOptionYN"] && (
                                                <tr>
                                                  <th>Lease Renewal Option:</th>
                                                  <td>
                                                    {data[
                                                      "LeaseRenewalOptionYN"
                                                    ]
                                                      ? "Yes"
                                                      : "No"}
                                                  </td>
                                                </tr>
                                              )}
                                            </tbody>
                                          </table>
                                        </Panel>
                                      )}
                                    <Panel
                                      header="Interior Information"
                                      key="2"
                                      style={customPanelStyle}
                                    >
                                      <table>
                                        <tbody>
                                          {data["FireplaceYN"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Fireplace:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["FireplaceYN"]
                                                  ? "Yes"
                                                  : "No"}
                                              </td>
                                            </tr>
                                          )}
                                          {data["FireplaceFeatures"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Fireplace:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["FireplaceFeatures"]
                                                  ? data["FireplaceFeatures"]
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}
                                          {data["FoundationDetails"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Foundation Details:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["FoundationDetails"]
                                                  ? data["FoundationDetails"]
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}
                                          {/* data["GarageSpaces"] && (
                                      <tr>
                                        <th>Garage Spaces:</th>
                                        <td>
                                          {data["GarageSpaces"]
                                            ? data["GarageSpaces"]
                                            : "not available"}
                                        </td>
                                      </tr>
                                    ) */}
                                          {data["GarageYN"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Garage:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["GarageYN"]
                                                  ? "Yes"
                                                  : "No"}
                                              </td>
                                            </tr>
                                          )}
                                          {data["MainLevelBathrooms"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Main Level Bathrooms:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["MainLevelBathrooms"]
                                                  ? data["MainLevelBathrooms"]
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}

                                          {data["MainLevelBedrooms"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Main Level Bedrooms:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["MainLevelBedrooms"]
                                                  ? data["MainLevelBedrooms"]
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}

                                          {data["LaundryFeatures"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Laundry:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["LaundryFeatures"]
                                                  ? CamelCaseSplit(
                                                      data["LaundryFeatures"]
                                                    )
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}

                                          {data["Utilities"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Utilities:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["Utilities"]
                                                  ? CamelCaseSplit(
                                                      data["Utilities"]
                                                    )
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}

                                          {data["Flooring"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Flooring:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["Flooring"]
                                                  ? CamelCaseSplit(
                                                      data["Flooring"]
                                                    )
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}

                                          {data["Appliances"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Appliances:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["Appliances"]
                                                  ? CamelCaseSplit(
                                                      data["Appliances"]
                                                    )
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}

                                          {data["SecurityFeatures"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Security:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["SecurityFeatures"]
                                                  ? CamelCaseSplit(
                                                      data["SecurityFeatures"]
                                                    )
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}

                                          {data["InteriorFeatures"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Interior:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["InteriorFeatures"]
                                                  ? CamelCaseSplit(
                                                      data["InteriorFeatures"]
                                                    )
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}

                                          {data["Furnished"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Flooring:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["Furnished"] ? (
                                                  <Tag color="green">Yes</Tag>
                                                ) : (
                                                  <Tag color="red">No</Tag>
                                                )}
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </Panel>
                                    <Panel
                                      header="Exterior Information"
                                      key="3"
                                      style={customPanelStyle}
                                    >
                                      <table>
                                        <tbody>
                                          {data["ExteriorFeatures"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Exterior:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {CamelCaseSplit(
                                                  data["ExteriorFeatures"]
                                                )}
                                              </td>
                                            </tr>
                                          )}

                                          {data["ParkingFeatures"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Parking:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {CamelCaseSplit(
                                                  data["ParkingFeatures"]
                                                )}
                                              </td>
                                            </tr>
                                          )}
                                          {data["View"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>View:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {CamelCaseSplit(data["View"])}
                                              </td>
                                            </tr>
                                          )}
                                          {data["CarportYN"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Carport:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["CarportYN"]
                                                  ? "Yes"
                                                  : "No"}
                                              </td>
                                            </tr>
                                          )}
                                          {data["CarportYN"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Carport Spaces:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {CamelCaseSplit(
                                                  data["CarportSpaces"]
                                                )}
                                              </td>
                                            </tr>
                                          )}
                                          {data["Unitview"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Unit view:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {CamelCaseSplit(
                                                  data["Unitview"]
                                                )}
                                              </td>
                                            </tr>
                                          )}
                                          {data["Fencing"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Fencing:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {CamelCaseSplit(
                                                  data["Fencing"]
                                                )}
                                              </td>
                                            </tr>
                                          )}
                                          {data["HorseYN"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Horse:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["HorseYN"] ? "Yes" : "No"}
                                              </td>
                                            </tr>
                                          )}
                                          {data["HorseAmenities"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Horse Amenities:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {CamelCaseSplit(
                                                  data["HorseAmenities"]
                                                )}
                                              </td>
                                            </tr>
                                          )}
                                          {data["OpenParkingYN"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Open Parking:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["OpenParkingYN"]
                                                  ? "Yes"
                                                  : "No"}
                                              </td>
                                            </tr>
                                          )}
                                          {data["OpenParkingSpaces"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Open Parking Spaces:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {CamelCaseSplit(
                                                  data["OpenParkingSpaces"]
                                                )}
                                              </td>
                                            </tr>
                                          )}

                                          {data["Garage"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Garage:</th>
                                              {/* Contenido*/}
                                              <td>{data["Garage"]}</td>
                                            </tr>
                                          )}
                                          {data["WaterfrontYN"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Waterfront:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["WaterfrontYN"]
                                                  ? "Yes"
                                                  : "No"}
                                              </td>
                                            </tr>
                                          )}
                                          {data["WaterBodyName"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Water Body Name:</th>
                                              {/* Contenido*/}
                                              <td>{data["WaterBodyName"]}</td>
                                            </tr>
                                          )}
                                          {data["WaterfrontFeatures"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Waterfront:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {CamelCaseSplit(
                                                  data["WaterfrontFeatures"]
                                                )}
                                              </td>
                                            </tr>
                                          )}
                                          {data["SpaYN"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Spa:</th>
                                              {/* Contenido*/}
                                              <td>{data["SpaYN"]}</td>
                                            </tr>
                                          )}
                                          {data["SpaFeatures"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Spa:</th>
                                              {/* Contenido*/}
                                              <td>{data["SpaFeatures"]}</td>
                                            </tr>
                                          )}
                                          {data["PatioAndPorchFeatures"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Patio/Porch:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {CamelCaseSplit(
                                                  data["PatioAndPorchFeatures"]
                                                )}
                                              </td>
                                            </tr>
                                          )}
                                          {data["BuildingFeatures"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Building:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["BuildingFeatures"]}
                                              </td>
                                            </tr>
                                          )}

                                          {data["Roof"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Roof:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {CamelCaseSplit(data["Roof"])}
                                              </td>
                                            </tr>
                                          )}

                                          {data["PoolFeatures"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Pool:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {CamelCaseSplit(
                                                  data["PoolFeatures"]
                                                )}
                                              </td>
                                            </tr>
                                          )}

                                          {data["ArchitecturalStyle"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Architectural Style:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {CamelCaseSplit(
                                                  data["ArchitecturalStyle"]
                                                )}
                                              </td>
                                            </tr>
                                          )}

                                          {data["Construction Materials"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Construction Materials:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["ConstructionMaterials"]}
                                              </td>
                                            </tr>
                                          )}

                                          {data["WindowFeatures"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Window:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {CamelCaseSplit(
                                                  data["WindowFeatures"]
                                                )}
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </Panel>
                                    <Panel
                                      header="Community Information"
                                      key="4"
                                      style={customPanelStyle}
                                    >
                                      <table>
                                        <tbody>
                                          {data["SeniorCommunityYN"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Senior Community:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["SeniorCommunityYN"] ? (
                                                  <Tag color="green">Yes</Tag>
                                                ) : (
                                                  <Tag color="red">No</Tag>
                                                )}
                                              </td>
                                            </tr>
                                          )}

                                          {data["CommunityFeatures"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Community:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["CommunityFeatures"]
                                                  ? CamelCaseSplit(
                                                      data["CommunityFeatures"]
                                                    )
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}

                                          {data["AssociationYN"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Association:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["AssociationYN"]
                                                  ? data["AssociationYN"]
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}
                                          {data["SecurityFeatures"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Security:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {CamelCaseSplit(
                                                  data["SecurityFeatures"]
                                                )}
                                              </td>
                                            </tr>
                                          )}

                                          {/* typeof data["AssociationFee"] !==
                                      "undefined" &&
                                      data["AssociationFee"] !== 0 && (
                                        <tr>
                                          <th>Association:</th>
                                          <td>
                                            {data["AssociationFee"]}{" "}
                                            {data["AssociationFeeFrequency"]}
                                          </td>
                                        </tr>
                                      ) */}

                                          {data["AssociationFeeInclude"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Association Fee Include:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["AssociationFeeInclude"]
                                                  ? data[
                                                      "AssociationFeeInclude"
                                                    ]
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}

                                          {data["AssociationAmenities"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Amenities:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["AssociationAmenities"]
                                                  ? CamelCaseSplit(
                                                      data[
                                                        "AssociationAmenities"
                                                      ]
                                                    )
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}
                                          {data["AccessibilityFeatures"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Accessibility:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["AccessibilityFeatures"]
                                                  ? data[
                                                      "AccessibilityFeatures"
                                                    ]
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}
                                          {data["BuildingAreaUnits"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Building AreaU nits:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["BuildingAreaUnits"]
                                                  ? data["BuildingAreaUnits"]
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}
                                          {data["MaintenanceInclude"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Maintenance Include:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["MaintenanceInclude"]
                                                  ? data["MaintenanceInclude"]
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}
                                          {data["ApprovalInfo"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Approval Info:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["ApprovalInfo"]
                                                  ? data["ApprovalInfo"]
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}
                                          {data["BodyType"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Body Type:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["BodyType"]
                                                  ? data["BodyType"]
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}

                                          {data[
                                            "AssociatElementarySchoolionFeeInclude"
                                          ] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Elementary School:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["ElementarySchool"]
                                                  ? data["ElementarySchool"]
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}

                                          {data["MiddleOrJuniorSchool"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Middle/Junior School:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["MiddleOrJuniorSchool"]
                                                  ? data["MiddleOrJuniorSchool"]
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}

                                          {data["HighSchool"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>High School:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["HighSchool"]
                                                  ? data["HighSchool"]
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}

                                          {data["WaterSource"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Water Source:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["WaterSource"]
                                                  ? data["WaterSource"]
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}

                                          {data["Sewer"] && (
                                            <tr>
                                              {/* Marcado*/}
                                              <th>Sewer:</th>
                                              {/* Contenido*/}
                                              <td>
                                                {data["Sewer"]
                                                  ? CamelCaseSplit(
                                                      data["Sewer"]
                                                    )
                                                  : "not available"}
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </Panel>
                                  </Collapse>
                                </Col>
                              </Row>
                            }
                            {status.includes(StandardStatus) && (
                              <Row
                                style={{
                                  height: 400,
                                }}
                              >
                                {Longitude && Latitude && (
                                  <Map
                                    zoom={24}
                                    showInfo={false}
                                    center={{
                                      lat: Latitude || 0,
                                      lng: Longitude || 0,
                                    }}
                                    zoom={1}
                                    dataSource={[
                                      {
                                        Longitude: Longitude || 0,
                                        Latitude: Latitude || 0,
                                      },
                                    ]}
                                    loading={loading}
                                  />
                                )}
                              </Row>
                            )}

                            {StandardStatus === "Active" &&
                              priceHistory.length > 0 && (
                                <section>
                                  <div
                                    style={{
                                      margin: "40px 10px",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <h2>Price History </h2>
                                    <Table
                                      loading={loadingHistory}
                                      className="table-history"
                                      showHeader={true}
                                      dataSource={priceHistory}
                                      columns={columns}
                                      size="small"
                                      pagination={false}
                                      bordered
                                      /*  title={() => "Header"}
                                  footer={() => "Footer"} */
                                    />
                                  </div>
                                </section>
                              )}
                            <Row
                              className="grid-walkscores"
                              type="flex"
                              gutter={8}
                              justify="center"
                              align="middle"
                              style={{
                                marginTop: 50,
                              }}
                            >
                              {Geocode && status.includes(StandardStatus) && (
                                <Col>
                                  <WidgetCard
                                    {...walkscore}
                                    title="Walk Score"
                                    description="Walker's Paradise"
                                    valueField="walkscore"
                                  />
                                </Col>
                              )}
                              {/* <Col>
                              <WidgetCard
                                {...walkscore}
                                schools={Math.round(Math.random() * 100)}
                                icon="school"
                                title="Schools"
                                description={walkscore.description}
                                valueField="schools"
                              />
                            </Col> */}
                              {Geocode && status.includes(StandardStatus) && (
                                <Col>
                                  <WidgetCard
                                    {...transitscore}
                                    icon="bus"
                                    transit={Math.round(Math.random(50) + 1)}
                                    title="Transit Score"
                                    description={transitscore.description}
                                    valueField="transit_score"
                                  />
                                </Col>
                              )}
                            </Row>
                            {Geocode && status.includes(StandardStatus) && (
                              <Row
                                type="flex"
                                gutter={8}
                                justify="center"
                                align="middle"
                                style={{
                                  margin: "40px 10px",
                                }}
                              >
                                <Col span={24}>
                                  <h2>Nearby schools in {City}</h2>
                                </Col>
                                <Col span={24}>
                                  <Row
                                    className="schools"
                                    justify="start"
                                    type="flex"
                                    style={{
                                      maxHeight: "550px",
                                      overflowY: "auto",
                                      margin: 10,
                                    }}
                                  >
                                    {schools.map((item) => {
                                      let {
                                        name,
                                        gsRating,
                                        gradeRange,
                                        distance,
                                      } = item;
                                      /* name, gsRating,gradeRange, distance,  */
                                      return (
                                        <Col className="widget" span={8}>
                                          <div>
                                            <div className="w-body">
                                              <div className="w-content">
                                                <div>
                                                  <h2>{name}</h2>
                                                </div>
                                                <div className="w-description">
                                                  <span>
                                                    <strong>Grades:</strong>{" "}
                                                    {gradeRange}
                                                  </span>
                                                  <span>
                                                    <strong>Distance:</strong>{" "}
                                                    {distance}
                                                  </span>
                                                </div>
                                              </div>
                                              {/* <div>
                                            <Rate
                                              disabled
                                              allowHalf
                                              defaultValue={
                                                gsRating > 5
                                                  ? gsRating / 2
                                                  : gsRating
                                              }
                                            />
                                          </div> */}
                                              {gsRating && (
                                                <div className="circle-container">
                                                  <div
                                                    className={`circle-rating ${
                                                      gsRating < 5
                                                        ? "low"
                                                        : gsRating > 5 &&
                                                          gsRating < 8
                                                        ? "medium"
                                                        : "higher"
                                                    }`}
                                                  >
                                                    <span>{gsRating}/</span>
                                                    <span>10</span>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </Col>
                                      );
                                    })}
                                    <Col span={24}>
                                      <div className="copy-gratschools">
                                        <h2>About GreatSchools GreatSchools</h2>
                                        <p>
                                          Ratings based on test scores and
                                          additional metrics when available.
                                        </p>
                                        <a
                                          href="https://www.greatschools.org/gk/ratings/"
                                          target="_blank"
                                        >
                                          Visit site for more
                                        </a>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            )}
                            {StandardStatus === "Active" &&
                              type_property === "Sale" && (
                                <section>
                                  <h2>Affordability</h2>
                                  <p>
                                    Calculate your monthly mortgage payments
                                  </p>
                                  <div className="calcule-form">
                                    <CalculateForm
                                      price_home={price_home}
                                      down_payment={down_payment}
                                      percent_down_payment={
                                        percent_down_payment
                                      }
                                      rate_in={rate_in}
                                      onSubmit={() => {
                                        this.calculate_mortgage(null, {
                                          price_home,
                                          down_payment,
                                          percent_down_payment,
                                          rate_in,
                                          years,
                                        });
                                      }}
                                      onChange={(value, name) => {
                                        this.setState({ [name]: value });

                                        console.log("change:: ", {
                                          [name]: value,
                                        });
                                        this.calculate_mortgage(null, {
                                          price_home,
                                          down_payment,
                                          percent_down_payment,
                                          rate_in,
                                          years,
                                          [name]: value,
                                        });
                                      }}
                                    />
                                  </div>
                                </section>
                              )}

                            {StandardStatus === "Active" &&
                              type_property === "Sale" && (
                                <section>
                                  <Row
                                    style={{
                                      margin: 10,
                                    }}
                                  >
                                    <Col
                                      xs={24}
                                      sm={12}
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <Progress
                                        className="mortgage-percent"
                                        width={200}
                                        type="circle"
                                        max={100000000}
                                        strokeLinecap="square"
                                        /* percent={100} */
                                        percent={percent_down_payment}
                                        successPercent={Number(mortgage)}
                                        /* successPercent={Number(
                                    mortgage.replace(/\$/g, "")
                                  )} */
                                        status="active"
                                        strokeColor="#32A8EA"
                                        strokeWidth={10}
                                        format={(percent) => (
                                          <div>
                                            <h3>Your Monthly Payment </h3>
                                            <h2>
                                              {mortgage !==
                                              "Please Complete Mortgage inputs"
                                                ? money(mortgage)
                                                : ""}
                                            </h2>
                                          </div>
                                        )}
                                      />
                                    </Col>
                                    <Col
                                      xs={24}
                                      sm={12}
                                      style={{
                                        marginTop: "40px",
                                      }}
                                    >
                                      <div className="legends">
                                        <Badge
                                          color="blue"
                                          text={
                                            <div className="badge-item">
                                              <span className="badge-text">
                                                Principal & Interest
                                              </span>
                                              <span className="badge-value">
                                                {money(mortgage)}
                                              </span>
                                            </div>
                                          }
                                        />
                                        <Badge
                                          color="cyan"
                                          text={
                                            <div className="badge-item">
                                              <span className="badge-text">
                                                Property Taxes
                                              </span>
                                              <span className="badge-value">
                                                $
                                                {Helper.decimal(property_taxes)}
                                              </span>
                                            </div>
                                          }
                                        />
                                        <Badge
                                          color="yellow"
                                          text={
                                            <div className="badge-item">
                                              <span className="badge-text">
                                                Home Insurance
                                              </span>
                                              <span className="badge-value">
                                                ${Helper.decimal(insurance)}
                                              </span>
                                            </div>
                                          }
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <h2>Total Monthly Payment: </h2>{" "}
                                          <h3
                                            style={{
                                              padding: "0px 15px",
                                              marginTop: "20px",
                                            }}
                                          >
                                            {money(
                                              Math.round(
                                                Number(mortgage) +
                                                  Number(property_taxes) +
                                                  Number(insurance)
                                              )
                                            )}
                                          </h3>
                                        </div>
                                        {/* <Badge
                                    color="orange"
                                    text={
                                      <div className="badge-item">
                                        <span className="badge-text">
                                          Mortgage ins. & other
                                        </span>
                                        <span className="badge-value">
                                          {money(0)}
                                        </span>
                                      </div>
                                    }
                                  /> */}
                                      </div>
                                    </Col>
                                    {
                                      <BackTop>
                                        <Button
                                          type="primary"
                                          shape="circle"
                                          icon="arrow-up"
                                        />
                                      </BackTop>
                                    }
                                  </Row>
                                </section>
                              )}
                            <section>
                              <Row
                                style={{
                                  margin: 10,
                                }}
                              >
                                <Col>
                                  <Widget />
                                </Col>
                              </Row>
                            </section>
                          </div>
                        }
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xl={6}
                  lg={24}
                  md={24}
                  xs={24}
                  className="contact-form-root"
                >
                  <Affix offsetTop={10}>
                    <div className="contact-form-container">
                      <ContactForm
                        className="contact-form"
                        data={data}
                        description={
                          "I want more information about this property"
                        }
                        address_listing={address_listing}
                        ref={this.contactForm}
                        onSubmit={this.handleSubmit}
                      />
                    </div>
                  </Affix>
                </Col>
              </Row>
            </Layout>
          </Content>
        </Layout>
        {/* !isMobile && (
          <BackTop className="agent-bubble" visibilityHeight={300}>
            <Tooltip
              trigger="click"
              overlayClassName="tooltip-message"
              visible={show_tooltip}
              defaultVisible={true}
              placement="leftTop"
              title={
                <span>
                  <strong>Contact me about this listing.</strong>
                </span>
              }
            >
              <div>
                <Avatar
                  size="default"
                  shape="circle"
                  onClick={this.showModal}
                  src="https://s3.amazonaws.com/gooneworld-deployments-mobilehub-607941478/img/bruno-linares.jpg"
                />
                <div className="bubble-icon">
                  <div>
                    <Button
                      onClick={this.showModal}
                      className="bg-blue"
                      size="large"
                      type="primary"
                      shape="circle"
                    >
                      <FontAwesomeIcon
                        size={"lg"}
                        className="icon-social"
                        icon={["fas", "comment-dots"]}
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </Tooltip>
          </BackTop>
        ) */}
        <Modal
          title="Contact Me For This Listing"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <ContactForm
            className="contact-form"
            data={data}
            address_listing={address_listing}
            ref={this.contactForm}
            onSubmit={this.handleSubmit}
          />
        </Modal>
      </LayoutDetail>
    );
  }
}

const mapStateToProps = (state) => {
  const { search } = state;
  return {
    search: search,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListingDetails);
