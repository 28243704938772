const Search = (state = {}, action = {}) => {
  switch (action.type) {
    case "SET_SEARCH_PARAM":
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      };
      break;
    case "CITY_CHANGE":
      return {
        ...state,
        data: action.data
      };
      break;
    default:
      return state;
    break;
  }
};

export default Search;
