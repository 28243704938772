import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Menu from "./menu";
import "./header.css";

import { Radio, Row } from "antd";

import SearchFieldCategory from "./com/searchfield/SearchFieldCategory";
const background_header = require("../images/header_background.jpg");

class Header extends Component {
  state = {
    TransactionType: "Sale"
  };
  handleTransactionType = e => {
    let { value } = e.target;
    console.log("Value Selected: ", value);
    this.setState({
      TransactionType: value,
      address: "Miami,FL"
    });
  };
  onChangeSearch = (value, option) => {
    console.log("Value Selected: ", value);
    let { TransactionType } = this.state;

    try {
      let { history } = this.props;
      let {
        props: { record, category }
      } = option;
      let { City, State, Slug, Zip } = record;
      console.log(">>> CATEGORY: ", category, option);

      if (category == "Zip") {
        return history.replace(
          `/zipcode/${TransactionType}/${Zip.replace(/\//g, "-")}`
        );
      }
      if (category == "Address") {
        return history.replace(
          `/listings/details/${City.replace(/\//g, "-")}/${State.replace(
            /\//g,
            "-"
          )}/${Slug.replace(/\//g, "-")}`
        );
      }

      if (option) {
        this.setState({
          address: value
        });
        this.goToSearch(value, option);
      }
    } catch (err) {
      console.log("ERROR: ", err);
    }
  };
  goToSearch = (value, option) => {

    const { history } = this.props;
    let {
      props: { record }
    } = option;

    let { City, State } = record;

    let { address, TransactionType } = this.state;
    let path = `/listings/search/`;
    State = State.replace(/ /g, "");
    //City = City.replace(/ /g,'-');
    path = `/listings/search/${City || "Miami"}/${State || "FL"}`;
    path = path + `/${TransactionType || "Sale"}`;

    if (history) history.push(path);
  };
  componentDidMount() {
    let { match } = this.props;
    let { params } = match;
    console.log("> TransactionType: ", params.transaction_type);
    /* this.setState({
      TransactionType: params.transaction_type
    }); */
  }
  
  render() {
    let { TransactionType, address } = this.state;
    let { history } = this.props;
    return (
      <header
        className="home-header"
        style={{
          minHeight: 528,
          backgroundImage: `url(${background_header})`
        }}
      >
        <Menu {...this.props}/>
        <div className="home-menu-container">
          <h1 className="header-title">
            <Link
              to="/"
              style={{
                color: `white`,
                textDecoration: `none`
              }}
            >
              <div className="title-main">
                Homes for Sale <br />
                Apartments for Rent
              </div>
            </Link>
          </h1>
          <Row>
            <Radio.Group
              size="large"
              style={{
                width: "100%",
                textAlign: "center",
                margin: "10px auto"
              }}
              className="home-types"
              value={this.state.TransactionType}
              onChange={this.handleTransactionType}
            >
              <Radio.Button ghost value="Sale">
                For Sale
              </Radio.Button>
              <Radio.Button ghost value="Lease">
                For Rent
              </Radio.Button>
            </Radio.Group>
          </Row>
          <Row>
            <SearchFieldCategory
              history={history}
              className="search-home"
              size="large"
              transaction_type={this.state.TransactionType}
              placeholder="Search an addres, city, ZIP code or MlsId"
              onSelect={this.onChangeSearch}
              onOK={this.onChangeSearch}
            />
          </Row>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default withRouter(Header);
