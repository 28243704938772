import React, { useEffect, useState } from "react";
import { Affix, Col, Row } from "antd";
import { Wrapper } from "./Styles";
import Layout from "../../layouts/layoutsubdivision";
import ListingCardList from "./ListingCardList";
import Map from "../../components/com/Map/Map";

const SubdivisionListings = (props) => {
  const [SubdivisionName, setSubdivisionName] = useState();
  const [data, setData] = useState([]);
  const handleOnLoad = (data) => {
    setData(data.filter((it) => it.Latitude && it.Longitude));
  };
  useEffect(() => {
    let { match } = props;
    if (match) {
      let { SubdivisionName } = match.params;
      SubdivisionName = SubdivisionName.replace(/\-/g, " ");
      setSubdivisionName(SubdivisionName);
    }
  }, [props.match]);
  if (!SubdivisionName) return null;
  return (
    <Layout>
      <Wrapper>
        <Row
          style={{
            height: "100%",
          }}
          type="flex"
          justify="space-between"
          align="top"
          gutter={8}
        >
          <Col lg={12} xs={24}>
            <ListingCardList
              title={`Active Listings for Sale`}
              transaction_type="Sale"
              reference="listings-by-subdivision-name"
              onLoad={handleOnLoad}
              filterDefaultValues={{
                SubdivisionName,
                PropertyType: "Residential",
              }}
              pageSize={10}
            />
            <ListingCardList
              title="Active Listings for Rent"
              transaction_type="Sale"
              reference="listings-by-subdivision-name"
              filterDefaultValues={{
                SubdivisionName,
                PropertyType: "ResidentialLease",
              }}
              pageSize={10}
            />
            <ListingCardList
              title={`Recent Closed Sales`}
              transaction_type="Sale"
              reference="listings-closed"
              filterDefaultValues={{
                SubdivisionName,
                StandardStatus: "Active",
                PropertyType: "Residential",
              }}
              pageSize={10}
            />
            <ListingCardList
              title={`Recent Closed Rent`}
              transaction_type="Sale"
              reference="listings-closed"
              filterDefaultValues={{
                SubdivisionName,
                StandardStatus: "Active",
                PropertyType: "ResidentialLease",
              }}
              pageSize={10}
            />
          </Col>
          <Col span={12}>
            <Affix
              style={{
                minHeight: "100vh",
              }}
              offsetTop={0}
            >
              <div className="map-container">
                {data && data.length > 0 && (
                  <Map
                    /* dataSource={[active[0]]} */
                    dataSource={data}
                    /* reference="listings-by-subdivision-name"
                  filterDefaultValues={{
                       Longitude:{$ne:null},
                    SubdivisionName,
                    StandardStatus: "Active",
                    //PropertyType: TransactionType == "Sale" ? "Residential" : "ResidentialLease"
                }} */
                  />
                )}
              </div>
            </Affix>
          </Col>
        </Row>
      </Wrapper>
    </Layout>
  );
};
export default SubdivisionListings;
