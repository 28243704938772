import React, { useState, useEffect } from 'react';
import { DropDownMenu } from "../index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RadioGroup from 'antd/lib/radio/group';
import { Col, Icon, Input, InputNumber, Radio, Row } from 'antd';
import { WrapperDropDownMenuList } from './Styles';
const DropdownMenuList = props => {
    let data = props.data || [];
    let value =
        props.value !== "undefined" && props.value !== "NaN" ? props.value : "";
    const [showNumber, setShowNumber] = useState(true);
    const onChange = (value, el) => {
        if (typeof value === "undefined" || value == null) {
            setShowNumber(showNumber => (!showNumber))
            //return (showNumber = !showNumber);
        }
        if (typeof props.onChange !== "undefined") props.onChange(value);
    }
    return (<WrapperDropDownMenuList>
        <DropDownMenu
            className="btn-tools"
            icon={"down"}
            style={{
                height: 32,
                verticalAlign: "middle"
            }}
            label={
                <span>
                    <FontAwesomeIcon className="icon-fab" icon={props.icon || ""} />{" "}
                    {props.label}
                </span>
            }
            menuStyle={{
                width: 250
            }}
        >
            <Row type="flex" justify="center">
                <Col
                    span={24}
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        justifyItems: "center"
                    }}
                >
                    <RadioGroup
                        size={props.size || "default"}
                        value={props.value || null}
                        onChange={e => {
                            let { value } = e.target;
                            onChange(value, e.target);
                        }}
                    >
                        <>
                            {data.map((item, index) => {
                                return (
                                    <Radio.Button
                                        size="large"
                                        key={index}
                                        value={item.value}
                                        className={`btn-dropdown ${item.type || ""}`}
                                    >
                                        {item.text}
                                    </Radio.Button>
                                );
                            })}
                            <Radio.Button
                                size="large"
                                name="add"
                                /* value={value} */
                                className={`btn-dropdown`}
                            >
                                <Icon type={showNumber ? "up" : "down"} />
                            </Radio.Button>
                        </>
                    </RadioGroup>
                </Col>
                {showNumber && (
                    <Col
                        span={24}
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            justifyItems: "center"
                        }}
                    >
                        <Input
                            style={{ margin: "10px 0px", width: "100%" }}
                            size="large"
                            value={value}
                            label="Baths"
                            placeholder="Example: 1-3;"
                            onChange={value => {
                                onChange(value);
                            }}
                        />
                    </Col>
                )}
            </Row>
        </DropDownMenu>
    </WrapperDropDownMenuList>);
};
export default DropdownMenuList;