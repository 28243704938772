import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Col, DatePicker, Input, InputNumber, Row, Tabs } from 'antd';
import { Link } from 'react-router-dom';
import { SelectField, SimpleForm } from '../';
import { WrapperForm } from './Styles';
const { TabPane } = Tabs;
const TabPanel = props => <TabPane {...props}>{props.children}</TabPane>;
const styles = {
    width: "50%",
    margin: 5
};
//null,1,7,14,30,90,6m,12m,24m,36m
const days = [
    {
        id: undefined,
        name: "Any"
    },
    {
        id: "1",
        name: "1 day"
    },
    {
        id: "7",
        name: "7 days"
    },
    {
        id: "14",
        name: "14 days"
    },
    {
        id: "30",
        name: "30 days"
    },
    {
        id: "90",
        name: "90 days"
    },
    {
        id: "180",
        name: "6 months"
    },
    {
        id: "365",
        name: "12 months"
    },
    {
        id: "730",
        name: "24 months"
    },
    /* {
        value: "1095",
        name: "36 months"
    } */
]
const RentForm = ({ onChange, params, ...props }) => {
    const handleChange = (field, value, form) => {
        form.validateFields([field], (errors, values) => {
            if (!errors && onChange) onChange(field, value);
        });
    }
    return (<WrapperForm>
        <SimpleForm
            initialValues={params}
            autoSubmit={false}
            onChange={handleChange}
        >
            <InputNumber
                size="large"
                min={0}
                max={90000}
                name="LivingAreaMin"
                label="LivArea Min"
                validations={[
                    {
                        type: "number",
                        message: "This field must be number"
                    }
                ]}
                placeholder="Min Sqft"
                onChange={value => {
                    /* this.handleSqArea(value, "LivingAreaMin") */
                }}
            />
            <InputNumber
                size="large"
                min={0}
                max={90000}
                name="LivingAreaMax"
                label="LivArea Max"
                placeholder="Max Sqft"
                validations={[
                    {
                        type: "number",
                        message: "This field must be number"
                    }
                ]}
                onChange={value => {
                    /* this.handleSqArea(value, "LivingAreaMax") */
                }}
            />
            <DatePicker
                xtype="date"
                size="large"
                name="OriginalEntryTimestamp"
                label="Listing Date"
                placeholder="Select Date"
                format="MM-DD-YYYY"
            />
            <Input
                size="large"
                name="keywords"
                label="Keywords"
                placeholder="Pool,Waterfront, ..."
                type="text"
            />
            <SelectField
                flex={1}
                label="Days on Market"
                name="DaysOnMarket"
                initial={params.DaysOnMarket}
                choices={days}
            />
        </SimpleForm>
    </WrapperForm>)
}
const SaleForm = ({ onChange, params, ...props }) => {
    const handleChange = (field, value, form) => {
        form.validateFields([field], (errors, values) => {
            if (!errors && onChange) onChange(field, value);
        });
    }
    return (<WrapperForm>
        <SimpleForm
            initialValues={params}
            autoSubmit={false}
            onChange={handleChange}
        >
            <InputNumber
                size="large"
                min={0}
                max={90000}
                name="LivingAreaMin"
                label="LivArea Min"
                validations={[
                    {
                        type: "number",
                        message: "This field must be number"
                    }
                ]}
                placeholder="Min Sqft"
                onChange={value => {
                    /* this.handleSqArea(value, "LivingAreaMin") */
                }}
            />
            <InputNumber
                size="large"
                min={0}
                max={90000}
                name="LivingAreaMax"
                label="LivArea Max"
                placeholder="Max Sqft"
                validations={[
                    {
                        type: "number",
                        message: "This field must be number"
                    }
                ]}
                onChange={value => {
                    /* this.handleSqArea(value, "LivingAreaMax") */
                }}
            />
            <DatePicker
                xtype="date"
                size="large"
                name="OriginalEntryTimestamp"
                label="Listing Date"
                placeholder="Select Date"
                format="MM-DD-YYYY"
            />
            <Input
                size="large"
                name="keywords"
                label="Keywords"
                placeholder="Pool,Waterfront, ..."
                type="text"
            />
            <Checkbox
                xtype="checkbox"
                style={{ width: "50%" }}
                defaultChecked={params.REOYN == "true"}
                name="REOYN" >
                REO
            </Checkbox>
            <Checkbox
                xtype="checkbox"
                style={{ width: "50%" }}
                defaultChecked={params.ShortSaleYN == "true"}
                name="ShortSaleYN">
                Short Sale
            </Checkbox>
            <Checkbox
                xtype="checkbox"
                style={{ width: "50%" }}
                defaultChecked={params.AuctionYN == "true"}
                name="AuctionYN" >
                Auction
            </Checkbox>
            <Checkbox
                xtype="checkbox"
                style={{ width: "50%" }}
                defaultChecked={params.AuctionYN == "true"}
                name="NewConstructionYN" >
                New Construction
            </Checkbox>
            <SelectField
                flex={1}
                label="Days on Market"
                name="DaysOnMarket"
                initial={params.DaysOnMarket}
                choices={days}
            />

        </SimpleForm>
    </WrapperForm>)
}
const MoreMenu = ({ PropertySubTypes, onChange, ...props }) => {
    const [transaction_type, setTransactionType] = useState();
    const { params } = props.match;
    console.log(props.match)
    /* const { City, StateOrProvince, transaction_type } = params; */
    const { City, StateOrProvince } = params;
    let base_link = `/listings/search/${City}/${StateOrProvince}/${transaction_type}`;
    let new_construction_link = `${base_link}?NewConstructionYN=true&order_by=NEW-LISTINGS`;
    let pending_listing = `${base_link}?StandardStatus=Pending`;
    const handleChangeTab = (key) => {
        setTransactionType(key);
        if (onChange)
            onChange("transaction_type", key);
    }
    const handleChange = (field, value) => {
        if (onChange)
            onChange(field, value);
    }
    useEffect(() => {
        if (props.transaction_type)
            setTransactionType(props.transaction_type);
        return () => {
            setTransactionType(null);
        }
    }, [props.transaction_type]);
    return (<div>
        {<Tabs
            className="tabs-more"
            activeKey={transaction_type}
            defaultActiveKey={transaction_type}
            type="card"
            onChange={handleChangeTab}
        >
            {<TabPanel
                className="tab-item tab-first"
                tab="For Sale"
                key="Sale"
            >
                <div>
                    <section>
                        <SaleForm
                            params={params}
                            onChange={handleChange}
                            {...props}
                        />
                    </section>
                    {/* <section>
                        <div>
                            <Button type="link" size="large">
                                <Link
                                    to={new_construction_link}
                                    target="_blank"
                                >
                                    New Construction
                                </Link>
                            </Button>
                            <Button type="link" size="large">
                                <Link
                                    to={new_construction_link}
                                    target="_blank"
                                >
                                    Foreclosures
                                </Link>
                            </Button>
                        </div>
                    </section> */}
                </div>
            </TabPanel>}
            <TabPanel
                className="tab-item tab-middle"
                tab="For Rent"
                key="Lease"
            >
                <div>
                    <section>
                        <RentForm
                            params={params}
                            onChange={handleChange}
                            {...props}
                        />
                    </section>
                </div>
            </TabPanel>
        </Tabs>}
    </div>)
}
export default MoreMenu;