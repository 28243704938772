import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Menu from "./menu";
import "./header.css";
import "./header_oneestimate.css";

import { Radio, Row } from "antd";

import SearchFieldCategory from "./com/searchfield/SearchFieldCategory";
const background_header = require("../images/sell22.jpg");

class Header extends Component {
  state = {
    type: "for_sale"
  };
  handleTransactionType = e => {
    let { value } = e.target;
    this.setState({
      type: value,
      address: "Miami,FL"
    });
  };
  onChangeSearch = (value, option) => {
    console.log("Value Selected: ", value);
    this.setState({
      address: value
    });
    if (typeof value !== "undefined") this.goToSearch(value, option);
  };
  goToSearch = (value, option) => {
    const { history } = this.props;
    let {
      props: { record }
    } = option;

    let { City, State } = record;

    let { address, type } = this.state;
    let path = `/listings/search/`;
    State = State.replace(/ /g, "");
    //City = City.replace(/ /g,'-');
    path = `/listings/search/${City || "Miami"}/${State || "FL"}`;
    path = path + `/${type || "for_sale"}`;
    if (history) history.push(path);
  };
  render() {
    let { type, address } = this.state;
    return (
      <header
        style={{
          minHeight: 528,
          backgroundImage: `url(${background_header})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          zoom: 1,
          boxSizing: "border-box",
          padding: "0px 10px 0px 10px",
          backgroundPositionX: "80%",
          backgroundPositionY: "10%"
        }}
      >
        <Menu />
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `1.45rem 1.0875rem`
          }}
        >
          <h1 className="header-title">
            <Link
              to="/"
              style={{
                color: `white`,
                textDecoration: `none`
              }}
            >
              <div className="title-main_one">How Much Is My Home Worth?</div>
            </Link>
          </h1>
          <Row>
            <Radio.Group
              size="large"
              style={{
                width: "100%",
                textAlign: "center",
                margin: "10px"
              }}
              className="home-types"
              value={this.state.type}
              onChange={this.handleTransactionType}
            >
              <Radio.Button ghost value="for_sale">
                For Sale
              </Radio.Button>
              <Radio.Button ghost value="for_lease">
                For Lease
              </Radio.Button>
            </Radio.Group>
          </Row>
          <Row>
            <SearchFieldCategory
              className="search-home"
              size="large"
              /* defaultValue={["Miami, FL"]} */
              placeholder="Search an addres, city, ZIP code or MlsId"
              onSelect={this.onChangeSearch}
              onOK={this.onChangeSearch}
            />
          </Row>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default withRouter(Header);
