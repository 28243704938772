import React from "react";
import { Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Social = props => (
  <div className={props.className || "social-network"}>
    <div>
      <a href="https://www.facebook.com/GoOneWorld" target="_blank">
        <FontAwesomeIcon
          className="icon-fab-animated"
          icon={["fab", "facebook"]}
          size="lg"
          color="#ccc"
        />
      </a>
      <a href="https://twitter.com/gooneworld" target="_blank">
        <FontAwesomeIcon
          className="icon-fab-animated"
          icon={["fab", "twitter"]}
          size="lg"
          color="#ccc"
        />
      </a>
      <a
        href="https://www.linkedin.com/company/one-world-international-real-estate"
        target="_blank"
      >
        <FontAwesomeIcon
          className="icon-fab-animated"
          icon={["fab", "linkedin"]}
          size="lg"
          color="#ccc"
        />
      </a>
    </div>
  </div>
);
export default Social;
