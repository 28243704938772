import React, { useEffect, useState } from "react";
import { Wrapper } from "./Styles";
import { Button } from "antd";



const Term = ({ ...props }) => {
    const [visible, setVisible] = useState(false);
    const handleOnChange = () => {
        setVisible(visible => !visible);

    }
    return (
        <Wrapper>
            <div className="container-section">
                <div className="container-section-buttom">
                    <Button type="link" onClick={() => handleOnChange()}>Disclaimer</Button>
                </div>
            </div>
            {visible && <div className="terminos-description" style={{ width: "100%" }}>
                By clicking request information, you agree that GoOneWorld and
                some real estate professionals may contact you by phone/text
                about your inquiry. Message/data rates may apply. You also agree
                to our{" "}
                <a target="_blank" href="/terms-and-conditions">
                    Terms of Use
                </a>
                .
              </div>}
        </Wrapper>
    )
}
export default Term;