import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../redux/actions/action_search";

import { Row, Col, Affix } from "antd";
import "./listings.css";
import "./listingssubdivision.css";

import ListingsCards from "../components/condo/CondosCards";
import Layout from "../layouts/layoutcontesubdivision";
import Map from "../components/com/MapCondos/Map";
import { api_search_condoslist } from "../services";

const MAX_PAGE_SIZE = 30;


class ConteSubdivision extends Component {
  state = {
    top: 0,
    data: [],
    active: [],
    pagination_active: {},
    loading_active: true
  };

  componentDidMount() {
    

    this.load_condos(
      {
        city: 'Miami Beach'
      },
      "active"
    );
  }

  load_condos = (params, name) => {

    api_search_condoslist({params})
      .then(response => {
        console.log(response);
        

        let res = response.data || [];
        let { data, meta } = res;
        console.log("Data: ", data);
        this.state[`pagination_${name}`] = meta;
        if (data.length > 0)
          this.setState({
            [`pagination_${name}`]: meta,
            [`loading_${name}`]: false,
            [name]: data
          });

        //console.log(this.state);
      })
      .catch(err => {
        this.setState({
          [`loading_${name}`]: false
        });
      });
  };

  handlePagination = (page, name, type, transtype) => {
    
    this.setState({
      [`pagination_${name}`]: {},
      [`loading_${name}`]: true
      //[name]: data
    });
    this.load_condos(
      {
        city: 'Miami Beach',
        page
      },
      name
    );
  };

  render() {
    let {
      active,
      pagination_active,
      loading_active
    } = this.state;
    //let {loading_active, pagination_active={}, pageSize=6} =
    let { CurrentPage = 1, TotalRecords = 0 } = pagination_active;

    return (
      <Layout>
        <Row
          style={{
            height: "100%"
          }}
        >
          <Col
            span={12}
            lg={12}
            xs={24}
            style={{
              paddingRight: "0px!important"
            }}
          >
            <Row>
              <Col
                span={24}
                lg={24}
                xs={24}
                style={{
                  paddingRight: "0px!important"
                }}
              >
                
                <ListingsCards
                  data={active}
                  pagination={pagination_active}
                  pageSize={6}
                  total={TotalRecords}
                  loading={loading_active}
                />
              </Col>
            </Row>

          </Col>

          <Col
            span={12}
            className="conte_map"
            style={{
              paddingLeft: "0px!important"
            }}
          >
            <Affix offsetTop={this.state.top}>
              <div className="map">
                <Map data={active} loading={loading_active} zoom={24} isOpen={false} />
              </div>
            </Affix>
          </Col>
        </Row>


      </Layout>
    );
  }
}
const mapStateToProps = state => {
  const { search } = state;
  return {
    search: search
  };
};
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ConteSubdivision));
