import React, { Component } from "react";
import { Carousel, Button, Row, Col } from "antd";
import "./carouselhorizontal.css";
import { isMobile } from "react-device-detect";

let scree_xs = 24,
  scree_sm = 12,
  scree_md = 8,
  scree_lg = 6,
  scree_xl = 6,
  scree_xxl = 4;
export default class CarouselHorizontal extends Component {
  state = {
    numberOfCards: 4,
    autoplay: true,
    dataSource: []
  };
  myRef = React.createRef();
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateSize);
  }
  componentDidMount() {
    let numberOfCards = this.updateSize();
    console.log("numberOfCards: ", numberOfCards);
    let items = this.getItems();
    this.setState({
      numberOfCards,
      items
    });
    window.addEventListener("resize", this.escale);
  }
  escale = () => {
    let items = this.getItems();
    this.setState({
      items
    });
  };
  updateSize = () => {
    let { numberOfCards } = this.state;
    let w = window.innerWidth;

    if (w <= 576) {
      //xs
      numberOfCards = 1;
    } else if (w > 576 && w <= 768) {
      //sm
      numberOfCards = 2;
    } else if (w > 768 && w < 992) {
      //md
      numberOfCards = 3;
    } else if (w >= 992 && w < 1200) {
      //lg
      numberOfCards = 4;
    } else if (w >= 1200 && w < 1600) {
      //xl
      numberOfCards = 4;
    } else if (w >= 1600) {
      //xxl
      numberOfCards = 6;
    }

    if (isMobile) numberOfCards = 1;
    console.log("Menos de 400px?", w, numberOfCards);
    /* this.setState({
      numberOfCards
    }); */
    return numberOfCards;
  };
  createItems = (record, xindex, arr) => {
    let numberOfCards = this.updateSize();
    //let { numberOfCards } = this.state;
    //numberOfCards = this.props.numberOfCards || numberOfCards;
    if (isMobile) numberOfCards = 1;
    let count = xindex;
    return (
      <Row
        key={xindex}
        style={{
          width: "100%"
        }}
      >
        {Array.apply(null, { length: numberOfCards })
          .map((el, index) => {
            count++;
            return count <= arr.length ? (
              <Col
                xs={scree_xs}
                sm={scree_sm}
                md={scree_md}
                lg={scree_lg}
                xl={scree_xl}
                xxl={scree_xxl}
                key={index}
                className="carousel-horizontal-item-container"
              >
                {typeof this.props.renderItem !== "undefined" ? (
                  <div className="carousel-horizontal-item">
                    {this.props.renderItem(arr[count - 1])}
                  </div>
                ) : (
                  <div className="carousel-horizontal-item">
                    Col {count}:{xindex} / {arr.length}
                  </div>
                )}
              </Col>
            ) : null;
          })
          .filter(item => item !== null)}
      </Row>
    );
  };
  getItems = () => {
    let { autoplay, dataSource } = this.state;
    let numberOfCards = this.updateSize();
    dataSource = this.props.dataSource || dataSource;
    let count = -1;
    //numberOfCards = this.props.numberOfCards || numberOfCards;
    numberOfCards = this.updateSize();
    if (isMobile) numberOfCards = 1;
    try {
      dataSource = dataSource.map((item, index) => {
        count++;
        if (index % numberOfCards === 0) {
          return this.createItems(item, count, dataSource);
        }
      });
    } catch (err) {}
    /* this.setState({
      numberOfCards,
      items: dataSource
    }); */
    return dataSource;
  };
  render() {
    let autoplay =
      typeof this.props.autoplay !== "undefined"
        ? this.props.autoplay
        : this.state.autoplay;
    let { loading } = this.props;

    let { dataSource } = this.props;
    let total = dataSource.length;
    //let numberOfCards = this.updateSize();
    let { numberOfCards } = this.state;
    return (
      <div className="carousel-horizontal-container">
        <Carousel
          ref={this.myRef}
          afterChange={this.props.onChange}
          className="carousel-horizontal carousel-horizontal-infinite"
          autoplay={autoplay}
          dots={false}
          style={{
            height: 400
          }}
        >
          {this.getItems()}
        </Carousel>
        {!loading && total > 1 && (
          <div className="tools-carousel-horizontal Aenable">
            <Button
              shape="round"
              className="btn-left"
              type="primary"
              icon="left"
              onClick={() => {
                this.myRef.current.prev();
              }}
            />
            <Button
              shape="round"
              className="btn-right"
              type="primary"
              icon="right"
              onClick={() => {
                this.myRef.current.next();
              }}
            />
          </div>
        )}
      </div>
    );
  }
}
