import React, { Component } from "react";
import { Card, Icon, Row, Button, Carousel, message, Modal } from "antd";
import { Link } from "react-router-dom";
import "./condocard.css";
import Constants from "../../utils/constants";
import Helper from "../../utils/Helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { Meta } = Card;
const { money } = Helper;

const URL_COMING_SOON = require("../../images/coming_soon.png");

const WrapperCover = props => {
  let { PhotosCount, ListingKeyNumeric } = props;
  let URL_PHOTO =
    PhotosCount > 0
      ? `/imgcondos/${ListingKeyNumeric}/1.jpg`
      : URL_COMING_SOON;

  return (
    <div
      className="cover-card"
      alt="example"
      style={{
        backgroundImage: `url(${URL_PHOTO})`,
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center"
      }}
    >
      {props.children}
    </div>
  );
};
class ListingMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0
    };
  }

  render() {
    let displayGalery = true;
    let showActions = "";

    let {
      ListingKeyNumeric
      ,City
      ,State
      ,SubdivisionName
      ,TotalUnits
      ,TotalFloor
      ,YearBuilt
      ,PhotosCount
      ,namecondo
    } = this.props;


    const URL_PHOTO =
      PhotosCount > 0
        ? `/imgcondos/${ListingKeyNumeric}/1.jpg`
        : URL_COMING_SOON;

        console.log("phot ",URL_PHOTO);
    showActions =
      typeof this.props.showActions !== "undefined"
        ? this.props.showActions
        : true;
    displayGalery = typeof displayGalery !== "undefined" ? displayGalery : true;

    return (
      <Card
        className="listing-card"
        hoverable
        style={{ width: "100%" }}
        cover={
          <WrapperCover
            {...this.props}
            current={this.state.current}
            onChange={this.onChange}
            displayGalery={displayGalery}
          >
          </WrapperCover>
        }
      >
        <Meta
          title={
            <div className="title-card">
              <a
                href={`/miami-beach/${namecondo}`}
              >
                {SubdivisionName}
              </a>
            </div>
          }
          description={
              <div>
              <Row>
                <div className="comple_add">
                  Stories: {this.props.TotalFloor} Units: {this.props.TotalUnits}{" "}
                  <br/>
                  Year Built: {this.props.YearBuilt}
                </div>
              </Row>
              <Row>
                {/*<h3 className="list-price">
                  {money(this.props.ListPrice)}
                </h3>*/}
              </Row>
            </div>
          }
        />
      </Card>
    );
  }
}

export default ListingMap;
