import  React, { Component } from 'react';
import { Skeleton } from 'antd';
import './skeletontpl.css'
const house = require('../../../images/house.svg');
class SkeletonTpl extends Component{
    render(){
        return (<div className="col-md-6 col-sm-6 col-xs-6 prop" key={this.props.key} style={{
            background:"#fff"
        }}>
            <div className="wht-cont" >
            <Skeleton className="property-skeleton" 
            active loading 
            avatar={{ shape: 'square' }} 
            title={false} 
            paragraph={{ rows: 3 }}/>
            </div>
        </div>);
    }
}

export default SkeletonTpl;