export const URL_S3 = "https://oneworld-listings-images.s3.amazonaws.com";
export const BUCKET = "oneworld-listings-images";
export const ACCESS_KEY_ID = "AKIA6H2C57S5QKMT2ANV";
export const SECRET_ACCESS_KEY = "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";

export const URL_S3_SERVER =
  window.location.hostname === "localhost"
    ? "http://localhost:3030"
    : "https://api-dev.gooneworld.com";

export const URL_COMING_SOON = "/images/coming_soon.svg";

export default {
  /* APIS */

  URL_API_GOONEWORLD:
    "https://api.gooneworld.com/" /* 'https://gooneworld.com/' */,
  URL_API_COMPROPERTEE: "https://api.gooneworld.com/", //'http://104.248.211.159:3000/',
  URL_BASE: "https://api.gooneworld.com/",
  //URL_API_PHOTOS: "https://imagecdn1.gooneworld.com/", //https://gooneworld.com/dataAPI/photos
  URL_API_PHOTOS: "https://81p8aypb40.execute-api.us-east-1.amazonaws.com/production/", //https://gooneworld.com/dataAPI/photos
  //URL_API_PHOTOS: "https://oneworld-listings-images.s3.amazonaws.com/", //https://gooneworld.com/dataAPI/photos

  /* URLS */
  URL_AUTOCOMPLETE: "api/Search/autocomplete", //'autocomplete.php',
  URL_RECENT_LISTING: "dataAPI/compropertee",
  URL_PHOTOS: "dataAPI/photos/",
  URL_API_SEARCH: "api/Properties/search/res", //'api/Properties/search',
  URL_API_FIND_LAT_LNG: "api/Properties/findLatLng/",
  URL_AUTOCOMPLETE_SEARCH: "api/Properties/autocomplete",

  /* DETAILS */

  PATH_API_DETAILS: "/api/Properties/details",

  /* ACL Users */
  URL_LOGIN: "api/Users/login",
  URL_LOGOUT: "api/Users/logout",
  URL_SIGNUP: "api/Users",

  /* Users */
  URL_USERS: "api/Users",
  URL_CHANGE_PASSWORD: "api/Users/change-password",

  URL_SAVE_SEARCH: "api/userSearchData",
  URL_SEND_MESSAGE: "func/send_message.php",
  URL_CALCULA_DOWN_PAYMENNT: "func/calculate_mortgage.php",

  URL_ADD_FAVORITES: "api/Users/{0}/favorites/add/{1}",

  URL_SITE_COMPROPERTEE: "https://www.gooneworld.com/",
  URL_SITE_GOONEWORLD: "https://www.gooneworld.com/",
  URL_SITE_COMPROPERTEE_TEST: "http://104.248.211.159:36459/",

  //https://api.gooneworld.com/api

  URL_DASHBOARD: "https://go-crm.gooneworld.com",
  //URL_DASHBOARD: "http://localhost:3005",

  URL_SEARCH_SUBDIVISION: "api/Properties/subdivisions",

  property_types: [
    "industrial",
    "retail",
    "shopping",
    "multi",
    "speciality",
    "income",
    "land",
    "office",
    "hospitality",
    "health",
    "entertainment"
  ],

  /* Feathers */
  URL_BASE_NEW_API:
    window.location.hostname === "localhost"
      ? "http://localhost:3030"
      : "https://api-dev.gooneworld.com",
  URL_AUTHENTICATION: "/authentication"

  /* URL_API_PHOTOS: `${
  window.location.hostname === "localhost"
    ? "http://localhost:3030"
    : "https://imagecdn.gooneworld.com"
  }/cdn/` //https://imagecdn.gooneworld.com/ */
};
