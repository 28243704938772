import React, { Component } from "react";
import { withRouter } from "react-router";
import { Drawer, Menu, Icon, Modal, message, Tabs } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProfileForm from "../components/com/profileform/ProfileForm";
import ChangePasswordForm from "../components/com/authentication/changepassword/ChangePasswordForm";
import { isMobile } from "react-device-detect";
import Constants from "../utils/constants";
import "./profilemenu.css";
import { Logout } from "../services/services";

const Logo = require("../images/logo.svg");

const { confirm } = Modal;
const { TabPane } = Tabs;

class ProfileMenu extends Component {
  state = {
    current: "",
    user: {}
  };
  closeModal = () => {
    /* if (this.modal) this.modal.destroy(); */
  };
  componentDidMount() {
    let { match } = this.props;
    let user = JSON.parse(localStorage.getItem("user"));
    let {
      params: { transaction_type }
    } = match;
    this.setState({
      current: transaction_type,
      user
    });
  }
  handleMenuClick = e => {
    let value = e.key;
    let { history, match } = this.props;
    let { user } = this.state;
    user = user || JSON.parse(localStorage.getItem("user"));
    this.setState({
      user
    });
    let {
      params: { City, StateOrProvince, transaction_type }
    } = match;

    switch (value) {
      case "logout":
        confirm({
          title: "Atention!",
          centered: true,
          content: "Do you want to close session?",
          onOk() {
            return Logout()
              .then(response => {
                localStorage.clear();
                history.push("/login");
              })
              .catch(err => message.error(err.message));
            /* logout({
              access_token: localStorage.access_token
            })
              .then(response => {
                let { data } = response;
                localStorage.clear();
                history.push("/login");
              })
              .catch(err => {
                message.error(err.message);
                localStorage.clear();
                history.push("/");
              }); */
          },
          onCancel() {}
        });
        break;
      case "profile":
        return history.push(`/profile/${user._id}`);
        break;

        break;
      case "dashboard":
       /*  window.location.href = `${Constants.URL_DASHBOARD}/?access_token=${localStorage.access_token}`; */
        break;
      case "signin":
        return history.push("/login");
        break;
      case "signup":
        return history.push("/signup");
        break;
      case "about":
        return history.push("/about");
        break;
      case "forgot":
        return history.push("/password-reset");
        break;
      case "contactus":
        return history.push("/contactus");
        break;
      default:
        this.setState({
          current: value
        });
        if (value !== transaction_type)
          window.location.href = `/listings/search/${City}/${StateOrProvince}/${value}`;
        /* history.replace(
            `/listings/search/${City}/${StateOrProvince}/${value}`
          ); */
        console.log("transaction_type: ", transaction_type);
        break;
    }
  };
  render() {
    let { current } = this.state;
    let { match, onVisible, onClose, onTitle } = this.props;
    let {
      params: { transaction_type }
    } = match;

    return (
      <Drawer
        title={onTitle}
        /*  placement={"right"} */
        closable={false}
        maskClosable={true}
        onClose={onClose}
        visible={onVisible}
      >
        <Menu onClick={this.handleMenuClick} selectedKeys={[current]}>
          <Menu.Item
            key="logo"
            disabled
            className="logo-item"
            style={{
              textAlign: "center"
            }}
          >
            <img
              src={Logo}
              style={{
                width: 130
                /* height: 60 */
              }}
            />
          </Menu.Item>
          {isMobile && (
            <Menu.Item key="about">
              <FontAwesomeIcon
                className="icon-fa-gray"
                size="lg"
                icon={["fas", "info-circle"]}
              />{" "}
              About us
            </Menu.Item>
          )}
          {isMobile && (
            <Menu.Item key="contactus">
              <FontAwesomeIcon
                className="icon-fa-gray"
                size="lg"
                icon={["fas", "envelope"]}
              />{" "}
              Contacts
            </Menu.Item>
          )}
          {localStorage.userId && (
            <Menu.Item key="profile">
              <Icon type="user" />
              Profile
            </Menu.Item>
          )}
          {localStorage.userId && (
            <Menu.Item key="dashboard">
              <a
                target="_blank"
                href={`${Constants.URL_DASHBOARD}/?access_token=${localStorage.access_token}`}
              >
                <Icon type="dashboard" />
                Dashboard
              </a>
            </Menu.Item>
          )}
          {transaction_type && (
            <Menu.Item key="Sale">
              <FontAwesomeIcon
                className="icon-fa-gray"
                size="lg"
                icon={["fas", "coins"]}
              />{" "}
              For Sale
            </Menu.Item>
          )}
          {transaction_type && (
            <Menu.Item key="Lease">
              <FontAwesomeIcon
                className="icon-fa-gray"
                size="lg"
                icon={["fas", "home"]}
              />{" "}
              For Rent
            </Menu.Item>
          )}
          {!localStorage.userId && (
            <Menu.Item key="signin">
              <FontAwesomeIcon
                className="icon-fa-gray"
                size="lg"
                icon={["fas", "sign-in-alt"]}
              />{" "}
              Sign In
            </Menu.Item>
          )}

          {!localStorage.userId && (
            <Menu.Item key="signup">
              <FontAwesomeIcon
                className="icon-fa-gray"
                size="lg"
                icon={["fas", "user-plus"]}
              />{" "}
              Sign Up
            </Menu.Item>
          )}
          {!localStorage.userId && (
            <Menu.Item key="forgot">
              <FontAwesomeIcon
                className="icon-fa-gray"
                size="lg"
                icon={["fas", "lock"]}
              />{" "}
              Forgot Password?
            </Menu.Item>
          )}
          {localStorage.userId && (
            <Menu.Item key="logout">
              <Icon type="close" />
              Log Out
            </Menu.Item>
          )}
        </Menu>
      </Drawer>
    );
  }
}

export default withRouter(ProfileMenu);
