import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  Card,
  Icon,
  Row,
  Button,
  Carousel,
  message,
  Modal,
  Tag,
  Col
} from "antd";
import { Link } from "react-router-dom";
import "./listingcard.css";
import Constants from "../../utils/constants";
import Helper from "../../utils/Helper";
import { add_favorite } from "../../services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageLoader from "react-load-image";
import Preloader from "../com/preloading/Preloader";
import moment from "moment";
import { URL_COMING_SOON } from "../../utils/constants";
const house = require("../../images/house.svg");

const { confirm } = Modal;
const { Meta } = Card;
const { money } = Helper;

function replace(text) {
  return text
    .replace("ct", "Ct")
    .replace("nw", "NW")
    .replace("sw", "SW")
    .replace("se", "SE")
    .replace("ne", "NE")
    .replace("pl", "Pl")
    .replace("Avee", "Ave")
    .replace("blvd", "Blvd")
    .replace("ht", "Ht");
}
/* const URL_COMING_SOON = require("../../images/coming_soon.png"); */
/* const URL_CLOSED = require("../../images/closed.jpg"); */
const ImageError = () => (
  <div
    className="cover-card coming-soon"
    alt="example"
    style={{
      backgroundImage: `url(${URL_COMING_SOON})`,
      width: "100%",
      height: "100%",
      backgroundSize: "100% 100%",
      backgroundPosition: "center"
    }}
  />
);
const BackgroundImage = ({ src, style = {}, ...props } = {}) => (
  <div
    style={Object.assign({ backgroundImage: `url(${src})` }, style)}
    {...props}
  />
);
const Image = props => (
  <ImageLoader {...props}>
    <BackgroundImage
      className="cover-card"
      style={{
        width: "100%",
        height: "250px",
        backgroundSize: "100% 100%",
        backgroundPosition: "center"
      }}
    />
    <div
      style={{
        /*  backgroundImage: house, */
        width: "100%",
        height: "210px",
        background: "#fafafa",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        position: "relative"
      }}
    >
      <ImageError />
    </div>

    <div
      style={{
        backgroundImage: house,
        width: "100%",
        minHeight: "100px",
        maxHeight: "200px",
        background: "#fafafa",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        position: "relative"
      }}
    >
      <Preloader />
    </div>
  </ImageLoader>
);

const WrapperCover = props => {
  let {
    PhotosCount,
    City,
    StateOrProvince,
    TransactionType,
    Slug,
    ListingKeyNumeric,
    current,
    StandardStatus,
    ListingType,
    query
  } = props;
  const ext = ListingType === "GOW" ? "jpeg" : "jpg";
  let URL_PHOTO =
    PhotosCount > 0
      ? `${Constants.URL_API_PHOTOS}${ListingKeyNumeric}/0.${ext}?width=400&height=400`
      : URL_COMING_SOON;
  if (StandardStatus === "Closed") {
    PhotosCount = 0;
    URL_PHOTO = TransactionType == "Sale" ? "/images/closed-sale.svg" : "/images/closed-rent.svg";
  }
  let images = Array.apply(null, { length: PhotosCount })
    .map((item, index) => {
      if (index === 0) return;
      return `${Constants.URL_API_PHOTOS}${ListingKeyNumeric}/${index}.${ext}?width=400&height=400`;
    })
    .filter(item => item != undefined);
  images.push(URL_PHOTO);

  const myRef = React.createRef();
  const imageRef = React.createRef();

  /* imageRef.onload = function() {
    alert("Loaded!");
  }; */
  return (
    <>
      {props.displayGalery ? (
        <>
          <Link
            to={
              StandardStatus === "Active"
                ? `/listings/details/${City}/${StateOrProvince}/${TransactionType}/${Slug}/${ListingKeyNumeric}${query}`
                : `/detail/${Slug}/${ListingKeyNumeric}/${StandardStatus}`
            }
          >
            <Image src={URL_PHOTO} />
          </Link>
          {/* <div
            className="cover-card"
            alt="example"
            ref={imageRef}
            onLoad={() => {
              alert("loaded!");
            }}
            style={{
              backgroundImage: `url(${URL_PHOTO})`,
              width: "100%",
              height: "100%",
              backgroundSize: "cover",
              backgroundPosition: "center"
            }}
          /> */}
          {props.children}
          {props.displayActions && PhotosCount > 1 && (
            <div className="tools-carousel Aenable">
              <Button
                className="btn-left"
                type="link"
                icon="left"
                onClick={() => {
                  if (props.handleClick) {
                    console.log("Images: ", images);
                    props.handleClick();
                  }
                }}
              />
              <Button
                className="btn-right"
                type="link"
                icon="right"
                onClick={() => {
                  props.handleClick();
                }}
              />
            </div>
          )}
          {props.displayActions && StandardStatus === "Active" && (
            <div className="footer-cover">
              <div
                style={{
                  float: "right",
                  display: "inline-block",
                  padding: "5px"
                }}
              >
                {PhotosCount} {PhotosCount > 1 ? "Photos" : "Photo"}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <Carousel
            ref={myRef}
            afterChange={props.onChange}
            className="carousel"
            autoplay={false}
            dots={false}
            displayGalery={props.displayGalery}
            style={{
              height: 400,
              background: "red"
            }}
          >
            {images.map((url, index) => {
              return (
                <div key={index} className="carousel-card">
                  {/* <div
                    className="cover-card"
                    alt="example"
                    style={{
                      backgroundImage: `url(${url})`,
                      width: "100%",
                      height: "100%",
                      backgroundSize: "cover",
                      backgroundPosition: "center"
                    }}
                  /> */}
                  <Image src={url} />
                </div>
              );
            })}
          </Carousel>
          <div className="footer-cover">
            {PhotosCount > 0 && (
              <div
                style={{
                  float: "right",
                  display: "inline-block",
                  padding: "5px"
                }}
              >
                <span>{current === PhotosCount - 1 ? " 1 " : current + 2}</span>
                {" of "}
                {PhotosCount} {PhotosCount > 1 ? "Photos" : "Photo"}
              </div>
            )}
          </div>
          <div>{props.children}</div>
          <div className="tools-carousel">
            <Button
              className="btn-left"
              type="link"
              icon="left"
              onClick={() => {
                myRef.current.prev();
              }}
            />
            <Button
              className="btn-right"
              type="link"
              icon="right"
              onClick={() => {
                //props.next(current++);
                myRef.current.next();
              }}
            />
          </div>
        </>
      )}
    </>
  );
};
class ListingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      displayGalery: true
    };
  }

  /* handleAddFavorite = () => {
    let { ListingKeyNumeric } = this.props;
    if (this.props.handleAddFavorite)
      this.props.handleAddFavorite(ListingKeyNumeric);
  }; */
  handleAddFavorite = ListingKeyNumeric => {
    const me = this;
    let { history, location } = this.props;
    //let { ListingKeyNumeric } = this.props;
    let paramas = {
      hostApp: "GOW",
      user_id: localStorage.userId,
      ListingKeyNumeric
    };
    if (!localStorage.userId)
      return confirm({
        width: 400,
        title: (
          <h3
            style={{
              fontSize: 22
            }}
          >
            Do You Like This Listing?
          </h3>
        ),
        content: (
          <h4
            style={{
              fontSize: 20
            }}
          >
            Sign In to Save It
          </h4>
        ),
        onOk() {
          window.previousLocation = location;
          history.push("/login");
        }
      });
    me.add_to_favorite(paramas);
  };
  add_to_favorite = paramas => {
    add_favorite(paramas)
      .then(({ msg }) => {
        message.info(msg || "Listing added to my favorites");
      })
      .catch(error => message.info("Listing removed from favorites"));
  };
  onChange = current => {
    this.setState({
      current
    });
  };

  render() {
    let address = "";
    let {
      StreetNumber,
      StreetDirPrefix,
      StreetName,
      UnitNumber,
      City = "",
      StateOrProvince = "",
      transaction_type,
      Matrix_Unique_ID,
      ListingKeyNumeric,
      PhotosCount,
      Slug,
      viewDetail,
      showActions,
      PropertyType,
      //displayGalery,
      StandardStatus,
      SubdivisionName,
      DaysOnMarket,
      ListingContractDate,
      BedroomsTotal,
      BathroomsFull
    } = this.props;

    let { displayGalery } = this.state;
    let showTransactionType = this.props.showTransactionType || false;
    address += StreetNumber !== "No Data" ? " " + StreetNumber : "";
    address += StreetDirPrefix !== "No Data" ? " " + StreetDirPrefix : "";
    address += StreetName !== "No Data" ? " " + StreetName : "";

    UnitNumber =
      typeof UnitNumber !== "undefined" && UnitNumber != 0 && UnitNumber != null
        ? `#${UnitNumber}`
        : "";

    address += " " + UnitNumber;

    /*  var adress_dir = [StreetNumber, StreetDirPrefix, StreetName, UnitNumber]; */

    /*  address +=
      UnitNumber !== "No Data" && UnitNumber !== "" && UnitNumber != null
        ? " # " + UnitNumber
        : ""; */

    var address_show = address.split(",")[0];
    var address_listing = address_show.substr(0, 30).replace(/null/g, "");
    address_listing = address_listing.replace(/undefined/g, "");
    var lenadd = address_show.length;
    var puntos = lenadd > 30 ? ".." : "";
    //console.log("--> ", StreetNumber, StreetDirPrefix, StreetName, UnitNumber);

    var typeprop = "";
    var size_sqft = "";

    if (
      this.props.StyleofProperty !== "" &&
      this.props.StyleofProperty !== "No Data"
    ) {
      typeprop = this.props.StyleofProperty;
    } else if (
      this.props.TypeofProperty !== "" &&
      this.props.TypeofProperty !== "No Data"
    ) {
      typeprop = this.props.TypeofProperty;
    } else {
      typeprop = "Other";
    }

    if (this.props.LivingArea != null && this.props.LivingArea > 0) {
      size_sqft = Helper.decimal(this.props.LivingArea) + " Sqft";
    } else {
      size_sqft = "";
    }

    showActions =
      typeof this.props.showActions !== "undefined"
        ? this.props.showActions
        : true;
    displayGalery = typeof displayGalery !== "undefined" ? displayGalery : true;
    transaction_type = PropertyType === "Residential" ? "Sale" : "Rent";
    let TransactionType = PropertyType === "Residential" ? "Sale" : "Lease";
    /* console.log(
      "Link: ",
      `/listings/details/${City}/${StateOrProvince}/${transaction_type}/${Slug}/${ListingKeyNumeric}`
    ); */
    let { location } = this.props;
    let query = location.search || "";
    return (
      <Card
        hoverable={this.props.hoverable || true}
        className="listing-card"
        cover={
          <WrapperCover
            query={query}
            TransactionType={TransactionType}
            next={current => {
              this.setState({
                current
              });
            }}
            {...this.props}
            current={this.state.current}
            onChange={this.onChange}
            handleClick={() => {
              let displayGalery =
                typeof this.props.displayGalery !== "undefined"
                  ? this.props.displayGalery
                  : true;
              if (displayGalery) this.setState({ displayGalery: false });
            }}
            displayGalery={displayGalery}
          >
            {viewDetail && (
              <div className="cover-container">
                <div className="cover-detail">
                  <Button
                    size="large"
                    className="btn-detail"
                    onClick={e => console.log(e.target)}
                  >
                    VIEW DETAILS
                  </Button>
                </div>
              </div>
            )}
            {showActions && StandardStatus === "Active" && (
              <div className="card-actions">
                <div>
                  <Button
                    className="heart-btn"
                    onClick={() => this.handleAddFavorite(ListingKeyNumeric)}
                    type="link"
                  >
                    <Icon type="heart" theme="filled" />
                  </Button>
                </div>
                {showTransactionType && (
                  <div
                    className="tag-cover"
                    style={{
                      float: "left",
                      marginLeft: 8,
                      verticalAlign: "middle"
                    }}
                  >
                    <Tag color="green">{`FOR ${transaction_type}`}</Tag>
                  </div>
                )}
              </div>
            )}
          </WrapperCover>
        }
      >
        <Meta
          onClick={e => {
            console.log("Card Cick!");
            /* `/listings/details/${City}/${StateOrProvince}/${TransactionType}/${Slug}/${ListingKeyNumeric}${query} */
          }}
          title={
            <div className="title-card">
              <h3 className="list-price txt-ellipsis">
                {StandardStatus === "Closed"
                  ? `${TransactionType == "Lease" ? "Rented" : "Sold"} ${money(
                    this.props.ListPrice
                  )}`
                  : `${money(this.props.ListPrice)}`}
              </h3>
            </div>
          }
          description={
            <Row>
              <Col span={24} className="type-card">
                {typeof BedroomsTotal !== "undefined" &&
                  BedroomsTotal !== 0 &&
                  BedroomsTotal !== null ? (
                  <small>
                    <FontAwesomeIcon
                      className="icon-fa"
                      size="lg"
                      icon={["fas", "bed"]}
                    />{" "}
                    <span>
                      {BedroomsTotal} {BedroomsTotal > 1 ? "bd" : "bd"}
                    </span>
                  </small>
                ) : null}
                {!BedroomsTotal && !BedroomsTotal && !size_sqft && (
                  <small>
                    <FontAwesomeIcon
                      className="icon-fa"
                      icon={["fas", "house-damage"]}
                    />{" "}
                    without detail.
                  </small>
                )}
                {BathroomsFull && (
                  <small
                    className={
                      typeof BedroomsTotal !== "undefined" &&
                        BedroomsTotal !== 0
                        ? "item-property divider-left"
                        : "item-property"
                    }
                  >
                    <FontAwesomeIcon
                      className="icon-fa"
                      size="lg"
                      icon={["fas", "bath"]}
                    />{" "}
                    <span>
                      {BathroomsFull} {BathroomsFull > 1 ? "ba" : "ba"}
                    </span>
                  </small>
                )}

                {size_sqft && (
                  <small
                    className={
                      BedroomsTotal
                        ? "item-property divider-left"
                        : "item-property"
                    }
                  >
                    <FontAwesomeIcon
                      className="icon-fa"
                      size="lg"
                      icon={["fas", "ruler"]}
                    />{" "}
                    <span>{size_sqft}</span>
                  </small>
                )}
              </Col>
              <Col span={24} className="card-address">
                {City && StateOrProvince && (
                  <Link
                    className={"txt-ellipsis"}
                    to={
                      StandardStatus === "Active"
                        ? `/listings/details/${City.replace(
                          /\//g,
                          "-"
                        )}/${StateOrProvince.replace(
                          /\//g,
                          "-"
                        )}/${TransactionType}/${Slug}/${ListingKeyNumeric}${query}`
                        : `/detail/${Slug}/${ListingKeyNumeric}/${StandardStatus}`
                    }
                  >
                    {address_listing}{" "}
                    {/*  {moment(ListingContractDate).format("YYYY-MM-DD")} */}
                  </Link>
                )}
                {/*  <div>{SubdivisionName}</div> */}
              </Col>
              <Col span={24} className="comple_add">
                {this.props.City} {this.props.StateOrProvince}{" "}
                {this.props.PostalCode}
              </Col>
            </Row>
          }
        />
      </Card>
    );
  }
}
export default withRouter(ListingCard);
