import React, { Component } from "react";
import { Input, Icon, Button, Row, Col, message } from "antd";
/* import "./changepassword.css"; */
import { AdvancedForm } from "../../index";

import { withRouter, Link } from "react-router-dom";

import { change_password } from "../../../../services";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: null,
      rp_password: null
    };
  }

  onSubmit = (err, params) => {
    let { history, match } = this.props;

    if (params.rp_password !== params.password)
      return message.error("Passwords no match");
    if (!err) {
      delete params.rp_password;
      params = {
        ...match.params,
        ...params
      };
      change_password(params)
        .then(response => {
          if (!response.success) return message.error(response.message);
          /*  if (typeof this.props.onSubmit !== "undefined")
            this.props.onSubmit(params); */
          localStorage.clear();
          history.push("/login");
          message.info(response.message);
        })
        .catch(err => {
          message.error(err.message);
        });
    }
  };
  render() {
    return (
      <AdvancedForm
        formLayout="vertical"
        onSubmit={this.onSubmit}
        footer={
          <div>
            <Row>
              <Col>
                <Button
                  size="large"
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  CHANGE PASSWORD
                </Button>
              </Col>
            </Row>
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{
                margin: 10
              }}
            >
              <Col>
                Do u have an account? <Link to="/login">Sign In</Link>
              </Col>
            </Row>
          </div>
        }
      >
        <div>
          <Input.Password
            size="large"
            style={{ width: "100%" }}
            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="New Password"
            type="password"
            name="password"
            validator={[
              { required: true, message: "Please input your current password!" }
            ]}
          />

          <Input.Password
            size="large"
            style={{ width: "100%" }}
            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Repeat Password"
            type="password"
            hasFeedback
            name="rp_password"
            validator={[
              { required: true, message: "Please input your new password!" }
            ]}
          />
        </div>
      </AdvancedForm>
    );
  }
}
export default withRouter(ChangePassword);
