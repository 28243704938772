import React, { Component } from "react";
import { AdvancedForm } from "../../index";
import { Input, Icon, Button, Row, Col } from "antd";
import { Link } from "react-router-dom";

import "./registerform.css";
import SimpleForm from "../../form/SimpleForm";
class RegisterForm extends Component {
  render() {
    return (
      <SimpleForm
        onSubmit={this.props.onSubmit}
        className="form-login"
        autoSubmit={false}
        footer={
          <div>
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{
                margin: 10
              }}
            >
              <Col align="center" span={24}>
                <Button
                  block
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  JOIN
                </Button>
              </Col>
              <Col
                style={{
                  padding: "10px 0px"
                }}
              >
                <span>Do u have an account? </span>
                <Link to="/login">Sign In</Link>
              </Col>
            </Row>
          </div>
        }
      >
        <Input name="origin" initial={window.location.origin} type="hidden" />
        <Input
          size="large"
          style={{ width: "100%" }}
          prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="First Name"
          name="first_name"
          validator={[
            { required: true, message: "Please input your first name!" }
          ]}
        />
        <Input
          size="large"
          style={{ width: "100%" }}
          prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="Last Name"
          name="last_name"
          validator={[
            { required: true, message: "Please input your last name!" }
          ]}
        />
        <Input
          size="large"
          style={{ width: "100%" }}
          prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="Email"
          name="email"
          validator={[
            { required: true, message: "Please input your email!" }
          ]}
        />
        <Input.Password
          size="large"
          name="password"
          style={{ width: "100%" }}
          prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="Password"
          type="password"
          validator={[
            { required: true, message: "Please input your password!" }
          ]}
        />
        <Input.Password
          type="password"
          size="large"
          name="rp_password"
          style={{ width: "100%" }}
          prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="Confirm Password"
          validator={[
            { required: true, message: "Please input your password!" }
          ]}
        />
      </SimpleForm>
    );
  }
}
export default RegisterForm;
