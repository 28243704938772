import React, { useEffect } from "react";
import "./App.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";

/* import './styles/main.scss'; */
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'

import Home from "./pages/home";
import Listings from "./pages/listings";
import ListingDetails from "./pages/listingdetails";
import ListingZipCode from "./pages/zipcode/ZipCode";
import Login from "./pages/Authentication/Login/Login";
import Signup from "./pages/Authentication/register/Register";
import PasswordReset from "./pages/Authentication/passwordreset/PasswordReset";
import ChangePassword from "./pages/Authentication/changepassword/ChangePassword";
import Subdivision from "./pages/subdivisionlistings/SubdivisionListings";
import Condos from "./pages/contesubdivision";
import LeadGeneration from "./pages/LeadGeneration/leadgeneration";
import WhyChooseOneWorld from "./pages/WhyChooseOneWorld/whychooseoneworld";
import OneEstimate from "./pages/OneEstimate/oneestimate";
import RealEstateClasses from "./pages/RealEstateClasses/realestateclasses";

import About from "./pages/about/About";
import ContactUs from "./pages/contactus/ContactUs";
import Terms from "./pages/terms/Terms";
import Privacy from "./pages/privacy/Privacy";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons";
import store from "./redux/stores";
import { message } from "antd";
import { reAuthenticate } from "./services/services";
import NotFound from "./pages/NotFound";
import Profile from "./pages/profile/Profile";
library.add(fab, fas, faCheckSquare, faCoffee);

store.subscribe(function() {
  console.log(store.getState());
});
if (!String.prototype.format) {
  String.prototype.format = function() {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function(match, number) {
      return typeof args[number] != "undefined" ? args[number] : match;
    });
  };
}
const App = () => {
  useEffect(() => {
    reAuthenticate()
      .then(({ user }) => {
        /*  message.info("You are authenticated!"); */
        localStorage.setItem("user", JSON.stringify(user));
      })
      .catch(err => {
        /* if (window.location.pathname !== "/") window.location.href = "/"; */
        /*  message.error(err.message); */
      });
    return () => {};
  }, []);
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/profile/:_id" component={Profile} />
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/about-us" component={About} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/terms-and-conditions" component={Terms} />
          <Route exact path="/privacy-policy" component={Privacy} />
          <Route exact path="/password-reset" component={PasswordReset} />
          <Route
            exact
            path="/change_password/:token"
            component={ChangePassword}
          />
          <Route exact path="/confirm-email/:token" component={Login} />
          <Route
            exact
            path="/listings/search/:City/:StateOrProvince/:transaction_type"
            component={Listings}
          />
          <Route
            exact
            path="/listings/details/:City/:StateOrProvince/:transaction_type/:Slug/:ListingKeyNumeric"
            component={ListingDetails}
          />
          <Route
            exact
            path="/listings/details/:City/:StateOrProvince/:Slug"
            component={ListingDetails}
          />
          <Route
            exact
            path="/detail/:Slug/:ListingKeyNumeric/:StandardStatus"
            component={ListingDetails}
          />
          <Route
            exact
            path="/detail/:ListingId"
            component={ListingDetails}
          />
          <Route
            exact
            path="/zipcode/:transaction_type/:PostalCode"
            component={ListingZipCode}
          />

          <Route exact path="/miami-beach/condos" component={Condos} />
          <Route
            exact
            path="/miami-beach/:SubdivisionName"
            component={Subdivision}
          />
          <Route exact path="/lead-generation" component={LeadGeneration} />
          <Route
            exact
            path="/why-choose-oneworld"
            component={WhyChooseOneWorld}
          />
          <Route exact path="/one-estimate" component={OneEstimate} />
          <Route
            exact
            path="/real-estate-classes"
            component={RealEstateClasses}
          />

          <Route default component={NotFound} />
          {/* <Route exact path="/subdivision/miami-beach/:condo" component={Subdivision}></Route> */}
        </Switch>
      </Router>
    </Provider>
  );
};

export default App;
