import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, List, Card } from "antd";
import LayoutAbout from "../../layouts/aboutlayout";
import Social from "../../components/com/social/Social";
import Map from "../../components/com/Map/IframeGoogleMap";
import Section from "../../components/Section";
import "./about.css";

const { Meta } = Card;

/* const Section = props => (
  <Row
    className={props.className || "section"}
    type="flex"
    justify="center"
    style={{ ...props.style }}
  >
    <Col span={props.span || 18}>
      <section className={"section-container"}>{props.children}</section>
    </Col>
  </Row>
); */
const geo = {
  lat: 25.77409,
  long: -80.13914
};
const teamData = [
  {
    name: "Elliot",
    url: "https://i.pravatar.cc/450?img=60",
    description: "Senior Vice President and General Manager"
  },
  {
    name: "Matthew",
    url: "https://i.pravatar.cc/450?img=11",
    description: "Vice President of Marketing"
  },
  {
    name: "Jenny",
    url: "https://i.pravatar.cc/450?img=49",
    description: "Vice President of Product"
  }
];
const CardLeadership = props => {
  return (
    <List
      className={props.className || "cardlist"}
      itemLayout="horizontal"
      grid={{ gutter: 16, column: 4 }}
      dataSource={props.dataSourse}
      renderItem={props.renderItem}
    />
  );
};
const imageLeft = require("../../images/leadership-gooneworld.jpg");
const Logo = require("../../images/logo-color.svg");
const headerBackground = require("../../images/about-us.jpg");

const style = {
  padding: "0px 20px"
};

class About extends Component {
  render() {
    return (
      <LayoutAbout>
        <header>
          <div
            className="image-header-container"
            style={{
              backgroundImage: `url(${headerBackground})`,
              width: "100%",
              height: "600px",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat"
            }}
          >
            <div>
              {/* <h2>
                It's our mission to build <br /> a more neighborly world.
              </h2> */}
            </div>
          </div>
        </header>
        <Section className="section">
          <Row
            type="flex"
            justify="center"
            gutter={16}
            align="middle"
            style={{
              margin: "160px 0px"
            }}
          >
            <Col xs={24} sm={12} md={12} lg={12} xl={this.props.span || 12}>
              <div
                className="image-left"
                style={{
                  backgroundImage: `url(${imageLeft})`
                }}
              />
            </Col>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={this.props.span || 12}
              style={style}
            >
              {/*  <Row>
                <img
                  style={{
                    float: "left",
                    padding: "0px",
                    margin: "8px 0px"
                  }}
                  src={Logo}
                  width="auto"
                  height="60px"
                />
              </Row> */}
              <Row
                type="flex"
                align="middle"
                justify="start"
                style={{
                  marginTop: "30px",
                  fontSize: "16px"
                }}
              >
                <p>
                  Today One World International and GoOneWorld are the leading
                  real estate firm in South Florida. We are involved in the
                  Miami real estate market, including home sales, house rentals,
                  new construction sales, and community growth. We specialize in
                  website development, mobile technologies, IDX integration,
                  search engine marketing, and social media presence. One World
                  International serves greater Miami area, including Miami
                  Beach, Miami Downtown, Midtown, Brickell, Key Biscayne, Coral
                  Gables, Coconut Grove, Kendall, Doral, Sunny Isles, Aventura,
                  and all Broward County. We also service Broward and Palm Beach
                  County.
                </p>
                <p>
                  One World International Realty has a proven record of adapting
                  to market changes. It provides homeowners, buyers, realtors,
                  and investors with up-to-date information about market trends
                  so that they can make informed decisions about their home for
                  sale or investment properties. We educate customers about new
                  products and services and offer tools to grow online presence
                  with lead generation and customer management.
                </p>
              </Row>
              <Row type="flex" justify="start">
                <Social className="social-network-gray" />
              </Row>
            </Col>
          </Row>
        </Section>
        {/* <Section className="section section-gray">
          <Row type="flex" justify="center">
            <Col span={24}>
              <div className="block">
                <h2>Leadership</h2>
                <CardLeadership
                  dataSourse={teamData}
                  renderItem={item => (
                    <Card
                      style={{ width: 340 }}
                      hoverable
                      cover={<img alt="example" src={item.url} />}
                    >
                      <Meta
                        title={item.name}
                        description={<Link to="/">{item.description}</Link>}
                      />
                    </Card>
                  )}
                />
              </div>
            </Col>
          </Row>
        </Section>
         */}
        <Section className="section section-gray">
          <Row type="flex" justify="center">
            <Col xs={24} sm={12} md={12} lg={12} xl={this.props.span || 24}>
              <div className="block">
                <h2>Headquarters</h2>
                <p>
                  GoOneWorld and One World International Realty, founded in 2008
                  with headquarters in Miami Beach, Florida, has a critical
                  mission, and it is to provide real estate agents with the
                  technology and tools that can increase their productivity and
                  stay connected with the customers. One World International
                  management believes that technology is a way to grow and
                  develop for real estate firms. That is why the company has
                  been concentrating on investing in web technology and
                  providing the best marketing tools to realtors in the Miami
                  area.
                </p>
                <p>
                  The growth of the business is due to the interest of real
                  estate professionals in being a part of the technology
                  revolution in the real estate industry. For many years the
                  agents had to travel to meet clients, show properties, and
                  transact in person. Now all the listings can be found on the
                  web and can be added or updated in real-time. Real estate
                  agents can access information on smartphones and respond to
                  the potential customer immediately. One World International
                  provides a platform that allows access to thousands of real
                  estate listings in the Miami area, easy website navigation,
                  lead generation, and customer management tools to keep in
                  touch with clients.
                </p>
              </div>
            </Col>
          </Row>
        </Section>
        <Section className="section ">
          <Row type="flex" justify="center">
            <Col xs={24} sm={12} md={12} lg={12} xl={this.props.span || 24}>
              <div className="map-container">
                <Map lat={geo.lat} long={geo.long} />
              </div>
            </Col>
          </Row>
        </Section>
      </LayoutAbout>
    );
  }
}
export default About;
