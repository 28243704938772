import React, { Component } from "react";
import { Row, Col } from "antd";

export default class Section extends Component {
  render() {
    return (
      <Row
        className={this.props.className || "section"}
        type="flex"
        justify="center"
        style={{ ...this.props.style }}
      >
        <Col span={this.props.span || 18}>
          <section className={"section-container"}>
            {this.props.children}
          </section>
        </Col>
      </Row>
    );
  }
}
