import React, { useEffect, useState, useRef } from "react";
import {
  withScriptjs,
  GoogleMap,
  withGoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import "./map.css";
import Preloader from "../preloading/Preloader";
import Constants from "../../../utils/constants";
import ListingMap from "../../listings/ListingMap";
import { getService } from "../../../services";
import { message } from "antd";
import _ from "lodash";
import axios from "axios";
const { compose, withProps, withStateHandlers } = require("recompose");
const styles = [
  { featureType: "water", stylers: [{ color: "#7fbde0" }] },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#fff" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#ffffff" }],
  },
];
/* const styles = require("./styleMap.json"); */
/* const styles = require("./themes/theme-blue.json"); */
const GoogleMapWrapper = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBbsQSRn1eit85DvTMfTFR07HYMbjVkzqA&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div id="map" style={{ height: `100%` }} />,
  }),
  withStateHandlers(
    () => ({
      isOpen: false,
      loading: true,
      current: "",
      markers: [],
    }),
    {
      onToggleOpen:
        ({ isOpen, current }) =>
        (id) => ({
          isOpen: !isOpen,
          current: id,
        }),
    }
  ),
  withScriptjs,
  withGoogleMap
)((props) => {
  const [dataSource, setDataSource] = useState([]);
  const [center, setCenter] = useState({ LatLng: 0, lat: 0, lng: 0 });
  const [map, setMap] = useState(null);
  let bounds = new window.google.maps.LatLngBounds();
  let infoWindow = new window.google.maps.InfoWindow();
  let service = new window.google.maps.DirectionsService();
  const iconSize = 14;
  let iconMarker = new window.google.maps.MarkerImage(
    "/images/red-point.svg",
    null /* size is determined at runtime */,
    null /* origin is 0,0 */,
    null /* anchor is bottom center of the scaled image */,
    new window.google.maps.Size(iconSize, iconSize)
  );
  let iconMarkerSelected = new window.google.maps.MarkerImage(
    "/images/white-point.svg",
    null /* size is determined at runtime */,
    null /* origin is 0,0 */,
    null /* anchor is bottom center of the scaled image */,
    new window.google.maps.Size(iconSize, iconSize)
  );
  const createMarker = (latLng) => {
    var marker = new window.google.maps.Marker({
      position: latLng,
      map: map,
      draggable: true,
    });
    bounds.extend(marker.getPosition());
    window.google.maps.event.addListener(marker, "click", function (evt) {
      var address;
      var that = this;
      var geocoder = new window.google.maps.Geocoder();
      geocoder.geocode(
        {
          latLng: this.position,
        },
        function (results, status) {
          if (status == window.google.maps.GeocoderStatus.OK) {
            address = results[0].formatted_address;
            infoWindow.setContent("Address:" + address);
            infoWindow.open(map, that);
          } else {
            alert("Geocoder failed due to: " + status);
          }
        }
      );
    });
  };
  useEffect(() => {
    if (props.dataSource && props.dataSource.length > 0) {
      let items = props.dataSource.filter((it) => it.Longitude);
      let item = items[0];
      /* let item = props.dataSource[0]; */
      if (item) {
        let center = { LatLng: 0, lat: item.Latitude, lng: item.Longitude };
        setCenter(center);
      }
      setDataSource(props.dataSource);
    }
  }, [props.dataSource]);
  useEffect(() => {
    if (props.center) setCenter(props.center);
  }, [props.center]);

  useEffect(() => {
    /* let map = new window.google.maps.Map(document.getElementById("map"), {
      center,
      zoom: 12,
    }); */
    if (map) {
      console.log("Map:", map);
      /* const bounds = new window.google.maps.LatLngBounds();
      if (props.center) {
        bounds.extend(new window.google.maps.LatLng(props.center.lat, props.center.lng));
        MyMap.fitBounds(bounds);
      } */
    }
  }, [map]);
  return (
    <>
      {dataSource.length > 0 && (
        <GoogleMap
          defaultZoom={props.zoom || 12}
          zoom={props.zoom || 12}
          defaultCenter={center}
          onClick={props.onToggleOpen}
          defaultZoomOnClick={true}
          defaultOptions={{
            //disableDefaultUI: true, // disable default map UI
            //draggable: true, // make map draggable
            // keyboardShortcuts: false, // disable keyboard shortcuts
            //scaleControl: true, // allow scale controle
            //scrollwheel: false, // allow scroll wheel
            styles: styles, // change default map styles
          }}
        >
          <>
            {dataSource
              .filter((mark) => typeof mark.Latitude != "undefined")
              .map((mark, index) => {
                let location = {
                  lat: parseFloat(mark.Latitude),
                  lng: parseFloat(mark.Longitude),
                };
                const ext = mark.ListingType == "GOW" ? "jpeg" : "jpg";
                return (
                  <Marker
                    className={"item-marker"}
                    key={index}
                    icon={
                      props.current === index ? iconMarkerSelected : iconMarker
                    }
                    position={location}
                    onMouseOver={() => {
                      props.onToggleOpen(index);
                    }}
                    onClick={() => props.onToggleOpen(index)}
                  >
                    <>
                      {props.showInfo && props.current === index && (
                        <InfoWindow
                          onCloseClick={() => props.onToggleOpen(index)}
                        >
                          <ListingMap
                            PhotosCount={1}
                            url={`${Constants.URL_API_PHOTOS}${mark.ListingKeyNumeric}/0.${ext}?width=100&height=100`}
                            current={mark}
                            displayGalery={false}
                            showActions={false}
                            size="small"
                            record={mark}
                            {...mark}
                          />
                        </InfoWindow>
                      )}
                    </>
                  </Marker>
                );
              })}
          </>
        </GoogleMap>
      )}
    </>
  );
});
const MapFactory = ({ reference, filterDefaultValues = {}, ...props }) => {
  const [dataSource, setDataSource] = useState();
  const [loading, setLoading] = useState(true);
  const [showInfo, setShowInfo] = useState(true);
  const [center, setCenter] = useState();
  const getData = () => {
    if (reference) {
      const service = getService(reference);
      setLoading(true);
      service
        .find({
          query: {
            ...filterDefaultValues,
          },
        })
        .then(({ data, center }) => {
          if (center) {
            setCenter({
              LatLng: 0,
              ...center,
            });
          }
          setDataSource(data);
          setLoading(false);
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  };
  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);
  useEffect(() => {
    if (props.dataSource) {
      setDataSource(props.dataSource);
      setLoading(false);
    }
  }, [props.dataSource]);

  useEffect(() => {
    if (reference) getData();
  }, [reference]);
  useEffect(() => {
    if (!_.isEmpty(filterDefaultValues)) {
      getData();
    }
  }, [filterDefaultValues]);
  useEffect(() => {
    if (props.center) setCenter(props.center);
  }, [props.center]);
  useEffect(() => {
    if (typeof props.showInfo != "undefined") setShowInfo(props.showInfo);
  }, [props.showInfo]);
  return (
    <>
      {!loading ? (
        <GoogleMapWrapper
          dataSource={dataSource}
          loading={loading}
          center={center}
          showInfo={showInfo}
        />
      ) : (
        <Preloader />
      )}
    </>
  );
};
export default MapFactory;
