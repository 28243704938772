import React, { useState, Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../../../redux/actions/action_search";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMobile } from "react-device-detect";
import qs from "qs";
import moment from "moment";
/*
import "icheck/skins/all.css"; // or single skin css
 import "jquery-ui/themes/base/core.css";
import "jquery-ui/themes/base/theme.css";
import "jquery-ui/themes/base/autocomplete.css";
import "jquery-ui/ui/core";
import "jquery-ui/ui/widgets/autocomplete"; */
import "./search.css";
import debounce from "lodash/debounce";
import { DropDownMenu } from "../index";
import {
  Button,
  Input,
  Menu,
  Dropdown,
  Icon,
  Checkbox,
  Row,
  Col,
  DatePicker,
  InputNumber,
  message,
  Tabs,
  Modal,
  Affix,
  Radio
} from "antd";
import {
  AdvancedForm,
  SearchList,
  SearchFieldCategory,
  DropDownList
} from "../index";

import { api_search, save_listing_search } from "../../../services/index";
import { resolve, reject } from "q";
import PriceRange from "./PriceRange";
import ListTypes from "./ListTypes";
import DropdownMenuList from "./DropDownMenuList";
import RadioGroupList from "./RadioGroupList";
import MoreMenu from "./MoreMenu";
const { TabPane } = Tabs;
const { confirm } = Modal;
const TabPanel = props => <TabPane {...props}>{props.children}</TabPane>;

const MAX_LISTINGS = 30;
const DEFAULT_VALUES = ["SingleFamilyResidence", "Condominium", "Townhouse"];
const ButtonGroup = Button.Group;
const RadioGroup = Radio.Group;
/* let showNumber = false; */
const group_items = [
  {
    icon: "plus",
    text: "1+",
    value: "1+",
    type: "plus"
  },
  {
    icon: "plus",
    text: "2+",
    value: "2+",
    type: "plus"
  },
  {
    icon: "plus",
    text: "3+",
    value: "3+",
    type: "plus"
  },
  {
    icon: "plus",
    text: "4+",
    value: "4+",
    type: "plus"
  },
  {
    icon: "plus",
    text: "5+",
    value: "5+",
    type: "plus"
  }
];

const types = [
  {
    value: "Sale",
    text: "For Sale",
    color: "red"
  },
  {
    value: "Lease",
    text: "For Rent",
    color: "yellow"
  },
]
class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TotalRecords: "",
      TotalPages: "",
      CurrentPage: "",
      Listings: [],
      page: 1,
      transaction_type: [],
      propertyType: [],
      listingType: [],
      min_sqft: null,
      max_sqft: null,
      listing_date: null,
      unCheckedType: "",
      keywords: null,
      reo: null,
      short_sale: null,

      menuType: false,
      Type: false,
      moreFilters: false,
      menuPrice: false,
      menuBedrooms: false,
      showBathrooms: false,
      showMenu: false,
      filters: {
        TransactionType: "Sale",
        PropertySubType: [],
        PriceFrom: undefined,
        PriceTo: undefined
      },
      ShortSaleYN: false,
      REOYN: false,
      options: [],
      address: "",
      more: false
    };
    //this.changeProps = debounce(this.changeProps, 300);
    //this.filter = debounce(this.filter, 800);
    //this.handleKeywords = debounce(this.handleKeywords, 800);
    //this.handleSqArea = debounce(this.handleSqArea, 800);
    this.handlePrice = debounce(this.handlePrice, 800);
  }

  transactionTypeFilterDefault() {
    const { params } = this.props.match;
    let transactionType = [];
    const { transaction_type } = params;
    if (transaction_type) transactionType = transaction_type.split("-");

    return transactionType;
  }

  minSqftFilterDefault() {
    const { params } = this.props.match;
    let min_sqft = "";
    let max_sqft = "";
    let listing_date = "";
    let keywords = "";
    let short_sale = false;
    let reo = false;
    let propertyType = [];

    if (params && params.dynamicParams) {
      let { dynamicParams } = params;
      dynamicParams = dynamicParams.split(",");
      dynamicParams.forEach(params => {
        let queryParams = params.split("=");
        for (let i = 0; i < queryParams.length; i++) {
          if (queryParams[i] === "min_sqft") {
            min_sqft = queryParams[i + 1];
          }
          if (queryParams[i] === "max_sqft") {
            max_sqft = queryParams[i + 1];
          }
          if (queryParams[i] === "listing_date") {
            listing_date = queryParams[i + 1];
          }
          if (queryParams[i] === "keywords") {
            keywords = queryParams[i + 1];
          }
          if (queryParams[i] === "short_sale") {
            short_sale = true;
          }
          if (queryParams[i] === "reo") {
            reo = true;
          }
          if (queryParams[i] === "propertyType") {
            propertyType = queryParams[i + 1].split("&");
          }
          // if (queryParams[i] === 'transactionType') {
          //     transaction_type = queryParams[i + 1].split('-');
          // }
        }
      });
    }
    // console.log(transaction_type, '***** TRANSACTION TYPE *****');
    return {
      min_sqft,
      max_sqft,
      listing_date,
      keywords,
      short_sale,
      reo,
      propertyType
    };
  }

  handleInput = e => {
    this.setState({ value: e.target.value });
  };

  handleAddressChange = e => {
    if (e.key === "Enter") {
      const { history } = this.props;
      const { url } = this.props.match;
      let path = ``;
      let address = e.target.value;
      let location = url.split("/");
      if (address) {
        address = address.split(",");
      }
      location[3] = address[0] ? address[0].split(" ").join("-") : "Miami";
      location[4] = address[1] || "FL";

      location.forEach(loc => {
        if (loc !== "") {
          path = `${path}/${loc}`;
        }
      });

      history.push(path);
    }
  };

  checkType(e) {
    this.setState({
      Listings: []
    });
    const value = e.target.id.replace(/^\w/, c => c.toLowerCase());
    if (!this.propertyTypeChecked(value)) {
      this.setState(
        { propertyType: [...this.state.propertyType, value] },
        () => {
          e = { target: { value: this.state.propertyType.join("&") } };
          this.moreFiltersUrlHandler(e, "propertyType");
        }
      );
    } else {
      this.setState(
        {
          propertyType: this.state.propertyType.filter(type => {
            return type !== value;
          })
        },
        () => {
          e = { target: { value: this.state.propertyType.join("&") } };
          this.moreFiltersUrlHandler(e, "propertyType");
        }
      );
    }
  }

  handleSale(e) {
    this.setState({
      Listings: []
    });

    const { url } = this.props.match;
    let location = url.split("/");
    let transaction_type = location[5].split("-");
    let path = ``;

    if (transaction_type.indexOf("Sale") !== -1) {
      this.setState({ unCheckedType: "" });
    } else {
      this.setState({ unCheckedType: "Sale" });
    }

    if (
      (transaction_type[1] === "Lease" || transaction_type[0] === "Lease") &&
      transaction_type.indexOf("Sale") !== -1
    ) {
      location[5] = "Lease";
    } else if (
      transaction_type[1] === "Lease" ||
      transaction_type[0] === "Lease"
    ) {
      if (this.state.unCheckedType === "Lease") {
        location[5] = "Sale";
      } else {
        location[5] = `Sale-Lease`;
      }
    } else {
      location[5] = "Sale";
    }

    this.setState({ transaction_type: location[5].split("-") });

    location.forEach(loc => {
      if (loc === "") {
      } else path = `${path}/${loc}`;
    });

    const { history } = this.props;
    history.push(path);
  }

  handleLease(e) {
    this.setState({
      Listings: []
    });
    const { url } = this.props.match;
    let location = url.split("/");
    let transaction_type = location[5].split("-");
    let path = ``;

    if (transaction_type.indexOf("Lease") !== -1) {
      this.setState({ unCheckedType: "" });
    } else {
      this.setState({ unCheckedType: "Lease" });
    }

    if (
      (transaction_type[1] === "Sale" || transaction_type[0] === "Sale") &&
      transaction_type.indexOf("Lease") !== -1
    ) {
      location[5] = "Sale";
    } else if (
      transaction_type[1] === "Sale" ||
      transaction_type[0] === "Sale"
    ) {
      if (this.state.unCheckedType === "Sale" && e.target.checked) {
        location[5] = "Lease";
      } else {
        location[5] = `Sale-Lease`;
      }
    } else if (transaction_type[0] === "Lease" && !e.target.checked) {
      location[5] = "Sale";
    } else if (transaction_type[0] === "Lease" && e.target.checked) {
      location[5] = "Lease";
    } else {
      location[5] = "Sale";
    }
    location.forEach(loc => {
      if (loc === "") {
      } else path = `${path}/${loc}`;
    });
    this.setState({ transaction_type: location[5].split("-") });

    const { history } = this.props;
    history.push(path);
  }

  moreFiltersUrlHandler(e, type) {
    const { history } = this.props;
    let { params } = this.props.match;
    const { url } = this.props.match;
    let location = url.split("/");
    let path = ``;
    let min_sqft_found = false;
    let alreadyExists = false;
    let queryParams = [];
    let duplicateParams = [];
    if (params && params.dynamicParams) {
      const { dynamicParams } = params;
      duplicateParams = dynamicParams.split(",");
      for (let i = 0; i < duplicateParams.length; i++) {
        queryParams = duplicateParams[i].split("=");
        for (let j = 0; j < queryParams.length; j++) {
          if (queryParams[j] === type) {
            if (e.target.value !== "" && e.target.value) {
              min_sqft_found = true;
              duplicateParams[i] = `${queryParams[j]}=${e.target.value}`;
              queryParams[j + 1] = e.target.value;
            } else {
              duplicateParams[i] = `${type}=removed`;
            }
          }
        }
      }
    } else {
      if (e.target.value !== "") {
        duplicateParams.push(`${type}=${e.target.value}`);
        alreadyExists = true;
      }
    }

    if (e.target.value !== "" && !min_sqft_found && !alreadyExists)
      duplicateParams.push(`${type}=${e.target.value}`);

    _.remove(duplicateParams, function (el) {
      if (el === `${type}=removed`) {
        return el;
      }
    });

    for (let i = 0; i < 6; i++) {
      if (location[i] !== "") path = `${path}/${location[i]}`;
    }

    if (duplicateParams.length > 0) {
      path = `${path}/${duplicateParams.join(",")}`;
    }

    history.push(path);
  }

  handleMinSqFt(e) {
    this.setState({
      Listings: []
    });
    this.setState({ min_sqft: e.target.value });
    this.moreFiltersUrlHandler(e, "min_sqft");
  }

  handleMaxSqFt(e) {
    this.setState({
      Listings: []
    });
    this.setState({ max_sqft: e.target.value });
    this.moreFiltersUrlHandler(e, "max_sqft");
  }

  handleListDate(e) {
    this.setState({
      Listings: []
    });
    this.setState({ listing_date: e.target.value });
    this.moreFiltersUrlHandler(e, "listing_date");
  }

  handleKeyword(e) {
    this.setState({
      Listings: []
    });
    this.setState({ keywords: e.target.value });
    this.moreFiltersUrlHandler(e, "keywords");
  }

  handleReo(e) {
    this.setState(
      {
        reo: !this.state.reo,
        Listings: []
      },
      () => {
        if (this.state.reo) e = { target: { value: "on" } };
        else e = { target: { value: "" } };

        this.moreFiltersUrlHandler(e, "reo");
      }
    );
  }

  handleShortSale(e) {
    this.setState(
      {
        Listings: [],
        short_sale: !this.state.short_sale
      },
      () => {
        if (this.state.short_sale) e = { target: { value: "on" } };
        else e = { target: { value: "" } };

        this.moreFiltersUrlHandler(e, "short_sale");
      }
    );
  }

  handleFilters = () => {
    const {
      min_sqft,
      max_sqft,
      listing_date,
      keywords,
      reo,
      short_sale
    } = this.state;

    this.setState({
      Listings: []
    });
    this.props.actions.setSearchParam({
      min_sqft: min_sqft,
      max_sqft: max_sqft,
      listing_date: listing_date,
      keywords: keywords,
      roe: reo,
      short_sale: short_sale
    });
  };

  propertyTypeChecked = type => {
    let propertyIndex = this.state.propertyType.indexOf(type);
    if (propertyIndex === -1) {
      return false;
    } else {
      return true;
    }
  };
  componentDidMount() {
    this.filter();
  }
  /*
   * Custom Listiners
   */
  onChangePropertyType = values => {
    const { params } = this.props.match;
    if (typeof values === "object" && Array.isArray(values))
      values = values.join(",");

    const { City, StateOrProvince, transaction_type } = params;
    let { filters } = this.state;

    filters.PropertySubType = values;
    filters.TransactionType = transaction_type;
    this.setState({
      filters: {
        ...filters
      }
    });
    console.log("---> ", values);
    this.filter("PropertySubType");
  };
  onChangeSearch = (value, option) => {
    this.setState({
      address: value
    });
    this.goToSearch(value, option);
  };
  search = params => {
    /* Search API */
    api_search({ params })
      .then(res => {
        if (res.status === 200) {
          const { TotalPages, TotalRecords, CurrentPage } = res.data.meta;
          const response = res.data;
          const Listings = response.data;
          this.setState({
            TotalRecords,
            TotalPages,
            CurrentPage,
            loading: false,
            Listings,
            page: this.state.page + 1
          });
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
  onChangeListingType = values => {
    let { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        listing_type: values
      }
    });
  };
  goToSearch = (value, option) => {
    try {
      const { history, location } = this.props;
      const { url } = this.props.match;
      let { address } = this.state;
      const { params } = this.props.match;
      const { transaction_type } = params;
      let {
        props: { record, category }
      } = option;
      let { City, State, Slug, Zip } = record;
      console.log(">>> CATEGORY: ", category, option);

      let pathname = ``;
      let _href = url.split("/");

      /* if (category == "City") {
        if (address) {
          address = address.split(",");

          _href[3] = City || "Miami";
          _href[4] = State.replace(/ /g, "") || "FL";

          _href.forEach(loc => {
            if (loc !== "") {
              pathname = `${pathname}/${loc}`;
            }
          });

          this.props.actions.setSearchParam({
            address,
            limit: 30,
            City,
            State
          });
          return history.replace(
            `/listings/search/${City.replace(/\//g, "-")}/${State.replace(
              /\//g,
              "-"
            )}/${transaction_type}`
          );
        }
      } */
      if (category == "City") {
        if (address)
          address = address.split(",");
        if (this.props.actions)
          this.props.actions.setSearchParam({
            address,
            limit: 30,
            City,
            State
          });
        return history.replace(
          `/listings/search/${City.replace(/\//g, "-")}/${State.replace(
            /\//g,
            "-"
          )}/${transaction_type}`
        );
      }
      if (category == "Zip") {
        this.props.actions.setSearchParam({
          PostalCode: Zip
        });
        return history.replace(
          `/zipcode/${transaction_type}/${Zip.replace(/\//g, "-")}`
        );
      }
      if (category == "Address") {
        return history.replace(
          `/listings/details/${City.replace(/\//g, "-")}/${State.replace(
            /\//g,
            "-"
          )}/${Slug.replace(/\//g, "-")}`
        );
      }

      /* let pathname = ``;
      let _href = url.split("/");
      if (address) {
        address = address.split(",");
 
        _href[3] = City || "Miami";
        _href[4] = State.replace(/ /g, "") || "FL";
 
        _href.forEach(loc => {
          if (loc !== "") {
            pathname = `${pathname}/${loc}`;
          }
        });
 
        this.props.actions.setSearchParam({
          address,
          limit: 30,
          City,
          State
        }); */

      /* Query String */
      /* let query = location.search || "";
        let search = {};
 
        if (category == "Zip" || "Address") {
          if (category === "Zip")
            search = qs.stringify({
              ZipCode: record[category]
            });
        }
 
        console.log("PROPS: ", record);
        history.push({
          pathname,
          search
        }); */
    } catch (err) {
      console.log("ERROR: ", err);
    }
  };
  sortByChange = e => {
    let { filters } = this.state;
    let value = e.key;
    filters["order_by"] = value !== filters["order_by"] ? value : undefined;
    this.setState({
      filters: {
        ...filters
      }
    });
    this.filter("order_by");
  };
  handleTransactionType = e => {
    let { filters } = this.state;
    let { value } = e.target;
    filters["transactionType"] = value;
    this.state.filters = {
      ...filters
    };
    this.filter("transactionType");
  };
  handlePrice = (name, value) => {
    let { filters } = this.state;
    filters[name] = filters[name] !== value ? filters[name] : undefined;
    filters[name] = value !== filters[name] ? value : undefined;
    filters[name] = value !== null && value !== 0 ? value : undefined;
    this.setState({
      ...filters
    });
    this.filter(name);
  };
  handleBeds = (value, name) => {
    let { filters } = this.state;
    if (value)
      value = String(value);
    filters[name] = filters[name] !== value ? filters[name] : undefined;
    filters[name] = value !== filters[name] ? value : undefined;
    filters[name] = value !== null && value !== 0 ? value : undefined;
    this.setState({
      ...filters
    });
    this.filter("BedroomsTotal");
  };
  handleBaths = (value, name) => {
    let { filters } = this.state;
    value = String(value);
    filters[name] = filters[name] !== value ? filters[name] : undefined;
    filters[name] = value !== filters[name] ? value : undefined;
    filters[name] = value !== null && value !== 0 ? value : undefined;
    this.setState({
      ...filters
    });
    this.filter("BathsTotal");
  };
  handleSqFt = (value, name) => {
    //return console.log(value,name);
    let { filters } = this.state;
    if (!value) {
      return this.filter("Baths", {
        [name]: undefined
      });
    }
    if (value !== filters[name]) {
      filters[name] = value;
      this.setState({
        ...filters
      });
      if (
        filters["ApproximateLotSizeTo"] &&
        filters["ApproximateLotSizeFrom"]
      ) {
        this.filter("LotSize");
      } else if (
        !filters["ApproximateLotSizeTo"] &&
        !filters["ApproximateLotSizeFrom"]
      ) {
        this.filter("LotSize", {
          ApproximateLotSizeTo: undefined,
          ApproximateLotSizeFrom: undefined
        });
      }
    }
  };
  handleSqArea = (value, name) => {
    let { filters } = this.state;
    filters[name] = value !== null ? value : undefined;
    this.setState({
      ...filters
    });
    this.filter(name);
  };
  handleListingDate = (date, value) => {
    let { filters } = this.state;
    filters["OriginalEntryTimestamp"] =
      value !== null ? moment(value).toISOString() : undefined;
    this.setState({
      ...filters
    });
    return this.filter("OriginalEntryTimestamp");
  };
  handleKeywords = e => {
    let { value } = e.target;
    let { filters } = this.state;

    filters["Amenities"] = value !== null ? value : undefined;
    this.setState({
      ...filters
    });
    return this.filter("Amenities");

    if (value !== filters["Amenities"]) {
      filters["Amenities"] = value;
      this.setState({
        ...filters
      });
      if (value) {
        this.filter("keywords");
      } else {
        this.filter("keywords", {
          Amenities: undefined
        });
      }
    }
  };
  handleREOYN = e => {
    let { filters } = this.state;
    let { checked, name } = e.target;
    if (checked !== filters[name]) {
      this.filter(name, {
        SpecialListingConditions: "ShortSale",
        [name]: checked ? "1" : undefined
      });
    }
  };
  changeProps = params => {
    this.props.actions.changeSearch(params);
  };
  filter = (type, extra) => {
    let { history, location, match } = this.props;
    let { filters } = this.state;
    let params = qs.parse(location.search.replace(/\?/g, ""));

    if (typeof type !== "undefined") {
      params = {
        ...params,
        [type]: filters[type]
      };
    }
    if (typeof extra !== "undefined") {
      switch (type) {
        case "ShortSaleYN":
          if (typeof extra["ShortSaleYN"] === "undefined")
            for (var key in extra) {
              extra[key] = undefined;
            }
          break;
        default:
          break;
      }
      params = {
        ...params,
        ...extra
      };
    }

    /* case "keywords":
case "REOYN":
case "ShortSaleYN":
case "orderBy":
order_by: filters["order_by"] */

    for (var key in params) {
      filters[key] = params[key];
      if (
        typeof filters[key] === "undefined" ||
        filters[key] === "" ||
        filters[key] === null
      ) {
        delete filters[key];
        delete params[key];
      }
    }
    params["TransactionType"] = match.params["transaction_type"];
    params["limit"] = params["limit"] ? parseInt(params["limit"]) : 30;
    params["page"] = params["page"] ? parseInt(params["page"]) : 1;
    params = {
      ...params,
      ...match.params
    };

    console.log("Params: ", params);

    if (localStorage.userId) {
      if (location.autoSave) this.saveSearch();
      console.log("Is Logged!", location, this.props);
    }
    //this.saveSearch()
    console.log("Search:: ", params)
    for (var key in params) {
      if (params[key] == "undefined" || typeof params[key] == "undefined" || params[key] == undefined || params[key] == null)
        delete params[key];
    }
    history.replace(`?${qs.stringify(params)}`);
    this.props.actions.changeSearch(params);
  };
  saveSearch = () => {
    let { location, history } = this.props;
    const params = this.props.match.params || {};
    const { transaction_type } = params;
    /* Query String */
    let query = location.search || "";
    let values = qs.parse(query.replace("?", ""));

    console.log("Save Properties? ", values);
    params["userId"] = localStorage.userId;
    params["hostApp"] = "GOW";
    params["PropertyType"] = transaction_type;
    params["url"] = `${window.location.pathname}${window.location.search ||
      ""}`;
    for (var key in values) {
      params[key] = values[key];
    }

    save_listing_search(params)
      .then(response => {
        let { data } = response;
        message.info("Saved successfully!");
      })
      .catch(err => {
        localStorage.clear();
        message.info("Please login to save the search.");
        return history.push("/login", {
          search: location.search,
          pathname: location.pathname
        });
      });
  };
  handleSaveSearch = e => {
    const self = this;
    let { search, history, location } = this.props;
    if (!search) search = {};
    return confirm({
      content: "Do you Want to save Search?",
      onOk() {
        self.saveSearch();
      }
    });
  };
  resetFilters = async TransactionType => {
    let { history, location } = this.props;
    let { params } = this.props.match;
    let { City, StateOrProvince, transaction_type } = params;
    this.setState({
      filters: {}
    });
    await history.replace(
      `/listings/search/${City}/${StateOrProvince}/${TransactionType}`
    );
  };
  handleMoreChange = async (field, value) => {
    switch (field) {
      case 'transaction_type':
        await this.resetFilters(value);
        this.filter();
        break;
      default:
        let { filters } = this.state;
        if (typeof value == "boolean") {
          filters[field] = value == true ? value : undefined;
        } else {
          filters[field] = value !== null ? value : undefined;
        }
        this.setState({
          ...filters
        });
        this.filter(field);
        break;
    }
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.history) {
      this.setState({
        history: nextProps.history
      });
    }
  }
  onChangeTransaction = async (TransactionType) => {
    await this.resetFilters(TransactionType);
    this.filter();
  }
  render() {
    let { params } = this.props.match;
    const { City, StateOrProvince, transaction_type } = params;

    let { history, location, match } = this.props;
    params = {
      ...params,
      ...qs.parse(location.search.replace(/\?/g, ""))
    };

    /* 
    
      const queryString = require('query-string');
 
      const parsed = queryString.parse(props.location.search);
    */
    const { filters, options, transactionType } = this.state;
    const address = `${City || ""}${City ? "," : ""}${StateOrProvince || ""}`;
    //console.log(">> transaction_type: ", transaction_type);
    const default_sub_types = [
      "SingleFamilyResidence",
      "Condominium",
      "Townhouse"
    ];
    const PropertySubTypes =
      transaction_type === "Sale"
        ? [
          {
            text: "Houses",
            value: "SingleFamilyResidence"
          },
          {
            text: "Condos/Co-Ops",
            value: "Condominium"
          },
          {
            text: "Townhomes",
            value: "Townhouse"
          },

          {
            text: "Multi-Family",
            value: "IncomeProperty"
          },
          {
            text: "Lots/Land",
            value: "UnimprovedLand"
          },
          {
            text: "Apartments",
            value: "Apartments"
          },
          {
            text: "Manufactured",
            value: "Manufactured"
          },
          {
            text: "BoatSlip",
            value: "BoatSlip"
          },
          {
            text: "Other",
            value: "Other"
          }
        ]
        : [
          {
            text: "Houses",
            value: "SingleFamilyResidence"
          },
          {
            text: "Apartment",
            value: "Apartment"
          },
          {
            text: "Townhouse",
            value: "Townhouse"
          },
          {
            text: "MultiFamily",
            value: "MultiFamily"
          },
        ];

    let base_link = `/listings/search/${City}/${StateOrProvince}/${transaction_type}`;
    let new_construction_link = `${base_link}?NewConstructionYN=true&order_by=NEW-LISTINGS`;

    let pending_listing = `${base_link}?StandardStatus=Pending`;
    //{ "StandardStatus" : "Pending" }

    let startDate = moment();
    startDate = startDate.subtract(5, "days");
    startDate = startDate.format("Y-MM-DD");

    return (
      <Row>
        <div className="search-tools">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={5}
            className="search-item search-item-city"
          >
            <SearchFieldCategory
              className="certain-category-search btn-green"
              onSelect={this.onChangeSearch}
              defaultValue={[address]}
              placeholder="Search an addres, city, ZIP code or MlsId"
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={18}
            className="conte-search-item"
            onClick={() => {
              this.setState({ showMenu: !this.state.showMenu });
            }}
          >
            <Row type="flex" justify="space-between" align="middle" gutter={4}>
              <Col xs={12} sm={12} md={6} lg={3} xl={3} className="search-item">
                <DropDownMenu
                  className="btn-tools"
                  icon={"filter"}
                  style={{
                    width: 100,
                    height: 32,
                    verticalAlign: "middle"
                  }}
                  label={transaction_type === "Sale"
                    ? "For Sale"
                    : "For Rent"}
                  menuStyle={{
                    minWidth: 300,
                    maxWidth: 300
                  }}
                >
                  <Row className="content">
                    <RadioGroupList
                      onChange={this.onChangeTransaction}
                      initialValue={transaction_type}
                      dataSource={types}
                    />
                  </Row>
                </DropDownMenu>
              </Col>
              <Col xs={12} sm={12} md={6} lg={3} xl={3} className="search-item">
                <DropDownMenu
                  className="btn-tools"
                  icon={"down"}
                  style={{
                    width: 100,
                    height: 32,
                    verticalAlign: "middle"
                  }}
                  label={transaction_type === "Sale"
                    ? "Home Type"
                    : "Rental Type"}
                  menuStyle={{
                    minWidth: 300,
                    maxWidth: 300
                  }}
                >
                  <Row className="content">
                    <ListTypes
                      dataSource={PropertySubTypes}
                      initialValues={this.state.filters.PropertySubType}
                      onSelectAll={this.onChangePropertyType}
                      onChange={this.onChangePropertyType}
                    />
                  </Row>
                </DropDownMenu>
              </Col>
              <Col xs={12} sm={12} md={6} lg={3} xl={3} className="search-item">
                <DropDownMenu
                  className="btn-tools"
                  icon={"down"}
                  style={{
                    width: 80,
                    height: 32,
                    verticalAlign: "middle"
                  }}
                  title="Price Range"
                  label="Price"
                  menuStyle={{
                    minWidth: 80,
                    maxWidth: 300
                  }}
                >
                  <Row className="content">
                    <PriceRange
                      onChange={this.handlePrice}
                      start={this.state.filters.PriceFrom}
                      end={this.state.filters.PriceTo}
                      minSteps={transaction_type === "Sale" ? 100000 : 500}
                      maxSteps={transaction_type === "Sale" ? 100000 : 500}
                    />
                  </Row>
                </DropDownMenu>
              </Col>
              <Col xs={12} sm={12} md={6} lg={3} xl={3} className="search-item">
                <DropdownMenuList
                  value={String(this.state.filters.BedroomsTotal)}
                  onChange={value => this.handleBeds(value, "BedroomsTotal")}
                  data={group_items}
                  icon={["fas", "bed"]}
                  label="Beds"
                />
              </Col>
              <Col xs={12} sm={12} md={6} lg={3} xl={3} className="search-item">
                <DropdownMenuList
                  value={String(this.state.filters.BathsTotal)}
                  onChange={value => this.handleBaths(value, "BathsTotal")}
                  data={group_items}
                  icon={["fas", "bath"]}
                  label="Baths"
                />
              </Col>
              <Col xs={12} sm={12} md={6} lg={3} xl={3} className="search-item">
                <DropDownMenu
                  className="btn-tools"
                  icon={"down"}
                  style={{
                    width: 100,
                    height: 32,
                    verticalAlign: "middle"
                  }}
                  label="More"
                  menuStyle={{
                    minWidth: 250,
                    maxWidth: 450
                  }}
                >
                  <Row className="content more-menu">
                    <Col
                      span={24}
                      style={{
                        padding: "0px 15px"
                      }}
                    >
                      {<MoreMenu
                        params={params}
                        onChange={this.handleMoreChange}
                        transaction_type={transaction_type}
                        PropertySubTypes={PropertySubTypes}
                        {...this.props}
                      />}
                    </Col>
                  </Row>
                </DropDownMenu>
              </Col>
              <Col xs={12} sm={12} md={6} lg={3} xl={3} className="search-item">
                {
                  <Button
                    className="bg-orange btn-save"
                    type="link"
                    onClick={this.handleSaveSearch}
                  >
                    {
                      <FontAwesomeIcon
                        className="icon-fab"
                        icon={["fas", "bell"]}
                      />
                    }{" "}
                  Save Search
                </Button>
                }
              </Col>
            </Row>
          </Col>
        </div>
      </Row>
    );
  }
}
const mapStateToProps = state => {
  return {
    search: state.search
  };
};
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Search));
