import React, { Component } from "react";
import debounce from "lodash/debounce";
import {
  GoogleApiWrapper,
  withScriptjs,
  GoogleMap,
  withGoogleMap,
  Map,
  Marker,
  Polyline,
  InfoWindow
} from "react-google-maps";
import GeocodeMap from "react-geocode";
import { withRouter } from "react-router";
import ListingCard from "../../condo/CondoCard";
import "./map.css";
import Preloader from "../preloading/Preloader";
import Constants from "../../../utils/constants";
import ListingMap from "../../condo/CondoMap";
import Axios from "axios";
import { resolve } from "url";

const { compose, withProps, withStateHandlers } = require("recompose");
/* const styles = [
  { featureType: "water", stylers: [{ color: "#D3E6F1" }] },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#adadad" }]
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#ffffff" }]
  }
]; */
const styles = require("./styleMap.json");
const iconUrl = require("../../../images/placeholder.png");
const iconSelected = require("../../../images/placeholder-selected.png");
const GoogleMapWrapper = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBIIMCtAQw9VFVhzdRxDe9XROOGAqqY0ps&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withStateHandlers(
    () => ({
      isOpen: false,
      loading: true,
      current: "",
      markers: []
    }),
    {
      onToggleOpen: ({ isOpen, current }) => id => ({
        isOpen: !isOpen,
        current: id
      })
    }
  ),
  withScriptjs,
  withGoogleMap
)(props => {
  let { data } = props;
  let center = { LatLng: 0, lat: 0, lng: 0 };

  if (data.length > 0) center = data[0].location;
  const iconSize = 32;
  let iconMarker = new window.google.maps.MarkerImage(
    iconUrl,
    null /* size is determined at runtime */,
    null /* origin is 0,0 */,
    null /* anchor is bottom center of the scaled image */,
    new window.google.maps.Size(iconSize, iconSize)
  );
  let iconMarkerSelected = new window.google.maps.MarkerImage(
    iconSelected,
    null /* size is determined at runtime */,
    null /* origin is 0,0 */,
    null /* anchor is bottom center of the scaled image */,
    new window.google.maps.Size(iconSize, iconSize)
  );

  return (
    <GoogleMap
      defaultZoom={props.zoom || 11}
      zoom={props.zoom || 12}
      defaultCenter={center}
      onClick={props.onToggleOpen}
      defaultZoomOnClick={true}
      defaultOptions={{
        //disableDefaultUI: true, // disable default map UI
        //draggable: true, // make map draggable
        // keyboardShortcuts: false, // disable keyboard shortcuts
        //scaleControl: true, // allow scale controle
        //scrollwheel: false, // allow scroll wheel
        styles: styles // change default map styles
      }}
    >
      <>
        {props.data
          .map((mark, index) => {
            let { data, location } = mark;
            return (
              <Marker
                key={index}
                icon={props.current === index ? iconMarkerSelected : iconMarker}
                position={location}
                onMouseOver={() => {
                  props.onToggleOpen(index);
                }}
                onClick={() => props.onToggleOpen(index)}
              >
                <>
                  {props.showInfo && props.current === index && (
                    <InfoWindow onCloseClick={() => props.onToggleOpen(index)}>
                      <ListingMap
                        PhotosCount={1}
                        url={`${Constants.URL_API_PHOTOS}${data.ListingKeyNumeric}/0.jpg?width=200&height=200`}
                        current={data}
                        displayGalery={false}
                        showActions={false}
                        /* onChange={this.onChange} */
                        {...data}
                      />
                    </InfoWindow>
                  )}
                </>
              </Marker>
            );
          })
          .filter((item, index) => item != null)}
      </>
    </GoogleMap>
  );
});

export default class MapFactory extends Component {
  state = {
    markers: [],
    loading: true
  };
  geoCodeAddresses = data => {
    let markers = [];
    this.setState({
      markers,
      loading: true
    });
    return new Promise((resolve, reject) => {
      if (data.length == 0) return reject();
      data.map((item = {}, index, arr) => {
        if (!item) return;
        let {
          StreetNumber,
          StreetDirPrefix,
          StreetName,
          UnitNumber,
          City,
          id,
          StateOrProvince,
          PostalCode,
          Remarks,
          Geocode
        } = item;

        let address = [
          StreetNumber,
          StreetDirPrefix,
          StreetName,
          UnitNumber,
          City,
          StateOrProvince,
          PostalCode
        ]
          .join(" ")
          .replace(/null/g, "");

        console.log("addres ", address);

        if (typeof Geocode !== "undefined") {
          //console.log("GEO: ",Geocode)
          let { latitude, longitude } = Geocode;
          markers.push({
            address,
            description: Remarks,
            location: {
              lat: latitude,
              lng: longitude
            },
            data: item
          });
          if (arr.length - 1 == index) resolve(markers);
        } else {
          Axios.get(
            `https://api.gooneworld.com/api/Properties/findLatLng/${id}`
          ).then(res => {
            let { data } = res;

            if (data.hasOwnProperty("latitude")) {
              let location = {
                lat: data.latitude,
                lng: data.longitude
              };
              //let res = response.results[response.results.length - 1];
              markers.push({
                address,
                description: Remarks,
                location,
                data: item
              });

              if (arr.length - 1 == index) resolve(markers);
            } else {
              /* Geo Localization by Address */
              GeocodeMap.setApiKey("AIzaSyBIIMCtAQw9VFVhzdRxDe9XROOGAqqY0ps");
              //console.log("Geo Localization by Address: ", address);
              GeocodeMap.fromAddress(address).then(
                response => {
                  if (response.results.length > 0) {
                    let res = response.results[response.results.length - 1];

                    // console.log("Geo Location: ", res.geometry.location);
                    markers.push({
                      address,
                      description: Remarks,
                      location: res.geometry.location,
                      data: item
                    });

                    if (arr.length - 1 == index) resolve(markers);
                  }
                },
                error => {
                  console.error(error);
                }
              );
            }
          });
        }
      });
    });
  };
  componentWillReceiveProps(props) {
    let { data } = props;

    this.geoCodeAddresses(data).then(markers =>
      this.setState({ markers, loading: false })
    );
    //console.log("Change!!",props);
  }
  componentDidMount() {
    let { data } = this.props;
    this.geoCodeAddresses(data).then(markers =>
      this.setState({ markers, loading: false })
    );
  }
  render() {
    let { markers } = this.state;
    let { showInfo = true, loading } = this.props;
    return (
      <>
        {!loading ? (
          <GoogleMapWrapper
            data={markers}
            loading={loading}
            showInfo={showInfo}
          />
        ) : (
          <Preloader />
        )}
      </>
    );
  }
}
