import styled from "styled-components";
export const WrapperPrice = styled.div`
    & .ant-list-split .ant-list-item {
        border-bottom: 0px!important;
        padding: 4px 8px!important;
        margin-bottom: 0px!important;
    }
    & .ant-list-items{
        margin: 10px 0px!important;
    }
    & li.ant-list-item {
        property-transition:all;
        background: #fff;
        transition-duration:.15s;

        display: flex;
        justify-content: center;
        align-items: center;
    }
    & li.ant-list-item:hover {
        background: #2196f333;
        transform:scale(1.2);
        transform: translateX(-2);
        font-weight: bold;
    }
    & li.ant-list-item:hover span{
        font-width:bold;
    }
    & .item-container span{
        font-size:18px;
    }
    & .ant-input-number{
        border: 2px solid rgb(211, 211, 211)!important;
        border-radius: 10px!important;
    }
    & .ant-input-number:focus {
        border-color: var(--color-secondary)!important;
        border-right-width: 2px !important;
        -webkit-box-shadow: 0 0 0 2px rgb(220 83 83 / 33%)!important;
        box-shadow: 0 0 0 2px rgb(220 83 83 / 33%)!important;
    }
    & .ant-divider-horizontal {
        width: 100%!important;
        min-width: 100%!important;
        margin: 24px 4px!important;
        background: rgb(211, 211, 211) !important;
    }
`;
export const WrapperList = styled.div`
    width: 100%!important;
    & .ant-list-split .ant-list-item {
        border-bottom: 0px solid #e8e8e8!important;
        line-height: 1!important;
        margin: 0px!important;
        padding: 4px 0px!important;
    }
    & .ant-list-header, .ant-list-footer {
        padding-top: 0px!important;
        padding-bottom: 0px!important;
        margin-bottom: 8px!important;
    }
    & label.ant-checkbox-wrapper {
        margin-right: 4px;
    }
`;
export const WrapperDropDownMenuList = styled.div`
    & .ant-radio-button-wrapper:hover,
    .ant-radio-button-wrapper {
        border: 2px solid #d9d9d9!important;
    }
    & .btn-dropdown{
        transition-duration:.15s;
    }
    & .btn-dropdown:hover{
        transform:scale(1.5);
        transform: translateX(-2); 
    }
    & .ant-radio-group{
        height: 40px !important;
        overflow: hidden;
    }
    & .ant-radio-button-wrapper{
        border: 1px solid var(--color-primary) !important;
    }
    & .ant-radio-button-wrapper:hover {
        border: 1px solid var(--color-primary) !important;
    }
`;
export const WrapperForm = styled.div`
    & label.ant-checkbox-wrapper {
        text-align: left;
        margin-left: 20px;
    }
`;
export const WrapperRadioGroupList = styled.div`
    & .ant-radio-wrapper > span:last-child {
        padding-left:4px!important;
    }
    /* & .ant-radio-wrapper > span:last-child::after {
        content: " ";
        width: 15px;
        height: 15px;
        position: absolute;
        border-radius: 50%;
        background: var(--color-secondary);
        left: 24px;
        top: calc(50% - 7.5px);
    }
    & .yellow > span:last-child::after {
        background: var(--color-yellow)!important;
    }
    & .purple > span:last-child::after {
        background: var(--color-purple)!important;
    }
    & .red > span:last-child::after {
        background: var(--color-red)!important;
    } */
`;