import React, { Component } from "react";
import { Spin } from "antd";
import './preloader.css';
class Preloader extends Component {
  render() {
    return (
      <div className="page-preloading">
        <Spin size="large" tip={<a>Loading...</a>} />
      </div>
    );
  }
}

export default Preloader;
