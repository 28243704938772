import React, { Component } from "react";
import { withRouter } from "react-router";
import ListingCard from "./CondoCard";
import { List, Pagination } from "antd";
import "./condoscards.css";
import axios from "axios";
import Skeleton from "../com/skeleton/SkeletonTpl";
import { add_favorite } from "../../services";
import { Modal, message } from "antd";
const { confirm } = Modal;

const MAX_SKELETON = 30;

class ListingsCards extends Component {
  state = {
    loading: false,
    preloading: true,
    hasMore: true,
    data: [],
    pageStart: 0,
    current: 1,
    loadMore: true
  };
  handlePagination = page => {
    this.setState({
      current: page
    });
    if (typeof this.props.onChange !== "undefined") {
      this.props.onChange(page);
    }
  };
  componentDidMount() {
    this.setState({
      data: this.defaultData()
    });
  }
  defaultData() {
    return Array.apply(null, { length: (this.props.pageSize || MAX_SKELETON) }).map((item, index) => {
      return index;
    });
  }
  /*addFavorite = ListingKeyNumeric => {
    const me = this;
    let { history, location } = this.props;
    //let { ListingKeyNumeric } = this.props;
    let paramas = {
      userId: localStorage.userId,
      ListingKeyNumeric
    };
    if (!localStorage.userId)
      return confirm({
        title: "Add to favorites?",
        content: "Do you Want to add to favorites?",
        onOk() {
          history.push("/login");
        }
      });
    me.add_to_favorite(paramas);
  };
  add_to_favorite = paramas => {
    add_favorite(paramas)
      .then(response => {
        let { data } = response;
        message.info(data.description);
      })
      .catch(error => message.error(error.message));
  };*/
  render() {
    let { loading, pagination = {}, pageSize = 30 } = this.props;
    let data = this.props.data || [];
    let { CurrentPage = 1, TotalRecords = data.length } = pagination;
    data = !loading ? data : this.state.data;
    return (
      <div
        className={
          !loading && TotalRecords === 0
            ? "listings listings-empty"
            : "listings"
        }
      >
        <div className="listings-cards">
          <List
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 3,
              lg: 3,
              xl:3,
            }}
            dataSource={data}
            renderItem={item => {
              /* if (typeof item === "object")
                axios
                  .get(
                    `http://54.84.6.129/syncPhotos.php?mui=${
                      item.ListingKeyNumeric
                    }`
                  )
                  .then(response => {
                    console.log("Done!", response);
                  })
                  .catch(err => console.log(err)); */
              return (
                <List.Item className="lcondo">
                  {!loading ? (
                    <ListingCard
                      displayActions={true}
                      {...item}
                    />
                  ) : (
                    <Skeleton />
                  )}
                </List.Item>
              );
            }}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(ListingsCards);
