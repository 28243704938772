import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Icon, List } from 'antd';
import { WrapperList } from "./Styles";
const ListTypes = ({ ...props }) => {
    const [dataSource, setDataSource] = useState([]);
    const [selection, setSelection] = useState([]);
    const [selectedAll, setSelectedAll] = useState(props.selectedAll || false);
    const handleClickItem = ({ value }) => {
        let new_selection = selection;
        if (!selection.includes(value)) {
            new_selection = [
                ...selection,
                value
            ];
        } else {
            new_selection = selection.filter(it => (it != value));
        }
        setSelectedAll(false);
        setSelection(new_selection);
        if (props.onChange)
            props.onChange(new_selection);
    }
    const handleSelectAll = () => {
        let new_selection = [];
        if (!selectedAll) {
            new_selection = dataSource.map(it => (it.value));
        }
        setSelection(new_selection);
        setSelectedAll(selected => (!selected));
        if (props.onChange)
            props.onChange(new_selection);
    }
    const renderItem = (item, index) => {
        return (<List.Item
            onClick={() => handleClickItem(item)}
            key={index}>
            <Checkbox checked={selection.includes(item.value)} />{" "}
            {item.text}
        </List.Item>);
    }
    useEffect(() => {
        if (props.dataSource) {
            setSelectedAll(props.dataSource.length == selection.length)
            setDataSource(props.dataSource);
        }

    }, [props.dataSource]);
    useEffect(() => {
        if (props.initialValues) {
            let initialValues = props.initialValues || [];
            if (typeof initialValues === 'string')
                initialValues = props.initialValues.split(",");
            setSelection(initialValues);
        }
    }, [props.initialValues])
    return (<WrapperList>
        <List
            header={
                <div className="header-list">
                    {<div className="select-all-container">
                        <Button
                            block
                            size="large"
                            type="link"
                            onClick={handleSelectAll}
                        >
                            <Icon
                                style={{
                                    color: "red"
                                }}
                                size="large" type={!selectedAll ? "check-circle" : "close-circle"} theme="filled" />
                            {!selectedAll ? "Select All" : "Deselect All"}
                        </Button>
                    </div>}
                </div>
            }
            dataSource={dataSource}
            renderItem={renderItem}
        />
    </WrapperList>);
}
export default ListTypes;