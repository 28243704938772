import React, { useState, useEffect, useRef } from "react";
//ES6
import ReactMapboxGl, {
  Layer,
  Feature,
  GeoJSONLayer,
  Marker,
  ZoomControl,
  Cluster,
  Popup
} from "react-mapbox-gl";
// ES5
import styled from "styled-components";
import "mapbox-gl/dist/mapbox-gl.css";
import ListingMap from "../listings/ListingMap";
import { Constants } from "../../utils";
import { getService } from "../../services/";
import { message, Spin, Icon } from "antd";
import _ from "lodash";
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
const themes = require("./styles.json");
const geojson = require("./geojson.json");
const Mark = styled.div`
  /* background-color: #e74c3c;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: 4px solid #eaa29b; */

  background-color: #e74c3c;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 40%);
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  border-color: #fff;

  &:hover{
    background-color: #fff;
    border-color: #e74c3c;
  }

`;
const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1Ijoib2tpbG9jbzIiLCJhIjoiY2o2eHRuZW9kMWtzajM0cGc5YzJzZDdtMyJ9.CUKmVGnNcpmjsTD0R3ov2A"
  /* accessToken:
    "pk.eyJ1IjoiYmx1bWV0cml4IiwiYSI6ImNrbzA0djBsMjBhZXUyd28zbjhjdm5nbG0ifQ.ooPkax1BiUirVySXDlKCHw" */
});

const mapStyle = {
  width: "100%",
  height: "100%"
};
const symbolLayout = {
  "text-field": "{place}",
  "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
  "text-offset": [0, 0.6],
  "text-anchor": "top"
};
const circlePaint = {
  "circle-color": "red"
};
const Wrapper = styled.div`
  min-height:700px!important;
  & .mapboxgl-map{
    display: block!important;  
    min-height:700px!important;
  }
  & .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  & .mapboxgl-popup{
    z-index:999!important;
  }
`;
const circleLayout = { visibility: "visible" };

/* Map */
const MapBox = ({ reference, filterDefaultValues = {}, ...props }) => {
  /*  lat: 10.97591637
    lng: -74.7881658 */
  let { type = "simple" } = props;
  let { onClick, onDblClick, onDragEnd } = props;
  const [center, setCenter] = useState(props.center || [-80.191788, 25.761681]);
  const [showPopup, setShowPopup] = useState(false);
  const [selected, setSelected] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const onStyleLoad = map => {
    const { onStyleLoad } = props;
    return onStyleLoad && onStyleLoad(map);
  };
  const myRef = useRef(null);
  const onClickCircle = evt => {
    console.log(evt);
  };
  const handleDragEnd = () => {
    const mapRef = myRef.current;
    let { map } = mapRef.state;
    /* let center = map.getCenter();
    if (onDragEnd) {
      onDragEnd(center)
    } */
  }
  const handleClick = (eOpt, e) => {
    let { lngLat } = e;
    let { lng, lat } = lngLat;
    /* if (lng) setCenter([lng, lat]);
    if (props.onChange) props.onChange(lngLat); */
  }
  const onMarkClick = item => {
    setSelected(item);
    setShowPopup(true);
    if (props.onMarkClick) props.onMarkClick(item);
  }
  const getData = () => {
    if (reference) {
      const service = getService(reference);
      setLoading(true);
      service.find({
        query: {
          ...filterDefaultValues
        }
      })
        .then(({ data }) => {
          setDataSource(data);
          setLoading(false);
        })
        .catch(err => {
          message.error(err.message);
        });
    }
  }
  const openPopup = (item) => {
    if (selected && (item.ListingKeyNumeric != selected.ListingKeyNumeric))
      setShowPopup(false);
    let timeout = setTimeout(() => {
      setSelected(item);
      setShowPopup(true);
      clearTimeout(timeout);
    }, 250);
  }
  const onOpenPopup = _.debounce(openPopup, 250, { maxWait: 1000 });
  useEffect(() => {
    if (reference)
      getData();
  }, [reference]);
  useEffect(() => {
    if (props.dataSource) {
      setDataSource(props.dataSource);
      setLoading(false);
    }
  }, [props.dataSource]);
  useEffect(() => {
    if (props.dataSource && props.dataSource.length > 0 && !initialized) {
      let item = props.dataSource[0];
      let lng = item.Longitude;
      let lat = item.Latitude;
      /* let { lng, lat } = item._geoloc;
      */
      if (lng && lat) setCenter([lng, lat]);
      setInitialized(true);
    }
    return () => { };
  }, [props.dataSource]);
  useEffect(() => {
    if (!_.isEmpty(filterDefaultValues)) {
      getData();
    }
  }, [filterDefaultValues])
  if (loading) return <Wrapper className="loader">
    <div className="loader-container">
      <Spin indicator={antIcon} />
    </div>
  </Wrapper>
  return (
    <Wrapper>
      <Map
        ref={myRef}
        style={themes.basic}
        center={center}
        onClick={handleClick}
        onDblClick={onDblClick}
        onDragEnd={handleDragEnd}
        minZoom={10}
        maxZoom={120}
        /* zoom={[props.zoom || 10]} */
        /* zoom={[props.zoom || 100]}
        minZoom={0}
        maxZoom={120} */
        containerStyle={mapStyle}
        onStyleLoad={onStyleLoad}
      >
        <ZoomControl />
        {showPopup && selected && <Popup
          coordinates={[selected.Longitude, selected.Latitude]}
          offset={{
            'bottom-left': [12, -38],
            'bottom': [0, -38],
            'bottom-right': [-12, -38]
          }}>
          <div>
            <ListingMap
              PhotosCount={1}
              onClose={() => {
                setShowPopup(false);
              }}
              onOver={(item) => {
                if (selected.ListingKeyNumeric != item.ListingKeyNumeric)
                  setSelected(item);
              }}
              url={`${Constants.URL_API_PHOTOS}${selected.ListingKeyNumeric}/0.jpg?width=100&height=100`}
              current={selected}
              displayGalery={false}
              showActions={false}
              size="small"
              record={selected}
              /* onChange={this.onChange} */
              {...selected}
            />
          </div>
        </Popup>}
        {type === "geojson" && (
          <GeoJSONLayer
            circleLayout={circleLayout}
            circlePaint={circlePaint}
            symbolLayout={symbolLayout}
            circleOnClick={onMarkClick}
            data={geojson}
          />
        )}
        {
          <>
            {dataSource && dataSource.map((item, index) => {
              let lng = item.Longitude;
              let lat = item.Latitude;
              /* let { lng, lat } = item._geoloc; */
              if (lng >= -90 && lat <= 90) {
                return (
                  <Marker
                    key={index}
                    coordinates={[lng, lat]}
                    /* onMouseLeave={() => {
                      setSelected(null);
                      setShowPopup(false);
                    }} */
                    onMouseEnter={() => onOpenPopup(item)}
                    onClick={e => {
                      if (onMarkClick)
                        onMarkClick(item)
                    }}
                  >
                    {props.renderMarker ? (
                      <div
                        style={{
                          cursor: "pointer"
                        }}
                      >
                        {props.renderMarker(item, index)}
                      </div>
                    ) : (
                      <Mark />
                    )}
                  </Marker>
                );
              } else {
                return null;
              }
            })}
          </>
        }
      </Map>
    </Wrapper>
  );
};
export default MapBox;
