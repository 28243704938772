const setSearchParam = (data) =>{
    return { 
        type: 'SET_SEARCH_PARAM',
        payload: data
    }
}

/* Custom Actions */
const changeSearch = data => {
    return { 
        type:'CITY_CHANGE',
        data
    }
}
export default { setSearchParam, changeSearch };