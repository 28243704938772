import React, {Component} from 'react';
import { TreeSelect } from "antd";

const SHOW_PARENT = TreeSelect.SHOW_CHILD;


function MapPath(data){
    if(!data) return [];
    return data.map(item => {
        if("children" in item){
           item["children"] = item.children.map(child => {
               child["parent"] = item.value;
               if("children" in child){
                   child["children"] = MapPath(child.children);
               }
               return child;
           });
        }
        return item;
    });
}


class SearchList extends Component{

    constructor(props){
        super(props);
        this.state = {
            value:undefined,
            nodeSelected:null,
            options:[]
        }
    }
    onChange = (value, label, extra) => {
        let {nodeSelected} = this.state;
        let {triggerNode,allCheckedNodes} = extra;
        var selecteds = [];

        if (triggerNode){
            let {props} = triggerNode;
            if(("isLeaf" in triggerNode)){

                if(triggerNode.isLeaf()){
                    if(nodeSelected!==props.parent){
                        nodeSelected = props.parent;
                        this.setState({
                            value:[],
                            nodeSelected
                        });
                    }
                }else{
                    if(nodeSelected!==props.value){
                        nodeSelected = props.value;
                        this.setState({
                            nodeSelected
                        });
                    }
                }
                allCheckedNodes.reduce((selecteds,item) => {

                    let node = item.node;
                    let props = (typeof node !== "undefined")?node.props:item.props;
        
                    if(typeof props.parent !== "undefined"){
                        if(selecteds.indexOf(props.value) === -1 && nodeSelected === props.parent){
                            selecteds.push(props.value);
                        }
                    }else{
                        props.children.map(nodeChild => {
                        var propsChild = nodeChild.props;
                        if(selecteds.indexOf(propsChild.value) === -1 && nodeSelected === propsChild.parent){
                                selecteds.push(propsChild.value);
                        }
                        return nodeChild;
                        })
                    }
                    return selecteds;
                },selecteds);
                
            }
        }

        if(selecteds.length === 0){
            selecteds = value;
        }
        this.setState({ value:selecteds });
        if(typeof this.props.onChange !== "undefined"){
            this.props.onChange(selecteds, nodeSelected);
        } 
        
    };
    onSelect = (value, node, extra) => {
        //console.log("Select:", value, node, extra);

        if(typeof this.props.onSelect !== "undefined"){
            this.props.onSelect(value, node, extra);
        }
    };
    componentDidMount(){
        let options = this.props.options || [];
        this.setState({ options: MapPath(options)});
    }
    render(){
        let {placeholder} = this.props;
        let {options} = this.state;
        let style = this.props.style || {width:200};


        return(
            <TreeSelect
                style={style}
                treeCheckable={true}
                allowClear={true}
                showCheckedStrategy={SHOW_PARENT}
                treeDefaultExpandAll={false}
                defaultValue={this.props.defaultValue}
                value={this.state.value}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                treeData={options}
                multiple={true}
                placeholder={placeholder || "Please select"}
                treeCheckStrictly={false}
                onChange={this.onChange}
                onSelect={this.onSelect}
            />
        );
    }
}

export default SearchList;