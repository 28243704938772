import React, { Component } from "react";
/* import { withRouter } from "react-router"; */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../redux/actions/action_search";
import qs from "qs";
import { Button, Row, Col, Affix, BackTop, message } from "antd";
import "./listings.css";
import debounce from "lodash/debounce";
import ListingsCards from "../components/listings/ListingsCards";
import Layout from "../layouts/layoutlistings";
import Map from "../components/com/Map/Map";
import { api_search } from "../services";
import { isMobile } from "react-device-detect";
import axios from "axios";
import store from "../redux/stores";
import { Constants } from "../utils";

import { property_search } from "../services/";
import { MapBox } from "../components";
const MAX_PAGE_SIZE = 50;

const dataSource = require("../data/listings.json");

let source;
class Listings extends Component {
  constructor(props) {
    super(props);
    /* this.search = debounce(this.search, 800); */
  }
  state = {
    top: 0,
    data: [],
    pagination: {},
    filter: false,
    loading: true,
    loadingMap: true
  };

  componentWillMount() {
    const self = this;
    store.subscribe(() => {
      let _state = store.getState();
      let { search } = _state;
      let data = search.data || {};
      console.log("Search: ", data);
      self.search(data);
    });
    this.changeQuery();
    //this.searchListings();
  }
  componentWillReceiveProps(nextProps, nextContext) {
    let { location } = nextProps;
    let params = qs.parse(location.search.replace(/\?/g, ""));
    console.log("RECEIBE!", params);
    /*  this.search(params); */
  }
  search = params => {
    const self = this;
    //return console.log("Search Data: ", this.state.filter, params);
    this.setState({
      loadingMap: true,
      loading: true,
      filter: true
    });
    return property_search(params)
      .then(({ data, meta }) => {
        this.setState({
          data,
          pagination: meta,
          filter: false,
          loading: false
        });
      })
      .catch(err => message.error(err.message));
  };
  searchListings = () => {
    let { match, search, location } = this.props;
    let data = search.data || {};
    let { params } = match;
    let { City, StateOrProvince, transaction_type } = params;
    /* Get Query String */
    let query = location.search || "";
    let values = qs.parse(query.replace("?", ""));
    data = {
      //...data,
      //Address: `${City},${StateOrProvince}`,
      City,
      StateOrProvince,
      TransactionType: transaction_type,
      limit: MAX_PAGE_SIZE,
      ...values
    };
    this.search(data);
    // this.props.actions.changeSearch(data);
  };
  changeQuery = (name, value) => {
    let { location, history } = this.props;
    let { search } = location;
    let query = qs.parse(search.replace(/\?/, ""));
    if (name && value) {
      query[name] = query[name] !== value ? value : undefined;
      history.replace(`?${qs.stringify(query)}`);
    } else {
      this.setState({
        ...query
      });
    }
  };
  sortByChange = order_by => {
    let { search } = this.props;
    let data = search.data || {};
    data = { ...data, order_by, limit: 30 };
    this.changeQuery("order_by", order_by);
    this.props.actions.changeSearch(data);
  };
  handlePagination = page => {
    let { search } = this.props;
    let data = search.data || {};
    console.log("pagination: ", data);
    data = { ...data, page, limit: 30 };
    this.props.actions.changeSearch(data);
  };
  render() {
    let { data, pagination, loading, loadingMap } = this.state;
    let { match } = this.props;
    let { params } = match;
    let { City, StateOrProvince, transaction_type } = params;
    return (
      <Layout history={this.props.history}>
        <Row
          style={{
            height: "100%"
          }}
        >
          <Col
            md={12}
            /* lg={13} */
            style={{
              padding: "0px!important"
            }}
          >
            <ListingsCards
              data={data}
              pagination={pagination}
              loading={loading}
              sortByChange={this.sortByChange}
              onChange={this.handlePagination}
            />
            {isMobile && (
              <BackTop>
                <Button type="primary" shape="circle" icon="arrow-up" />
              </BackTop>
            )}
          </Col>
          <Col
            span={12}
            className="conte_map"
            style={{
              paddingLeft: "0px!important"
            }}
          >
            <Affix offsetTop={this.state.top}>
              <div className="map">
                {<Map
                  dataSource={data}
                  reference="get-listing-geocode"
                  filterDefaultValues={{
                    City,
                    StateOrProvince,
                    PropertyType: transaction_type == "Sale" ? "Residential" : "ResidentialLease"
                  }}
                />}
              </div>
            </Affix>
          </Col>
        </Row>
      </Layout>
    );
  }
}
const mapStateToProps = state => {
  const { search } = state;
  return {
    search: search
  };
};
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Listings);
