/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import "./layoutlistings.css";

import { Layout } from "antd";
import Menu from "../components/menuListings";
import Search from "../components/com/search/search";
import Footer from "../components/com/footer/Footer";
import Preloader from "../components/com/preloading/Preloader";

const { Header, Sider, Content } = Layout;

const Listings = ({ children, loading, search = true, ...props }) => (
  <div className="listings-container">
    {!loading ? (
      <Layout>
        {
          <header>
            <Menu />
          </header>
        }
        {search && (
          <Header className="submenu-listings">
            <Search {...props} />
          </Header>
        )}
        <Content>{children}</Content>
        <Footer span={12} className="footer-page footer-listing">
          Footer
        </Footer>
      </Layout>
    ) : (
      <Layout>
        <Content
          style={{
            width: "100%",
            margin: "0px auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Preloader />
        </Content>
      </Layout>
    )}
  </div>
);

Listings.propTypes = {
  children: PropTypes.node.isRequired
};

export default Listings;
