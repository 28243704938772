import React, { Component } from "react";
import { Layout, Col, Row } from "antd";
import { withRouter, a } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./footer.css";
import Social from "../social/Social";
import qs from "qs";
import { URL_CONDOS, URL_GOONEWORLD_SITE } from "../../../constants";
const { Footer } = Layout;
const style = {
  paddingLeft: "20px",
  paddingRight: "20px"
};
class FooterPage extends Component {
  render() {
    let for_sale = {
      TransactionType: "Sale",
      PropertySubType: "SingleFamilyResidence"
    };
    let for_rent = {
      TransactionType: "Lease",
      PropertySubType: "Apartment"
    };
    let for_sale_links = `?${qs.stringify(for_sale)}`;
    let for_rent_links = `?${qs.stringify(for_rent)}`;

    return (
      <Footer className={this.props.className || "footer-page"}>
        <Row className="footer-container" type="flex" justify="center">
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xl={this.props.span || 6}
            style={style}
          >
            <section>
              <h2>Miami Real Estate</h2>
              <ul>
                <li>
                  <a target="_blank" href="/listings/search/Aventura/FL/Sale">
                    Aventura Real Estate
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="/listings/search/Bal%20Harbour/FL/Sale"
                  >
                    Bal Harbour Real Estate
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="/listings/search/Bay%20Harbor%20Islands/FL/Sale"
                  >
                    Bay Harbor Islands Real Estate
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="/listings/search/Biscayne%20Park/FL/Sale"
                  >
                    Biscayne Park Real Estate
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="/listings/search/Coconut%20Grove/FL/Sale"
                  >
                    Coconut Grove Real Estate
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/listings/search/Homestead/FL/Sale">
                    Homestead Real Estate
                  </a>
                </li>
              </ul>
            </section>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xl={this.props.span || 6}
            style={style}
          >
            <section>
              <h2>Homes for Sale</h2>
              <ul>
                <li>
                  <a
                    target="_blank"
                    href={`/listings/search/North%20Bay%20Village/FL/Sale${for_sale_links}`}
                  >
                    North Bay Village Homes For Sale
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={`/listings/search/North%20Miami/FL/Sale${for_sale_links}`}
                  >
                    North Miami Homes For Sale
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={`/listings/search/North%20Miami%20Beach/FL/Sale${for_sale_links}`}
                  >
                    North Miami Beach Homes For Sale
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={`/listings/search/Surfside/FL/Sale${for_sale_links}`}
                  >
                    Surfside Homes For Sale
                  </a>
                </li>
              </ul>
            </section>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xl={this.props.span || 6}
            style={style}
          >
            <section>
              <h2>Apartments for Rent</h2>
              <ul>
                <li>
                  <a
                    target="_blank"
                    href={`/listings/search/Homestead/FL/Lease${for_rent_links}`}
                  >
                    Apartments For Rent in Homestead
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={`/listings/search/Key Biscayne/FL/Lease${for_rent_links}`}
                  >
                    Apartments For Rent in Key Biscayne
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={`/listings/search/Miami Beach/FL/Lease${for_rent_links}`}
                  >
                    Apartments For Rent in Miami Beach
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={`/listings/search/Miami Gardens/FL/Lease${for_rent_links}`}
                  >
                    Apartments For Rent in Miami Gardens
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={`/listings/search/Miami Shores/FL/Lease${for_rent_links}`}
                  >
                    Apartments For Rent in Miami Shores
                  </a>
                </li>
              </ul>
            </section>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xl={this.props.span || 6}
            style={style}
          >
            <section>
              <h2>Complexes</h2>
              <ul>
                <li>
                  <a target="_blank" href={`${URL_CONDOS}miami-beach/la-gorce-palace-condo`}>
                    La Gorce Palace Condo
                  </a>
                </li>
                <li>
                  <a target="_blank" href={`${URL_CONDOS}miami-beach/akoya-condo`}>
                    Akoya Condo
                  </a>
                </li>
                <li>
                  <a target="_blank" href={`${URL_CONDOS}miami-beach/1500-ocean-drive-condo`}>
                    1500 Ocean Drive Condo
                  </a>
                </li>
                <li>
                  <a target="_blank" href={`${URL_CONDOS}miami-beach/condos`}>
                    More Condos in Miami
                  </a>
                </li>
              </ul>
            </section>
          </Col>
        </Row>

        <Row
          type="flex"
          justify="center"
          className="sub-footer-page"
          align="middle"
        >
          <Col span={24}>
            <section>
              <ul className="list-links-pages">
                <li>
                  <a target="_blank" href="/contact-us">
                    Contact Us
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/about-us">
                    About Us
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/terms-and-conditions">
                    Terms and Conditions
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/privacy-policy">
                    Privacy Policiy
                  </a>
                </li>
              </ul>
            </section>
          </Col>
        </Row>
        <Row type="flex" justify="center" className="social-network">
          <Social />
        </Row>
        <Row
          type="flex"
          justify="center"
          align="middle"
          className="sub-footer-page"
        >
          <Col span={24}>
            <div className="subfoo">
              Copyright {moment().format("YYYY")} - One World International Real
              Estate / GoOneWorld.com
            </div>
          </Col>
        </Row>
      </Footer>
    );
  }
}
export default withRouter(FooterPage);
