/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";

//import Header from "../../components/header";
import Menu from "../../components/menu";
import { Layout, Row, Col } from "antd";
import "./whychooseoneworld.css";

const { Content } = Layout;
//const background_header = require("./images/why-one-world.jpg");
/*const img1 = require("./images/index-img_1.jpg");
const img2 = require("./images/index-img_2.jpg");
const img3 = require("./images/index-img_3.jpg");
const img4 = require("./images/index-img_4.jpg");
const img5 = require("./images/index-img_5.jpg");*/
const img10 = require("./images/index-img_10.jpg");


const LayoutWhyChooseOneWorld = ({ children }) => (
  <div className="whychooseoneworld">
    <header className="head-why">
        <Menu />
        <div className="conte-middle">
			<div>
				<div className="row">
					<div className="benefits"><h1>BENEFITS</h1></div>
					<div className="img-main"></div>
				</div>
            </div>
        </div>
    </header>

    <Layout>
    
      <section id="content">
		<div className="block-3">
			<div className="container">
				<div className="row">
					<div className="grid_12"> 
						<header>
							<h2 className="subtit">Why Work with US</h2>
						</header>
					</div>
				</div>
				<div className="row">
					<div className="grid_4">
						{/*<img src={img1} alt="Lead Generation" width="370" height="277"/>*/}
						<div className="img_lead"></div>
						<h5>Lead generation platform provides quality leads. Agents have potential to earn high commissions.</h5>
					</div>
					<div className="grid_4">
						{/*<img src={img2} alt="Marketing tools" width="370" height="277"/>*/}
						<div className="img_marketing"></div>
						<h5>Marketing tools help you stay connected with customers. News Blog, email campaigns and promotions.</h5>
					</div>
					<div className="grid_4">
						{/*<img src={img3} alt="Continuous education" width="370" height="277"/>*/}
						<div className="img_education"></div>
						<h5>Continuous education Real Estate agent community facilitates knowledge exchange and education.</h5>
					</div>
				</div>
			</div>
		</div>
		<div className="block-4">
			<div className="container">
				<div className="row">
					<div className="grid_12">
						<h2 className="subtit">Our mission & vision</h2>
					</div>
				</div>
				<div className="row">
					<div className="grid_6">
						{/*<img layout="responsive" src={img4} alt="Our Mission" width="570" height="277"/>*/}
						<div className="img_mission"></div>
						<p>Our mission is to provide real estate agents with the technology and tools that can increase their productivity and stay connected with the customers.</p>
					</div>
					<div className="grid_6">
						{/*<img layout="responsive" src={img5} alt="One World Interntional" width="570" height="277"/>*/}
						<div className="img_oneworld"></div>
						<p>One World International Management believes that technology is a way to grow and develop for real estate firm. That is why the company has been concentrating in investing in web technology and providing the best marketing tools to realtors in Miami area.</p>
					</div>
				</div>
			</div>
		</div>

		<div className="block-6">
			<div className="container">
				<div className="row">
					<div className="grid_12">
						<header>
							<h2 className="subtit">Hear from our agents</h2>
							<p className="sub_head"></p>
						</header>
						<p className="block-6_element">I joined One World International last year after moving to Miami from New Orleans. I was impressed with the set of tools that the company provides to its agents. Marketing techniques that I learned through continuous education program help me improve customer retention skills and gain return business.</p>
						<div className="block-6_item">
							<img src={img10} alt="Tom Lopez"/>
							<div>
								<h4>Tom Lopez,<span>Real Estate Associate</span></h4>
								<p>One World International Real Estate</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>




      


      
    </Layout>
  </div>
);

LayoutWhyChooseOneWorld.propTypes = {
  children: PropTypes.node.isRequired
};

export default LayoutWhyChooseOneWorld;
