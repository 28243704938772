import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../redux/actions/action_search";
import { Row, Col, Card, message } from "antd";
import "./home.css";
import Layout from "../layouts/layouthome";
import Carousel from "../components/com/carousel/CarouselHorizontal";
import ListingCard from "../components/listings/ListingCard";
import Skeleton from "../components/com/skeleton/SkeletonTpl";
import Constants from "../utils/constants";
import Helper from "../utils/Helper";
import moment from "moment";
import {
  get_new_listings,
  get_new_listings_under_300,
  get_new_listings_apartament_rent
} from "../services";
moment.locale("es");
const NUMER_OF_CARDS = 6;
const MAX_LISTINGS = 16;
const { money } = Helper;

const NewListings = props => {
  console.log("__>", props.loading);
  return (
    <Carousel
      {...props}
      renderItem={item =>
        !props.loading ? (
          <div className="item-carousel-container">
            <div
              style={{
                height: "100%"
              }}
            >
              <ListingCard
                style={{
                  display: "block"
                }}
                {...item}
                PhotosCount={1}
                url={`${Constants.URL_API_PHOTOS}${props.ListingKeyNumeric}/0.jpg?width=200&height=200`}
                displayActions={false}
                displayGalery={false}
                onChange={props.onChange}
              />
            </div>
            {/* <ListingCard
            {...item}
            PhotosCount={1}
            url={`${Constants.URL_API_PHOTOS}${
              props.ListingKeyNumeric
            }/0.jpg?width=200&height=200`}
            displayActions={false}
            displayGalery={false}
            onChange={props.onChange}
          /> */}
          </div>
        ) : (
          <Skeleton className="empty-item" />
        )
      }
    />
  );
};
const getDataSkeleton = () => {
  return Array.apply(null, { length: NUMER_OF_CARDS }).map((item, index) => {
    return index;
  });
};

class Home extends Component {
  state = {
    newListing: getDataSkeleton(),
    ListingPriceTo: getDataSkeleton(),
    ListingsLease: getDataSkeleton(),
    loadingNewListing: true,
    loadingListingsLease: true,
    loadingListingPriceTo: true
  };
  componentDidMount() {
    this.search();
  }
  search = async () => {
    let startDate = moment();
    /* startDate = startDate.subtract(2, "days");
    startDate = startDate.format("Y-MM-DD"); */

    /* const [newListing, ListingPriceTo, ListingsLease] = await Promise.all([
      get_new_listings(),
      get_new_listings_under_300(),
      get_new_listings_apartament_rent()
    ]); */

    get_new_listings().then(data => {
      this.setState({
        newListing: data,
        loadingNewListing: false
      });
    });
    get_new_listings_under_300().then(data => {
      this.setState({
        ListingPriceTo: data,
        loadingListingPriceTo: false
      });
    });
    get_new_listings_apartament_rent().then(data => {
      this.setState({
        ListingsLease: data,
        loadingListingsLease: false
      });
    });
    /* this.setState({
      newListing,
      loadingNewListing: false,
      ListingPriceTo,
      loadingListingPriceTo: false,
      ListingsLease,
      loadingListingsLease: false
    });

    return console.log("---> Listings: ", newListing); */
  };
  render() {
    let {
      newListing,
      ListingPriceTo,
      loadingNewListing,
      loadingListingPriceTo,
      loadingListingsLease,
      ListingsLease
    } = this.state;

    return (
      <Layout>
        <Row type="flex" justify="center">
          {newListing.length > 0 && (
            <Col span={24}>
              <div className="item-section bg-white">
                <div className="title-section">
                  <h2>New Listings</h2>
                  <p>Check Out the Latest Properties for Sale in Your Area</p>
                </div>
                <div className="listing-home">
                  {
                    <NewListings
                      loading={loadingNewListing}
                      numberOfCards={NUMER_OF_CARDS}
                      dataSource={newListing}
                      autoplay={false}
                    />
                  }
                </div>
              </div>
            </Col>
          )}
          <Col
            span={24}
            style={
              {
                /* width: 100% */
              }
            }
          >
            <div className="item-section">
              <div className="title-section">
                <h2>Find Great Deals Under {money(300000)}</h2>
                <p>Are you Looking for Good Purchase Opportunities?</p>
              </div>
              <div className="listing-home">
                {
                  <NewListings
                    numberOfCards={NUMER_OF_CARDS}
                    loading={loadingListingPriceTo}
                    dataSource={ListingPriceTo}
                    autoplay={false}
                  />
                }
              </div>
            </div>
          </Col>
          <Col
            span={24}
            style={
              {
                /* width: 100% */
              }
            }
          >
            <div className="item-section bg-white">
              <div className="title-section">
                <h2>Apartment for Rent</h2>
                <p>Find Great Deals on Our Apartments for Rent </p>
              </div>
              <div className="listing-home">
                {
                  <NewListings
                    numberOfCards={NUMER_OF_CARDS}
                    loading={loadingListingsLease}
                    dataSource={ListingsLease}
                    autoplay={false}
                  />
                }
              </div>
            </div>
          </Col>
        </Row>
      </Layout>
    );
  }
}
const mapStateToProps = state => {
  const { search } = state;
  return {
    search: search
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
